import { CreateLocalOfficeUserDTO, InactivateLocalOfficeUserDTO, LocalOfficeUserClient, LocalOfficeUserExistsDTO, ReinviteUserDTO } from '../volunteer-tracking-client';

const client: LocalOfficeUserClient = new LocalOfficeUserClient();

export const LocalOfficeUserService = {
   getByPrincipalId: async (principalId: string | undefined) => {
      const result = await client.getLocalOfficeUser(principalId)
      return result
   },
   createLocalOfficeUser: async (localOfficeUser: CreateLocalOfficeUserDTO | undefined) => {
      const result = await client.createLocalOfficeUser(localOfficeUser)
      return result
   },
   getResettlementAgencyLocalOfficeUsers: async (resettlementAgencyId: number | undefined) => {
      const result = await client.getResettlementAgencyLocalOfficeUsers(resettlementAgencyId)
      return result
   },
   deleteLocalOfficeUser: async (invactivateLocalOfficeUserDTO: InactivateLocalOfficeUserDTO) => {
      const result = await client.deleteLocalOfficeUser(invactivateLocalOfficeUserDTO)
      return result
   },
   checkIfLocalOfficeUserExists: async (localOfficeUserExistsDTO: LocalOfficeUserExistsDTO) => {
      const result = await client.checkIfLocalOfficeUserExists(localOfficeUserExistsDTO);
      return result
   },
   getByEmail: async (email: string) => {
      const result = await client.getByEmail(email);
      return result
   },
   reactivateLocalOfficeUser: async (dto: InactivateLocalOfficeUserDTO) => {
      await client.reactivateLocalOfficeUser(dto)
   },
   reinviteUser: async (dto: ReinviteUserDTO) => {
      await client.reinviteUser(dto)
   }
}