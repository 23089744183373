import React, { useEffect, useState } from 'react'
import { Stack, Spinner, Form, Button, Alert, Row } from 'react-bootstrap';
import { CreateCaseValidationService } from '../../services/CreateCaseValidationService';
import { InputService } from '../../services/InputService';
import { VolunteerClient, VolunteerDTO, CaseVolunteerDTO, LocalOfficeDTO, CreateVolunteerDTO } from '../../volunteer-tracking-client';
import { Exception } from '../Exception';
import { LocalOfficeService } from '../../services/LocalOfficeService';
import { useAppContext } from '../../AppContext';
import { VolunteerService } from '../../services/VolunteerService';


const client: VolunteerClient = new VolunteerClient();

export const CreateVolunteer = (props: {
  handleCaseChange?: (index: number, value: any) => void,
  caseAddVolunteer?: boolean,
  caseNumber?: string,
  caseVolunteer?: CaseVolunteerDTO,
  index?: number,
  isIndividualMatch?: boolean
}) => {
  const [volunteerDTO, setVolunteerDTO] = useState<CreateVolunteerDTO | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
  const [showCreateSuccess, setShowCreateSuccess] = useState(false);
  const [error, setError] = useState<Error>();
  const [localOffice, setLocalOffice] = useState<LocalOfficeDTO>()

  const [emailInvalidMessage, setEmailInvalidMessage] = useState("");
  const app = useAppContext();

  useEffect(() => {
    if (props.caseAddVolunteer && props.caseVolunteer && !props.caseVolunteer.volunteer?.email) {
      const updatedCaseVolunteerDTO = props.caseVolunteer;
      handleCaseChangeUpdate(props.index ?? 0, updatedCaseVolunteerDTO);
    }
    const getLocalOffice = async () => {
      setLocalOffice(await LocalOfficeService.getLocalOffice(parseInt(localStorage.getItem("selectedOrganizationId")!)))
    }
    try{
      getLocalOffice()
    }catch(ex:any){
      setError(ex)
    }

  }, []);



  const hasValidationError = (key: string, value: any) => {
    if (props.caseAddVolunteer) {
      const hasError = CreateCaseValidationService.hasError('CaseVolunteer', key, value);
      if (!hasError && key.includes('email')) {
        const validEmailExpression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const validEmail = (validEmailExpression.test(String(value).toLowerCase()));
        return !validEmail;
      }
      return hasError;
    } else {
      return (validationErrors.indexOf(key) !== -1);
    }
  }

  const handleInputChange = (event: any): void => {
    var key = event.target.name;
    var value = event.target.value;

    if (key === 'contactNumber') {
      const strValue = value as string;
      value = Number(strValue.replace(/\D/g, ''));
    }

    if (!props.caseAddVolunteer) {
      setVolunteerDTO(prevState => ({ ...prevState, [key]: value }));
    } else {
      let updatedCaseVolunteerDTO = props.caseVolunteer as CaseVolunteerDTO;
      let updatedVolunteerDTO = props.caseVolunteer?.volunteer as VolunteerDTO;

      if (!updatedCaseVolunteerDTO) updatedCaseVolunteerDTO = {} as CaseVolunteerDTO;
      if (!updatedVolunteerDTO) updatedVolunteerDTO = {} as VolunteerDTO;

      if (key === 'dateOfMatch' || key === 'timeCommitment') {
        if (key === 'dateOfMatch' && value === '') value = undefined;
        updatedCaseVolunteerDTO[key as keyof CaseVolunteerDTO] = value;
      } else {
        updatedVolunteerDTO[key as keyof VolunteerDTO] = value;
      }

      updatedCaseVolunteerDTO.volunteer = updatedVolunteerDTO;
      handleCaseChangeUpdate(props.index ?? 0, updatedCaseVolunteerDTO);
    }
  }

  const handleCaseChangeUpdate = (index: number, updatedCaseVolunteer: CaseVolunteerDTO) => {
    if (props.handleCaseChange) {
      props.handleCaseChange(props.index ?? 0, updatedCaseVolunteer);
    }
  }

  const handleAddVolunteer = async (event: any) => {
    event?.preventDefault();

    if (!(await hasValidFields()) || !volunteerDTO) {
      return;
    }
    else {
      setIsLoading(true);
      try {

        volunteerDTO.localOfficeId = parseInt(localStorage.getItem("selectedOrganizationId")!)
      } catch (error: any) {
        setError(error)
      }
      await VolunteerService.createVolunteerInvite(volunteerDTO)

      app.addRole(app.user, "Volunteer.All", localOffice?.id, localOffice?.name)

      setIsLoading(false);
      setShowCreateSuccess(true);
    }

  }

  const hasValidFields = async (): Promise<boolean> => {
    var errors = [];

    //firstname
    if (!volunteerDTO || !volunteerDTO?.firstName) {
      errors.push("firstname");
    }

    //lastName
    if (!volunteerDTO || !volunteerDTO?.lastName) {
      errors.push("lastname");
    }

    //email
    const expression = /\S+@\S+/;
    const validEmail = (expression.test(String(volunteerDTO?.email).toLowerCase()));
    let emailExists = false;
    if (volunteerDTO?.email) {
      emailExists = await client.checkIfVolunteerEmailExists(volunteerDTO?.email);
    }

    if (!validEmail || emailExists) {
      errors.push("email");
      if (emailExists) {
        setEmailInvalidMessage('Volunteer with that email already exists.');
      } else {
        setEmailInvalidMessage('Please enter a valid email address.');
      }
    }

    setValidationErrors(errors);

    return errors.length === 0;
  }

  var createVolunteerBody = (
    <>
      <Row>
        <Form.Group className='w-50 mb-3' controlId='volunteerEmail'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control isInvalid={hasValidationError('email', props.caseVolunteer ? props.caseVolunteer.volunteer?.email : volunteerDTO?.email)} placeholder='Email Address' name='email' onChange={handleInputChange}
            value={props.caseVolunteer ? props.caseVolunteer.volunteer?.email ?? '' : volunteerDTO?.email} />
          <Form.Control.Feedback type="invalid">
            {props.caseAddVolunteer ? props.caseVolunteer?.volunteer?.email ? 'Please enter a valid email address' : 'Required field.' : emailInvalidMessage}
          </Form.Control.Feedback>
        </Form.Group>
        {props.caseAddVolunteer &&
          <Form.Group className='w-50 mb-3' controlId='volunteercellPhone'>
            <Form.Label>Cell Phone</Form.Label>
            <Form.Control name='cellPhone' onChange={handleInputChange}
              placeholder='Cell phone'
              value={props.caseVolunteer ? InputService.FormatPhoneNumber(props.caseVolunteer.volunteer?.cellPhone ?? '') : InputService.FormatPhoneNumber(volunteerDTO?.cellPhone)} />
            <Form.Control.Feedback type="invalid">
              Required field.
            </Form.Control.Feedback>
          </Form.Group>
        }
      </Row>
      <Row>
        <Form.Group className='w-50 mb-3' controlId='volunteerFirstName'>
          <Form.Label>First Name</Form.Label>
          <Form.Control isInvalid={hasValidationError('firstName', props.caseVolunteer ? props.caseVolunteer.volunteer?.firstName : volunteerDTO?.firstName)} name='firstName' onChange={handleInputChange}
            placeholder='First name'
            value={props.caseVolunteer ? props.caseVolunteer.volunteer?.firstName ?? '' : volunteerDTO?.firstName} />
          <Form.Control.Feedback type="invalid">
            Required field.
          </Form.Control.Feedback>
        </Form.Group>
        {props.caseAddVolunteer &&
          <Form.Group className='w-50 mb-3' controlId='volunteerdateOfMatch'>
            <Form.Label>Date of Match</Form.Label>
            <Form.Control onChange={handleInputChange}
              type='date'
              placeholder='Enter date of match'
              value={props.caseVolunteer ? props.caseVolunteer.dateOfMatch ? props.caseVolunteer.dateOfMatch.toString().split('T')[0] : '' : ''}
              name='dateOfMatch' />
          </Form.Group>
        }
      </Row>
      <Row>
        <Form.Group className='w-50 mb-3' controlId='volunteerLastName'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control isInvalid={hasValidationError('lastName', props.caseVolunteer ? props.caseVolunteer.volunteer?.lastName : volunteerDTO?.lastName)} name='lastName' onChange={handleInputChange}
            placeholder='Last name'
            value={props.caseVolunteer ? props.caseVolunteer.volunteer?.lastName ?? '' : volunteerDTO?.lastName} />
          <Form.Control.Feedback type="invalid">
            Required field.
          </Form.Control.Feedback>
        </Form.Group>
        {props.caseAddVolunteer &&
          <Form.Group className='w-50 mb-3' controlId='volunteertimeCommitment'>
            <Form.Label>Time Commitment (Months)</Form.Label>
            <Form.Control name='timeCommitment' onChange={handleInputChange}
              type='number'
              placeholder='Time commitment'
              min={0}
              onKeyDown={InputService.HandleNumberInput}
              value={props.caseVolunteer ? props.caseVolunteer.timeCommitment ?? '' : ''} />
          </Form.Group>
        }
      </Row>
      {!props.caseAddVolunteer &&
        <Row>
          <Form.Group className='w-50 mb-3' controlId='volunteercellPhone'>
            <Form.Label>Cell Phone</Form.Label>
            <Form.Control name='cellPhone' onChange={handleInputChange}
              value={props.caseVolunteer ? InputService.FormatPhoneNumber(props.caseVolunteer.volunteer?.cellPhone ?? '') : InputService.FormatPhoneNumber(volunteerDTO?.cellPhone)} />
          </Form.Group>
        </Row>
      }
      {!props.caseAddVolunteer &&
        <Row className='pl-3'>
          <Button className='mt-3 w-25' variant='primary' type='submit'>
            Create Volunteer
          </Button>
        </Row>
      }
    </>
  );

  return (
    !error ?
      <>
        <Alert show={showCreateSuccess} variant="success" onClose={() => setShowCreateSuccess(false)} dismissible>
          <Alert.Heading>Success</Alert.Heading>
          <p>
            Volunteer Created!
            It may take up to 5 minutes before the account is fully active.
          </p>
        </Alert>
        <Stack>
          {!props.caseAddVolunteer &&
            <>
              < h1 id='tabelLabel' >Create Volunteer</h1>
              <p>This component creates a volunteer.</p>
            </>
          }
          {props.caseAddVolunteer && props.isIndividualMatch &&
            <>
              <Stack direction='horizontal' gap={2}>
                <h2>
                  Individual Matched with this client:
                </h2>
                <p className='mb-1'>{props.caseNumber}</p>
              </Stack>
              <p>
                Individual performing welcoming activities that promote client integration
              </p>
            </>
          }
          {props.caseAddVolunteer && props.index !== 0 &&
            <h2>Volunteer Info.</h2>
          }
          {isLoading &&
            <Stack className='mx-auto text-center mt-5'>
              <Spinner className='mx-auto' animation='border' variant='primary' />
            </Stack>
          }
          {(!isLoading) &&
            <Stack>
              {props.caseAddVolunteer
                ? createVolunteerBody
                : <Form onSubmit={handleAddVolunteer}>{createVolunteerBody}</Form>
              }
            </Stack>
          }
        </Stack>
      </> :
      <>
        <Exception message={error.message}></Exception>
      </>
  );
}

CreateVolunteer.displayName = CreateVolunteer.name;
