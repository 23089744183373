import { faFile } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const ListCard = (props: {
    title: string,
    hasReceipt?: boolean,
    children: any
}) => {
    const { children } = props

    return (
        <div className="case-item">
            {props.hasReceipt &&
                <FontAwesomeIcon icon={faFile} className={"me-2"} title={'Receipts Available'} />
            }
            <h3>{props.title}</h3>
            {children}
        </div>
    );
}