import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

import { useNavigate } from "react-router-dom";
import { VolunteerService } from '../../services/VolunteerService';
import { VolunteerDTO } from '../../volunteer-tracking-client';
import { DemographicsReview } from './DemographicsReview';
import { DemographicsSection1 } from './DemographicsSection1';
import { DemographicsSection2 } from './DemographicsSection2';
import { DemographicsValidator } from './DemographicsValidator';
import { RegistrationComponentProps } from './RegisrationComponentProps';
import { Exception } from '../Exception';


export const AddDemographics = (props: RegistrationComponentProps) => {
    const [volunteer, setVolunteer] = useState<VolunteerDTO>(props.volunteer);
    const [validations, setValidations] = useState<{ [page: number]: number }>({});
    const [page, setPage] = useState<number>(1);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        setVolunteer(props.volunteer);
    }, [props.volunteer]);

    const navigate = useNavigate();

    const handleChanges = (fn: () => any): void => {
        setVolunteer(prevState => ({ ...prevState, ...fn() }));
    }

    const handleNext = (event: any): void => {
        if (page == 2) {
            setValidations(new DemographicsValidator().validate(volunteer))
        }
        if (page < 4) {
            setPage(page + 1)
        }
    }

    const handlePrevious = (event: any): void => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const handleSubmit = async (event: any) => {
        try{
            if (volunteer.registrationComplete) {
                await VolunteerService.updateVolunteer(volunteer);
                navigate('/volunteer-home');
            } else {
                volunteer.registrationComplete = true
                await VolunteerService.updateVolunteer(volunteer)
                window.location.reload()
            }
        }catch(ex:any){

        }
    }

    return (
        !error?
        <>
            <Container>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <Row>
                                <Col className='header-breadcrumb breadcrumb-border-right'>
                                    <Row>
                                        <Col md={2}><div className={'breadcrumb-circle ' + (page == 1 ? 'active active-breadcrumb-circle' : '')} >1
                                            {validations[1] && <div className="validation-circle">{validations[1]}</div>}</div></Col>
                                        <Col className={'d-none d-lg-block ' + (page == 1 ? 'active' : '')}>
                                            <div className='breadcrumb-text'>Contact Information</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='header-breadcrumb breadcrumb-border-right'>
                                    <Row>
                                        <Col md={2}><div className={'breadcrumb-circle ' + (page == 2 ? 'active active-breadcrumb-circle' : '')} >2
                                            {validations[2] && <div className="validation-circle">{validations[2]}</div>}</div></Col>
                                        <Col className={'d-none d-lg-block ' + (page == 2 ? 'active' : '')}>
                                            <div className='breadcrumb-text'>Demographics</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='header-breadcrumb'>
                                    <Row>
                                        <Col md={2}><div className={'breadcrumb-circle ' + (page == 3 ? 'active active-breadcrumb-circle' : '')} >3
                                            {validations[3] && <div className="validation-circle">{validations[3]}</div>}</div></Col>
                                        <Col className={'d-none d-lg-block ' + (page == 3 ? 'active' : '')}>
                                            <div className='breadcrumb-text'>Review</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Title>
                    </Card.Body>
                </Card>
                {(page == 1) &&
                    <DemographicsSection1 volunteer={volunteer} onChangeHandler={handleChanges} />
                }
                {(page == 2) &&
                    <DemographicsSection2 volunteer={volunteer} onChangeHandler={handleChanges} />
                }
                {(page == 3) &&
                    <DemographicsReview volunteer={volunteer} />
                }

                <Row className='text-center mt-5'>
                    <Col md={{ span: 4, offset: 4 }}>
                        <Row>
                            {(page > 1) &&
                                <Col>
                                    <Button onClick={handlePrevious}>Previous</Button>
                                </Col>
                            }
                            {(page < 3) &&
                                <Col>
                                    <Button onClick={handleNext}>Next</Button>
                                </Col>
                            }
                            {(page == 3 && Object.keys(validations).length === 0) &&
                                <Col>
                                    <Button onClick={handleSubmit}>Submit</Button>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>

        </>:<Exception message={error.message}></Exception>
    )
}
