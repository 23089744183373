import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { CaseSponsorshipGroupDTO, CreateCaseDTO, FaithCommunityTypeDTO, SponsorshipGroupClient, SponsorshipGroupCommunityTypeDTO, SponsorshipGroupDTO, SponsorshipGroupTypeDTO, SponsorshipGroupVolunteerDTO } from '../../../volunteer-tracking-client';
import { SponsorshipGroup } from './SponsorshipGroup';
import { Exception } from '../../Exception';

const sponsorshipGroupClient: SponsorshipGroupClient = new SponsorshipGroupClient();

export const SponsorshipGroupSelect = (props: {
    handleChange: (key: any, value: any) => void,
    createCaseDTO: CreateCaseDTO,
    sponsorshipCommunityTypes: SponsorshipGroupCommunityTypeDTO[]
    faithCommunityTypes: FaithCommunityTypeDTO[]
}) => {
    const [sponsorshipGroupTypes, setSponsorshipGroupTypes] = useState<SponsorshipGroupTypeDTO[]>([]);
    const [sponsorshipGroups, setSponsorshipGroups] = useState<SponsorshipGroupDTO[]>([]);
    const [error, setError] = useState<Error>()

    useEffect(() => {
        const getSponsorshipGroupTypes = async () => {
            await sponsorshipGroupClient.getTypes()
                .then((resp) => { setSponsorshipGroupTypes(resp); });
        };
        const getSponsorshipGroups = async () => {
            await sponsorshipGroupClient.getSponsorshipGroups(props.createCaseDTO.localOfficeID)
                .then((resp) => { setSponsorshipGroups(resp); });
        };
        try{
            getSponsorshipGroupTypes();
            getSponsorshipGroups();
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    const sponsorshipGroupLeader = { volunteer: {}, isGroupLeader: true } as SponsorshipGroupVolunteerDTO;
    let caseSponsorshipGroup = {
        sponsorshipGroup: { isExisting: true, sponsorshipGroupVolunteers: [sponsorshipGroupLeader] } as SponsorshipGroupDTO
    } as CaseSponsorshipGroupDTO;
    let existingGroupSelected = false;
    if (props.createCaseDTO.caseSponsorshipGroups && props.createCaseDTO.caseSponsorshipGroups[0]) {
        caseSponsorshipGroup = props.createCaseDTO.caseSponsorshipGroups[0];
        if (!caseSponsorshipGroup.sponsorshipGroup) {
            caseSponsorshipGroup.sponsorshipGroup = { isExisting: true, sponsorshipGroupVolunteers: [sponsorshipGroupLeader] } as SponsorshipGroupDTO
        }
        existingGroupSelected = props.createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroupID !== undefined;
    }

    const handleCheckBoxChange = (event: any) => {
        let key: keyof SponsorshipGroupDTO = event.target.name;
        let value = event.target.checked;

        if (props.createCaseDTO.caseSponsorshipGroups && props.createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup) {
            if (key.endsWith('No')) {
                value = !value;
            }

            if (key.includes('isExisting')) {
                key = 'isExisting';
                clearSponsorshipGroup();
            }

            if (caseSponsorshipGroup.sponsorshipGroup) {
                caseSponsorshipGroup.sponsorshipGroup[key] = value;
            }
        }

        updateSponsorshipGroup();
    }

    const handleSelectSponsorshipGroup = (event: any) => {
        const value = event.target.value as number;
        if (caseSponsorshipGroup) {
            // eslint-disable-next-line
            const newSponsorshipGroup = sponsorshipGroups.filter(sg => sg.id == value)[0];
            if (newSponsorshipGroup) {
                caseSponsorshipGroup.sponsorshipGroup = newSponsorshipGroup;
                caseSponsorshipGroup.sponsorshipGroupID = newSponsorshipGroup.id;
                caseSponsorshipGroup.sponsorshipGroup.isExisting = true;
            }
            updateSponsorshipGroup();
        }
    }

    const updateSponsorshipGroup = () => {
        if (props.createCaseDTO.caseSponsorshipGroups && props.createCaseDTO.caseSponsorshipGroups[0]) {
            props.createCaseDTO.caseSponsorshipGroups[0] = caseSponsorshipGroup;
            props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        } else if (props.createCaseDTO.caseSponsorshipGroups) {
            props.createCaseDTO.caseSponsorshipGroups = [caseSponsorshipGroup];
            props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        }
    }

    const clearSponsorshipGroup = () => {
        if (props.createCaseDTO.caseSponsorshipGroups) {
            props.createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup = { sponsorshipGroupVolunteers: [sponsorshipGroupLeader] } as SponsorshipGroupDTO
            props.createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroupID = undefined;
            props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        }
    }

    return (
        !error?
        <Container>
            <Form>
                <Row>
                    <h2>Please select or create the community sponsorship group you are matching with this case</h2>
                </Row>
                <Row className='mb-3'>
                    <Form.Group className='mb-3' controlId='signedMoU'>
                        <Form.Label>Is this an existing or new group?</Form.Label>
                        <Form.Check
                            type='radio'
                            label='Existing'
                            name='isExisting'
                            checked={caseSponsorshipGroup.sponsorshipGroup?.isExisting}
                            onChange={handleCheckBoxChange}
                        />
                        <Form.Check
                            type='radio'
                            label='New'
                            name='isExistingNo'
                            checked={!caseSponsorshipGroup.sponsorshipGroup?.isExisting}
                            onChange={handleCheckBoxChange}
                        />
                    </Form.Group>
                </Row>
                {caseSponsorshipGroup.sponsorshipGroup && caseSponsorshipGroup.sponsorshipGroup.isExisting &&
                    <Row>
                        <Col sm={6}>
                            <Form.Group className='mb-3' controlId='selectCommunityGroup'>
                                <Form.Label>Sponsorship Group</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='selectCommunityGroup'
                                    defaultValue={undefined}
                                    value={caseSponsorshipGroup.sponsorshipGroup.id}
                                    onChange={handleSelectSponsorshipGroup}
                                >
                                    <option value={undefined}>Select Sponsorship Group</option>
                                    {sponsorshipGroups.map((sg, index) => {
                                        if (!sg.isDeleted) {
                                            return <option key={index} value={sg.id}>{sg.name}</option>
                                        }
                                    })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                }
                {caseSponsorshipGroup.sponsorshipGroup
                    && ((caseSponsorshipGroup.sponsorshipGroup?.isExisting && existingGroupSelected) || !caseSponsorshipGroup.sponsorshipGroup?.isExisting) &&
                    <SponsorshipGroup handleChange={props.handleChange}
                        isExisting={caseSponsorshipGroup.sponsorshipGroup?.isExisting ?? false}
                        createCaseDTO={props.createCaseDTO}
                        caseSponsorshipGroup={caseSponsorshipGroup}
                        sponsorshipGroupTypes={sponsorshipGroupTypes}
                        sponsorshipCommunityTypes={props.sponsorshipCommunityTypes}
                        faithCommunityTypes={props.faithCommunityTypes} />
                }
            </Form>
        </Container>:<Exception message={error.message}></Exception>
    );
}
SponsorshipGroupSelect.displayName = SponsorshipGroupSelect.name;