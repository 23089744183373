export const InputService = {
    HandleNumberInput: (event: any) => {
        const characterCode = event.key;
        if (characterCode === 'Backspace') return;

        const characterNumber = Number(characterCode);
        if (characterNumber >= 0) {
            if (event.currentTarget.value && event.currentTarget.value.length) {
                return;
            }
        } else {
            event.preventDefault()
        }
    },
    HandleZipCodeInput: (event: any) => {
        const characterCode = event.key;

        if (characterCode === 'Backspace') return;
        if (characterCode === '-') return;

        const characterNumber = Number(characterCode);
        if (characterNumber >= 0) {
            if (event.currentTarget.value && event.currentTarget.value.length) {
                return;
            }
        } else {
            event.preventDefault()
        }
    },
    FormatPhoneNumber: (value: any) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }
}