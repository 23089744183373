import React, { useEffect, useState } from 'react'
import { Stack, Spinner, Form, Button, Alert, Modal, ModalBody, Container } from 'react-bootstrap';
import { LocalOfficeService } from '../../services/LocalOfficeService';
import { LocalOfficeUserService } from '../../services/LocalOfficeUserService';
import { LocalOfficeUserDTO, LocalOfficeDTO, CreateLocalOfficeUserDTO } from '../../volunteer-tracking-client';
import { Exception } from '../Exception';
import { CreateLocalOfficeUserForm } from '../LocalOffice/CreateLocalOfficeUserForm';

type CreateLocalOfficeUserProps = {
    show: boolean,
    confirmHandler: (event: any, lou: LocalOfficeUserDTO, localOfficeId: number) => void,
    closeHandler: () => void
}

export const CreateLocalOfficeUserModal = (props: CreateLocalOfficeUserProps) => {
    const initalState: CreateLocalOfficeUserDTO = { firstName: "", lastName: "", email: "", phone: "" }
    const [localOffices, setLocalOffices] = useState<LocalOfficeDTO[]>([]);
    const [createLocalOfficeUserDTO, setCreateLocalOfficeUserDTO] = useState<CreateLocalOfficeUserDTO | undefined>(initalState);
    const [isLoading, setIsLoading] = useState(false);
    const [showCreateSuccess, setShowCreateSuccess] = useState(false);
    const [showBadRequest, setShowBadRequest] = useState(false);
    const [validated, setValidated] = useState(false)
    const [error, setError] = useState<Error>();
    const [emailMessage, setEmailMessage] = useState<string>();
    const [emailNew, setEmailNew] = useState<boolean>(true);


    useEffect(() => {
        const getLocalOffices = async () => {
            await LocalOfficeService.getByResettlementAgencyId(parseInt(localStorage.getItem("selectedOrganizationId")!))
                .then((resp) => { setLocalOffices(resp); });
        }

        getLocalOffices();
    }, [])

    const handleInputChange = (event: any): void => {
        var key = event.target.name;
        var value = event.target.value;
        setCreateLocalOfficeUserDTO(prevState => ({ ...prevState, [key]: value }) as CreateLocalOfficeUserDTO);
    }

    const handleEmailChange = async (event: any) => {
        try {
            const localOfficeUserDTO = await LocalOfficeUserService.getByEmail(event.target.value);
            if (localOfficeUserDTO == null) {
                setEmailNew(true)
            } else {
                setEmailNew(false)
                handleInputChange({ target: { name: "firstName", value: localOfficeUserDTO.firstName } })
                handleInputChange({ target: { name: "lastName", value: localOfficeUserDTO.lastName } })
                handleInputChange({ target: { name: "phone", value: localOfficeUserDTO.phone } })
            }
        } catch (e: any) {
            console.log(e)
        }
    }

    const handleCreateLocalOfficeUser = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setIsLoading(true);
            const localOfficeUser = await LocalOfficeUserService.getByEmail(createLocalOfficeUserDTO!.email);
            if (createLocalOfficeUserDTO?.email) {
                // eslint-disable-next-line eqeqeq
                if (localOfficeUser?.localOfficeLocalOfficeUsers?.some(lo => createLocalOfficeUserDTO.localOfficeId == lo.localOfficeId)) {
                    setEmailMessage('Local office user with that email and local office already exists.')
                    setValidated(false);
                    setIsLoading(false);
                } else {
                    try {
                        var lou = await LocalOfficeUserService.createLocalOfficeUser(createLocalOfficeUserDTO);
                        setShowCreateSuccess(true);
                        setValidated(false);
                        props.confirmHandler(event, lou, createLocalOfficeUserDTO.localOfficeId!);
                    } catch (error: any) {
                        if (error.status === 404) {
                            setValidated(true)
                            setShowBadRequest(true);
                        } else {
                            setError(error)
                        }
                    } finally {
                        setIsLoading(false);
                        setEmailMessage(undefined);
                    }
                }
            }
        }
    }

    if (error) {
        return <Exception message={error.message}></Exception>
    } else {
        return (
            <>
                <Alert show={showCreateSuccess} variant="success" onClose={() => setShowCreateSuccess(false)} dismissible>
                    <Alert.Heading>Success</Alert.Heading>
                    <p>
                        Local Office User Created!
                    </p>
                </Alert>
                <Alert show={showBadRequest} variant="danger" onClose={() => setShowBadRequest(false)} dismissible>
                    <Alert.Heading>Bad Request</Alert.Heading>
                    <p>
                        There was a problem creating the user.  This is mostly likely due to an invalid email address
                    </p>
                </Alert>
                <Modal show={props.show} onHide={props.closeHandler} centered onShow={() => {
                    setCreateLocalOfficeUserDTO(initalState)
                    setEmailNew(true)
                }}>
                    <Modal.Header closeButton >
                        <Modal.Title>Create Local Office User</Modal.Title>
                    </Modal.Header >
                    <ModalBody>
                        {isLoading &&
                            <Container className='mx-auto text-center mt-5'>
                                <Spinner className='mx-auto' animation='border' variant='primary' />
                            </Container>
                        }
                        {(!isLoading) &&
                            <Stack>
                                <Form noValidate validated={validated} onSubmit={handleCreateLocalOfficeUser}>
                                    <CreateLocalOfficeUserForm handleInputChange={handleInputChange} handleEmailChange={handleEmailChange}
                                        localOfficeUserDTO={createLocalOfficeUserDTO}
                                        emailInvalidMessage={emailMessage}
                                        readonly={!emailNew} />
                                    <Form.Group className='mb-3' controlId='localOfficeId'>
                                        <Form.Label>Local Office</Form.Label>
                                        <Form.Select required name='localOfficeId' onChange={handleInputChange}>
                                            <option value=''>Select</option>
                                            {localOffices.map((opt: LocalOfficeDTO) => (
                                                <option key={opt.id} value={opt.id}>{opt.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Button variant='secondary' onClick={(event: any) => {
                                        setCreateLocalOfficeUserDTO(initalState)
                                        setEmailNew(true)
                                        props.closeHandler()
                                    }
                                    }>Cancel</Button>
                                    <Button className='m-3' variant='primary' type='submit'>Create User</Button>
                                </Form>
                            </Stack>
                        }
                    </ModalBody>
                </Modal >
            </>
        );
    }
}