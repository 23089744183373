import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Stack } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { EducationLevelClient, EducationLevelDTO, EmploymentTypeClient, EmploymentTypeDTO, EngagementCountClient, EngagementCountDTO, EthnicityClient, EthnicityDTO, GenderClient, GenderDTO, ImmigrationStoryClient, ImmigrationStoryDTO, LanguageClient, LanguageDTO, StateClient, StateDTO } from "../../volunteer-tracking-client";
import { RegistrationComponentProps } from "./RegisrationComponentProps";
import { Exception } from "../Exception";


export const DemographicsSection2 = (props: RegistrationComponentProps) => {
    const [ethnicities, setEthnicities] = useState<EthnicityDTO[]>([])
    const [languages, setLanguages] = useState<LanguageDTO[]>([])
    const [educationLevels, setEducationLevels] = useState<EducationLevelDTO[]>([])
    const [employmentTypes, setEmploymentTypes] = useState<EmploymentTypeDTO[]>([])
    const [engagementCounts, setEngagementCounts] = useState<EngagementCountDTO[]>([])
    const [immigrationStories, setImmigrationStories] = useState<ImmigrationStoryDTO[]>([])
    const [error, setError] = useState<Error>()

    const { volunteer, onChangeHandler } = props
    const ethnicityClient = new EthnicityClient()
    const languageClient = new LanguageClient()
    const educationLevelClient = new EducationLevelClient()
    const employmentTypeClient = new EmploymentTypeClient()
    const engagementCountClient = new EngagementCountClient()
    const immigrationStoryClient = new ImmigrationStoryClient()

    const handleChange = (event: any) => {
        onChangeHandler!(() => { return { ...volunteer, ...{ [event.target.name]: event.target.value } } })
    }

    useEffect(() => {
        const getEthnicities = async () => {
            setEthnicities(await ethnicityClient.getAll())
        }

        const getLanguages = async () => {
            setLanguages(await languageClient.getAllLanguages())
        }

        const getEducationLevels = async () => {
            setEducationLevels(await educationLevelClient.getAll())
        }

        const getEmploymentTypes = async () => {
            setEmploymentTypes(await employmentTypeClient.getAll())
        }

        const getEngagementCounts = async () => {
            setEngagementCounts(await engagementCountClient.getAll())
        }

        const getImmigrationStories = async () => {
            setImmigrationStories(await immigrationStoryClient.getAll())
        }
        try{
            getEthnicities()
            getLanguages()
            getEducationLevels()
            getEmploymentTypes()
            getEngagementCounts()
            getImmigrationStories()
        }catch(ex:any){
            setError(ex)
        }

    }, []);

    return (
        !error?
        <Card>
            <Card.Header>
                <Row>
                    <Col md={10}><h2>Demographics</h2></Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Form.Label>
                    <h4>Which of the following best describes your race/ethnicity?</h4>
                    {!volunteer.ethnicityId &&
                        <div className="text-danger">(You must select one)</div>
                    }
                </Form.Label>
                {ethnicities.map(e => {
                    return (
                        <Row key={e.id} className="mb-4">
                            <Col md={12}>
                                <Form.Check
                                    required
                                    checked={volunteer.ethnicityId == e.id}
                                    label={e.value}
                                    name="ethnicityId"
                                    type="radio"
                                    value={e.id}
                                    onChange={handleChange} />
                            </Col>
                        </Row>
                    )
                })}
                <Row className="mb-4">
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Primary Language Spoken</Form.Label>
                            <Form.Control
                                isInvalid={!volunteer.primaryLanguageId}
                                name='primaryLanguageId'
                                as='select'
                                value={volunteer.primaryLanguageId || ' '}
                                onChange={handleChange}
                            >
                                <option key='' value=' ' disabled hidden>Primary Language Spoken</option>
                                {languages.map((l, index) => {
                                    return <option key={index} value={l.id}>{l.name}</option>
                                })
                                }
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Other Languages Spoken</Form.Label>
                            <MultiSelect
                                labelledBy="Please Include if applicable"
                                options={languages!.map((l, index) => {
                                    return { label: l.name!, value: l.id }
                                })}
                                value={volunteer.volunteerOtherLanguages?.map(l => { return { label: languages.find(lang => lang.id === l.languageId)?.name ?? '', value: l.languageId } }) || []}
                                onChange={(event: any) => {
                                    volunteer.volunteerOtherLanguages = event.map((h: any) => {
                                        return { language: { name: h.label }, languageId: h.value }
                                    })
                                    onChangeHandler!(() => { return { ...volunteer, ...{ volunteerOtherLanguages: volunteer.volunteerOtherLanguages } } })
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Highest Level of Education Achieved</Form.Label>
                            <Form.Control
                                isInvalid={!volunteer.educationLevelId}
                                name='educationLevelId'
                                as='select'
                                value={volunteer.educationLevelId || ' '}
                                onChange={handleChange}
                            >
                                <option key='' value=' ' disabled hidden>Select an option</option>
                                {educationLevels.map((e, index) => {
                                    return <option key={index} value={e.id}>{e.value}</option>
                                })
                                }
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Employment Status</Form.Label>
                            <Form.Control
                                isInvalid={!volunteer.employmentTypeId}
                                name='employmentTypeId'
                                as='select'
                                value={volunteer.employmentTypeId || ' '}
                                onChange={handleChange}
                            >
                                <option key='' value=' ' disabled hidden>Select an option</option>
                                {employmentTypes.map((e, index) => {
                                    return <option key={index} value={e.id}>{e.value}</option>
                                })
                                }
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    {volunteer.employmentTypeId == employmentTypes.find(et => et.value == 'Student')?.id &&
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Enter Educational Institution</Form.Label>
                                <Form.Control
                                    isInvalid={!volunteer.employmentTypeNotes || volunteer.employmentTypeNotes?.trim() == ''}
                                    value={volunteer.employmentTypeNotes || ''}
                                    name="employmentTypeNotes"
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    }

                </Row>
                <Form.Label>
                    <h4>Which statement best describes your previous engagement welcoming newcomers as a member of a community sponsor group?</h4>
                    {!volunteer.engagementCountId &&
                        <div className="text-danger">(You must select one)</div>
                    }
                </Form.Label>
                <Row className="mb-4">
                    <Col md={6}>
                        <Form.Group className='mb-3' controlId='engagementCount'>
                            <Form.Control
                                name='engagementCountId'
                                as='select'
                                value={volunteer.engagementCountId ?? undefined}
                                onChange={(event: any) => {
                                    onChangeHandler!(() => { return { ...volunteer, ...{ [event.target.name]: +event.target.value } } })
                                }}
                            >
                                <option value={undefined}>Select engagement</option>
                                {engagementCounts.map((engagement, index) => {
                                    return <option key={index} value={engagement.id}>{engagement.value}</option>
                                })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Label>
                    <h4>Personal Immigration Story</h4>
                    {!volunteer.immigrationStoryId &&
                        <div className="text-danger">(You must select one)</div>
                    }
                </Form.Label>
                <Row className="mb-4">
                    <Col md={6}>
                        <Form.Group className='mb-3' controlId='immigrationStory'>
                            <Form.Label>Please select your most recent immigrant in your family history to the United States. </Form.Label>
                            <Form.Control
                                name='immigrationStoryId'
                                as='select'
                                value={volunteer.immigrationStoryId ?? undefined}
                                onChange={(event: any) => {
                                    if (+event.target.value !== 2) {
                                        volunteer.immigrationStoryCountry = undefined;
                                        volunteer.isRefugee = false;
                                        onChangeHandler!(() => { return { immigrationStoryCountry: undefined } })
                                        onChangeHandler!(() => { return { ...volunteer, ...{ isRefugee: false } } })
                                    }
                                    onChangeHandler!(() => { return { ...volunteer, ...{ [event.target.name]: +event.target.value } } })
                                }}
                            >
                                <option value={undefined}>Select Immigration Story</option>
                                {immigrationStories.map((immigrationStory, index) => {
                                    return <option key={index} value={immigrationStory.id}>{immigrationStory.story}</option>
                                })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                {volunteer.immigrationStoryId == 2 &&
                    <Row className="mb-4">
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>From the country of:</Form.Label>
                                <Form.Control
                                    isInvalid={(!volunteer.immigrationStoryCountry || volunteer.immigrationStoryCountry?.trim() == '')}
                                    value={volunteer.immigrationStoryCountry ?? ''}
                                    name="immigrationStoryCountry"
                                    onChange={handleChange}
                                    disabled={!(volunteer.immigrationStoryId == 2)}
                                    placeholder="Country"
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='caseClientHasUSTie'>
                                <Form.Label>I came to the U.S. as a refugee</Form.Label>
                                <Stack direction='horizontal' gap={3}>
                                    <Form.Check
                                        type='radio'
                                        label='Yes'
                                        name='isRefugee'
                                        checked={volunteer.isRefugee ?? false}
                                        onChange={(event: any) => {
                                            onChangeHandler!(() => { return { ...volunteer, ...{ [event.target.name]: event.target.checked } } })
                                        }}
                                    />
                                    <Form.Check
                                        type='radio'
                                        label='No'
                                        name='isRefugee'
                                        checked={!volunteer.isRefugee ?? true}
                                        onChange={(event: any) => {
                                            onChangeHandler!(() => { return { ...volunteer, ...{ [event.target.name]: !event.target.checked } } })
                                        }}
                                    />
                                </Stack>
                            </Form.Group>
                        </Col>
                    </Row>
                }
            </Card.Body>
        </Card>:<Exception message={error.message}></Exception>
    )
}