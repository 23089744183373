import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export default (props: any) => {

  const verifyRemoveLocalOffice = () => {
    props.verifyRemoveLocalOffice(props.data)
  }

  const showEditLocalOfficeModal = () => {
    props.showEditLocalOfficeModal(props.data)
}

  return (  
    <div style={{ cursor: "pointer" }}>
    <span>
      <FontAwesomeIcon icon={faTrash} onClick={verifyRemoveLocalOffice} />

      

    </span>
    <span style={{paddingLeft:"20px"}}>
      <FontAwesomeIcon icon={faEdit} title='Edit Agency Name' onClick={showEditLocalOfficeModal} />
    </span>
    </div>
  );
};

