import { GridApi } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { InactivateLocalOfficeUserDTO, LocalOfficeUserDTO, ReinviteUserDTO } from "../../volunteer-tracking-client";
import { Exception } from "../Exception";
import VolunteerListActionRenderer from "./UserListActionRenderer";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { VerifyRemoveLocalOfficeUserModal } from "./VerifyRemoveLocalOfficeUserModal";
import { LocalOfficeUserService } from "../../services/LocalOfficeUserService";
import { CreateLocalOfficeUserModal } from "../NationalResettlementOffice/CreateLocalOfficeUserModal";
import { useAppContext } from "../../AppContext";
import { VerifyReactivateLocalOfficeUserModal } from "./VerifyReactivateLocalOfficeUserModal";
import { VerifyReinviteModal } from "./VerifyReinviteModal";

type LocalOfficeUserListProperties = {
    localOfficeUsers: LocalOfficeUserDTO[]
}

export const LocalOfficeUserList = (props: LocalOfficeUserListProperties) => {
    const app = useAppContext();

    const [gridApi, setGridApi] = useState<GridApi>();
    const [showRemoveLocalOfficeUserModal, setShowRemoveLocalOfficeUserModal] = useState(false);
    const [showReactivateLocalOfficeUserModal, setShowReactivateLocalOfficeUserModal] = useState(false);
    const [showCreateLocalOfficeUserModal, setShowCreateLocalOfficeUserModal] = useState(false);
    const [showReinviteUserModal, setShowReinviteUserModal] = useState(false)
    const [activeLocalOfficeUsers, setActiveLocalOfficeUsers] = useState<LocalOfficeUserDTO[]>([]);
    const [inactiveLocalOfficeUsers, setInactiveLocalOfficeUsers] = useState<LocalOfficeUserDTO[]>([]);
    const [showActive, setShowActive] = useState<boolean>(true);
    const [error, setError] = useState<Error>();
    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit()
        setGridApi(params.api)
    }

    useEffect(() => {
        const activeLocalOfficeUsers = props.localOfficeUsers.filter(
            lou => lou.isActive)
        setActiveLocalOfficeUsers(activeLocalOfficeUsers);
        const inactiveLocalOfficeUsers = props.localOfficeUsers.filter(lou => !lou.isActive);
        setInactiveLocalOfficeUsers(inactiveLocalOfficeUsers);

    }, [props.localOfficeUsers]);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
    };

    const getRowNodeId = (data: any) => data.id + ":" + data.localOffice.id;

    const handleRemoveLocalOfficeUser = async () => {
        const row: LocalOfficeUserDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                const inactivateLocalOfficeUserDTO: InactivateLocalOfficeUserDTO = {
                    principalId: row[0].principalId,
                    localOfficeId: row[0].localOffice?.id
                }
                await LocalOfficeUserService.deleteLocalOfficeUser(inactivateLocalOfficeUserDTO)
                gridApi?.applyTransaction({ remove: [row[0]] })
                const index = activeLocalOfficeUsers.findIndex(v => v.id === row[0].id)
                activeLocalOfficeUsers.splice(index, 1);
                row[0] = { ...row[0], ...{ isActive: false } }
                inactiveLocalOfficeUsers.push(row[0])
                setShowRemoveLocalOfficeUserModal(false)
            } catch (error: any) {
                setError(error)
            }
        }
    }

    const handleReactivateLocalOfficeUser = async () => {
        const row: LocalOfficeUserDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                const inactivateLocalOfficeUserDTO: InactivateLocalOfficeUserDTO = {
                    principalId: row[0].principalId,
                    localOfficeId: row[0].localOffice?.id
                }
                await LocalOfficeUserService.reactivateLocalOfficeUser(inactivateLocalOfficeUserDTO)
                gridApi?.applyTransaction({ remove: [row[0]] })
                const index = inactiveLocalOfficeUsers.findIndex(v => v.id === row[0].id)
                inactiveLocalOfficeUsers.splice(index, 1);
                row[0] = { ...row[0], ...{ isActive: true } }
                activeLocalOfficeUsers.push(row[0])
                setShowReactivateLocalOfficeUserModal(false)
            } catch (error: any) {
                setError(error)
            }
        }

    }

    const handleReinviteUser = async () => {
        const row: LocalOfficeUserDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                const reinviteUserDTO: ReinviteUserDTO = {
                    principalId: row[0].principalId,
                }
                await LocalOfficeUserService.reinviteUser(reinviteUserDTO)  
                setShowReinviteUserModal(false);
            } catch (error: any) {
                setError(error)
            }
        }
    }

    const handleRemoveLocalOfficeUserModalClose = () => {
        setShowRemoveLocalOfficeUserModal(false)
    }

    const handleCreateLocalOfficeUser = async (event: any, lou: LocalOfficeUserDTO, localOfficeId: number) => {
        gridApi?.applyTransaction({ add: [lou] })
        setShowCreateLocalOfficeUserModal(false)
        // eslint-disable-next-line eqeqeq
        const localOffice = lou.localOfficeLocalOfficeUsers!.find(lo => lo.localOfficeId == localOfficeId)
        if (lou.email === app.user?.email) {
            app.addRole(app.user, "OfficeUser.All", localOffice?.id, localOffice?.localOffice?.name)
        }
    }

    const handleCreateLocalOfficeUserModalClose = () => {
        setShowCreateLocalOfficeUserModal(false)
    }

    const handleReactivateLocalOfficeUserModalClose = () => {
        setShowReactivateLocalOfficeUserModal(false)
    }

    const handleReinviteUserModalClose = () => {
        setShowReinviteUserModal(false)
    }

    const handleShowActiveClick = () => {
        setShowActive(!showActive);
    }


    return (
        !error ?
            <Container>
                <VerifyRemoveLocalOfficeUserModal
                    show={showRemoveLocalOfficeUserModal}
                    confirmHandler={handleRemoveLocalOfficeUser}
                    closeHandler={handleRemoveLocalOfficeUserModalClose}
                ></VerifyRemoveLocalOfficeUserModal>
                <VerifyReactivateLocalOfficeUserModal
                    show={showReactivateLocalOfficeUserModal}
                    confirmHandler={handleReactivateLocalOfficeUser}
                    closeHandler={handleReactivateLocalOfficeUserModalClose}
                ></VerifyReactivateLocalOfficeUserModal>
                <VerifyReinviteModal
                    show={showReinviteUserModal}
                    confirmHandler={handleReinviteUser}
                    closeHandler={handleReinviteUserModalClose}
                ></VerifyReinviteModal>
                <CreateLocalOfficeUserModal
                    show={showCreateLocalOfficeUserModal}
                    confirmHandler={handleCreateLocalOfficeUser}
                    closeHandler={handleCreateLocalOfficeUserModalClose}
                ></CreateLocalOfficeUserModal>
                <Row className='mb-3'>
                    <Col className='d-flex'>
                        <Button
                            className='mb-3'
                            onClick={() => setShowCreateLocalOfficeUserModal(true)}>
                            Add Local Office User
                        </Button>
                    </Col>
                    <Col >
                        <Button className='mb-3' style={{ "float": "right" }} onClick={handleShowActiveClick}>{showActive ? 'Show Inactive Users' : 'Show Active Users'}</Button>
                    </Col>
                </Row>
                <h3>Local Office Users</h3>
                <div className="ag-theme-alpine" style={{ height: 700 }}>
                    <AgGridReact
                        getRowNodeId={getRowNodeId}
                        frameworkComponents={{
                            volunteerListActionRenderer: VolunteerListActionRenderer
                        }}
                        onGridReady={onGridReady}
                        rowData={showActive ? activeLocalOfficeUsers : inactiveLocalOfficeUsers}
                        pagination={true}
                        paginationAutoPageSize={true}
                        defaultColDef={defaultColDef}
                        rowSelection={'single'}
                    >
                        <AgGridColumn headerName="Last Name" field="lastName"></AgGridColumn>
                        <AgGridColumn headerName="First Name" field="firstName"></AgGridColumn>
                        <AgGridColumn headerName="Email" field="email"></AgGridColumn>
                        <AgGridColumn headerName="Local Office" field="localOffice.name"></AgGridColumn>
                        <AgGridColumn headerName="Resettlement Agency" field="localOffice.nationalResettlementAgency.name"></AgGridColumn>
                        <AgGridColumn field="localOffice.nationalResettlementAgency.id" hide={true}></AgGridColumn>
                        <AgGridColumn cellRenderer="volunteerListActionRenderer" cellRendererParams={{
                            verifyRemoveVolunteer: () => { setShowRemoveLocalOfficeUserModal(true) },
                            verifyReactivateVolunteer: () => { setShowReactivateLocalOfficeUserModal(true) },
                            verifyReinviteUser: () => { setShowReinviteUserModal(true)},
                            titleText: 'Local Office User'
                        }}></AgGridColumn>
                    </AgGridReact>
                </div>
            </Container > :
            <>
                <Exception message={error.message}></Exception>
            </>
    )

} 