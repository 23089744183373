import React from "react"
import { Container } from "react-bootstrap"

export const UnauthorizedComponent = () =>{
    return(
        <Container>
            <p>You are not authorized to view this page</p>
            <p>Contact your adminstrator if you believe you've reached this page in error</p>
        </Container>
    )
}