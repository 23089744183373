import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { CaseClient, CaseDTO, CaseSponsorshipGroupDTO, FamilyMemberDTO } from "../../volunteer-tracking-client";
import GACaseListActionRenderer from "./GACaseListActionRenderer";
import { ViewCaseSponsorshipInfoModal } from "./ViewCaseSponsorshipInfoModal";
import { Exception } from "../Exception";

const client: CaseClient = new CaseClient();

export const GACaseList = () => {
    const [cases, setCases] = useState<CaseDTO[]>([]);
    const [showCaseSponsorshipInfo, setShowCaseSponsorshipInfo] = useState<boolean>(false);
    const [selectedCase, setSelectedCase] = useState<CaseDTO>();
    const [error, setError] = useState<Error>()

    useEffect(() => {
        const getAllCases = async () => {
            await client.getAllCases()
                .then((resp) => { setCases(resp); });
        };
        try{
            getAllCases();
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    const onGridReady = (params: any) => {
        let model = params.api.getFilterModel();
        model = {
            status: {
                filterType: 'text',
                type: 'contains',
                filter: 'Active'
            }
        };
        params.api.setFilterModel(model);
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
    };

    return (
        !error?
        <Container>
            {selectedCase && selectedCase.caseSponsorshipGroups &&
                <ViewCaseSponsorshipInfoModal
                    show={showCaseSponsorshipInfo}
                    caseSponsorshipGroup={selectedCase.caseSponsorshipGroups[0]}
                    closeHandler={() => setShowCaseSponsorshipInfo(false)}
                />
            }
            <h3>Clients</h3>
            <div className="ag-theme-alpine" style={{ height: 700 }}>
                <AgGridReact
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    rowData={cases}
                    frameworkComponents={{
                        gaCaseListActionRenderer: GACaseListActionRenderer
                    }}
                    pagination={true}
                    paginationAutoPageSize={true}
                >
                    <AgGridColumn headerName='Status' colId='status' field='status.name'
                        filterParams={
                            {
                                newRowsAction: 'keep'
                            }
                        }
                    ></AgGridColumn>
                    <AgGridColumn headerName="Local Office" field="localOffice.name"></AgGridColumn>
                    <AgGridColumn headerName="National Resettlement Agency" field="localOffice.nationalResettlementAgency.name"></AgGridColumn>
                    <AgGridColumn headerName="Type Paired" valueGetter={params => {
                        if (params.data.caseSponsorshipGroups) {
                            let typesPaired: Array<string> = [];
                            params.data.caseSponsorshipGroups.forEach((csg: CaseSponsorshipGroupDTO) => {
                                if (csg.type && csg.type.type && !typesPaired.includes(csg.type.type)) {
                                    typesPaired.push(csg.type.type);
                                }
                            });

                            return typesPaired;
                        }
                    }}></AgGridColumn>
                    <AgGridColumn headerName="Date Paired" valueGetter={params => {
                        if (params.data.clientDOA) {
                            return params.data.clientDOA.toString().split('T')[0];
                        }
                    }}></AgGridColumn>
                    <AgGridColumn headerName="City" valueGetter={params => {
                        if (params.data.familyMembers) {
                            let city = '';
                            params.data.familyMembers.forEach((fm: FamilyMemberDTO) => {
                                if (fm.isPrimary && fm.city) {
                                    city = fm.city;
                                }
                            });
                            return city
                        }
                    }}></AgGridColumn>
                    <AgGridColumn headerName="State/Territory" valueGetter={params => {
                        if (params.data.familyMembers) {
                            let state = '';
                            params.data.familyMembers.forEach((fm: FamilyMemberDTO) => {
                                if (fm.isPrimary && fm.state) {
                                    state = fm.state.name ?? '';
                                }
                            });
                            return state;
                        }
                    }}></AgGridColumn>
                    <AgGridColumn headerName="Contact Number" valueGetter={params => {
                        if (params.data.familyMembers) {
                            let contactNumber = '';
                            params.data.familyMembers.forEach((fm: FamilyMemberDTO) => {
                                if (fm.isPrimary && fm.contactNumber) {
                                    contactNumber = fm.contactNumber;
                                }
                            });
                            return contactNumber;
                        }
                    }}></AgGridColumn>
                    <AgGridColumn pinned='right' width={100} headerName="Actions" cellRenderer="gaCaseListActionRenderer" cellRendererParams={{
                        viewSponsorshipGroupInformation: (data: CaseDTO) => {
                            setSelectedCase(data);
                            setShowCaseSponsorshipInfo(true)
                        }
                    }}></AgGridColumn>
                </AgGridReact>
            </div>
        </Container >:<Exception message={error.message}></Exception>
    )
}