import {NationalResettlementAgencyClient, NationalResettlementAgencyDTO } from '../volunteer-tracking-client';

const client: NationalResettlementAgencyClient = new NationalResettlementAgencyClient();

export const NationalResettlementAgencyService = {
    getAllNationalResettlementAgencies: async () => {
       const result:NationalResettlementAgencyDTO[] = await client.getAllNationalResettlementAgencies()
       return result
    },
    updateNationalResettlementAgency: async(dto:NationalResettlementAgencyDTO) => {
        await client.editNationalResettlmentAgency(dto)
    }
}