import React, { useState } from 'react'
import { Stack, Spinner, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { NationalResettlementAgencyClient, CreateNationalResettlementAgencyDTO } from '../../volunteer-tracking-client';
import { Exception } from '../Exception';

const nationalResettlementAgencyClient: NationalResettlementAgencyClient = new NationalResettlementAgencyClient();

export const CreateNationalResettlementAgency = () => {
    const [createNationalResettlementAgencyDTO, setCreateNationalResettlementAgencyDTO] = useState<CreateNationalResettlementAgencyDTO>();
    const [isLoading, setIsLoading] = useState(false);
    const [showCreateSuccess, setShowCreateSuccess] = useState(false);
    const [showBadRequest, setShowBadRequest] = useState(false);
    const [error, setError] = useState<Error>();
    const [nameErrorMessage, setNameErrorMessage] = useState<string>();

    const navigate = useNavigate();

    const handleInputChange = (event: any): void => {
        var key = event.target.name;
        var value = event.target.value;
        setCreateNationalResettlementAgencyDTO(prevState => ({ ...prevState, [key]: value }));
    }

    const handleCreateAgency = async (event: any) => {
        let agencyExists = false;
        if (createNationalResettlementAgencyDTO?.name) {
            agencyExists = await nationalResettlementAgencyClient.checkIfNationalResettlementAgencyExists(createNationalResettlementAgencyDTO.name);
        }
        if (!agencyExists) {
            setIsLoading(true);
            setNameErrorMessage('');
            try {
                await nationalResettlementAgencyClient.createNationalResettlementAgency(createNationalResettlementAgencyDTO);
                setShowCreateSuccess(true);
            } catch (error: any) {
                if (error.status === 404) {
                    setShowBadRequest(true);
                } else {
                    setError(error)
                }
            } finally {
                setIsLoading(false);
                navigate('/list-national-resettlement-agencies');
            }
        } else {
            setNameErrorMessage('National Resettlement Agency with that name already exists.');
            setIsLoading(false);
        }
    }

    const handleCancel = () => {
        navigate('/list-national-resettlement-agencies');
    }

    return (
        !error ?
            <>
                <Alert show={showCreateSuccess} variant="success" onClose={() => setShowCreateSuccess(false)} dismissible>
                    <Alert.Heading>Success</Alert.Heading>
                    <p>
                        National Resettlement Agency Created!
                    </p>
                </Alert>
                <Alert show={showBadRequest} variant="danger" onClose={() => setShowBadRequest(false)} dismissible>
                    <Alert.Heading>Bad Request</Alert.Heading>
                    <p>
                        There was a problem creating the national resettlement agency.
                    </p>
                </Alert>
                <Stack>
                    <h1 id='tableLabel'>Create National Resettlement Agency</h1>
                    {isLoading &&
                        <Stack className='mx-auto text-center mt-5'>
                            <Spinner className='mx-auto' animation='border' variant='primary' />
                        </Stack>
                    }
                    {(!isLoading) &&
                        <Stack>
                            <Form>
                                <Row className='my-3'>
                                    <Col>
                                        <Form.Group className='w-50 mb-3' controlId='name'>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control required isInvalid={nameErrorMessage ? true : false} type="text" placeholder='Name' name='name' onChange={handleInputChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {nameErrorMessage}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Stack direction='horizontal' gap={3}>
                                    <Button className='mt-3' variant='secondary' onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                    <Button className='mt-3' variant='primary' onClick={handleCreateAgency}>
                                        Save
                                    </Button>
                                </Stack>
                            </Form>
                        </Stack>
                    }
                </Stack>
            </> :
            <>
                <Exception message={error.message}></Exception>
            </>
    );
}
