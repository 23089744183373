import React, { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { InactivateNationalResettlementUserDTO, NationalResettlementUserClient, NationalResettlementUserDTO, ReinviteUserDTO } from '../../volunteer-tracking-client';
import { useAppContext } from '../../AppContext';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { GridApi } from 'ag-grid-community';
import { VerifyDeleteNationalResettlementUserModal } from './VerifyDeleteNationalResettlementUserModal';
import { Exception } from '../Exception';
import { NationalResettlementUserService } from '../../services/NationalResettlementUserService';
import NationalResettlementUserListActionRenderer from './NationalResettlementUserListActionRenderer';
import { CreateNationalResettlementUserModal } from '../NationalResettlementOffice/CreateNationalResettlementUserModal';
import { VerifyReactivateNationalResettlementUserModal } from './VerifyReactivateNationalResettlementUserModal';
import { VerifyReinviteModal } from '../Common/VerifyReinviteModal';

const client: NationalResettlementUserClient = new NationalResettlementUserClient();

export const ListNationalResettlementUsers = () => {
    const [showDeleteNationalResettlementUserModal, setShowDeleteNationalResettlementUserModal] = useState(false);
    const [showReinviteUserModal, setShowReinviteUserModal] = useState(false)
    const [showReactivateNationalResettlementUserModal, setShowReactivateNationalResettlementUserModal] = useState(false);
    const [showCreateNationalResettlementUserModal, setShowCreateNationalResettlementUserModal] = useState(false);
    const [activeNationalResettleMentUsers, setActiveNationalResettlementUsers] = useState<NationalResettlementUserDTO[]>([]);
    const [inactiveNationalResettlementUsers, setInactiveNationalResettlementUsers] = useState<NationalResettlementUserDTO[]>([]);
    const [showActive, setShowActive] = useState<boolean>(true);
    const [gridApi, setGridApi] = useState<GridApi>();
    const [error, setError] = useState<Error>();
    const app = useAppContext();

    useEffect(() => {
        const getAllNationalResettlementUsers = async () => {
            const getUsers = (nationalResettlementUsers: NationalResettlementUserDTO[]): NationalResettlementUserDTO[] => {
                const expandedUsers: NationalResettlementUserDTO[] = [];
                nationalResettlementUsers.forEach(u => u.nationalResettlementAgencyNationalResettlementUsers?.forEach(nra => {
                    const user = {
                        ...u, ...{ nationalResettlementAgency: nra.nationalResettlementAgency, isActive: nra.isActive }
                    }
                    expandedUsers.push(user)
                }))
                return expandedUsers;
            }

            if (app.user?.roles?.includes('GlobalAdmin.All')) {
                await client.getAllNationalResettlementUsers()
                    .then((resp) => {
                        setRowData(getUsers(resp))
                    });
            } else if (app.user?.roles?.includes('NationalOfficeUser.All')) {
                const organizationId = parseInt(localStorage.getItem("selectedOrganizationId")!);
                await client.getAllNationalResettlementUsersByAgencyId(organizationId)
                    .then((resp) => {
                        resp.map(nru => nru.nationalResettlementAgency = nru.nationalResettlementAgencyNationalResettlementUsers?.find(
                            nra => nra.nationalResettlementAgencyId === organizationId));
                        setRowData(getUsers(resp))
                    });
            }
        };
        try{
            getAllNationalResettlementUsers();
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    const onGridReady = (params: any) => {
        setGridApi(params.api)
        params.api.sizeColumnsToFit()
    }

    const setRowData = (users: NationalResettlementUserDTO[]) => {
        const activeVolunteers = users.filter(lov => lov.isActive);
        setActiveNationalResettlementUsers(activeVolunteers);
        const inactiveVolunteers = users.filter(lov => !lov.isActive);
        setInactiveNationalResettlementUsers(inactiveVolunteers);
    }
    const handleDeleteNationalResettlementUser = async () => {
        const row: NationalResettlementUserDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                var dto: InactivateNationalResettlementUserDTO = {
                    principalId: row[0].principalId,
                    nationalResettlementAgencyId: row[0].nationalResettlementAgency?.id
                }
                await NationalResettlementUserService.deleteNationalResettlementUser(dto)
                gridApi?.applyTransaction({ remove: [row[0]] })
                const index = activeNationalResettleMentUsers.findIndex(v => v.id === row[0].id)
                activeNationalResettleMentUsers.splice(index, 1);
                row[0] = { ...row[0], ...{ isActive: false } }
                inactiveNationalResettlementUsers.push(row[0])

                setShowDeleteNationalResettlementUserModal(false)
            } catch (error: any) {
                setError(error)
            }
        }
    }

    const handleReactivateNationalResettlementUser = async () => {
        const row: NationalResettlementUserDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                const inactivateLocalOfficeUserDTO: InactivateNationalResettlementUserDTO = {
                    principalId: row[0].principalId,
                    nationalResettlementAgencyId: row[0].nationalResettlementAgency?.id
                }
                await NationalResettlementUserService.reactivateNationalResettlementUser(inactivateLocalOfficeUserDTO)
                gridApi?.applyTransaction({ remove: [row[0]] })
                const index = inactiveNationalResettlementUsers.findIndex(v => v.id === row[0].id)
                inactiveNationalResettlementUsers.splice(index, 1);
                row[0] = { ...row[0], ...{ isActive: true } }
                activeNationalResettleMentUsers.push(row[0])
                setShowReactivateNationalResettlementUserModal(false)
            } catch (error: any) {
                setError(error)
            }
        }
    }

    const handleReinviteUser = async () => {
        const row: NationalResettlementUserDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                const reinviteUserDTO: ReinviteUserDTO = {
                    principalId: row[0].principalId,
                }
                await NationalResettlementUserService.reinviteNationalOffficeUser(reinviteUserDTO)
                setShowReinviteUserModal(false);
            } catch (error: any) {
                setError(error)
            }
        }
    }

    const handleCreateNationalResettlementUser = async (event: any, nru: NationalResettlementUserDTO) => {
        activeNationalResettleMentUsers.push(nru)
        gridApi?.applyTransaction({ add: [nru] })
        setShowCreateNationalResettlementUserModal(false)
    }

    const handleDeleteNationalResettlementUserModalClose = async () => {
        setShowDeleteNationalResettlementUserModal(false)
    }

    const handleReactivateNationalResettlementUserModalClose = async () => {
        setShowReactivateNationalResettlementUserModal(false)
    }

    const handleCreateNationalResettlementUserModalClose = async () => {
        setShowCreateNationalResettlementUserModal(false)
    }


    const handleReinviteUserModalClose = () => {
        setShowReinviteUserModal(false)
    }


    const getRowNodeId = (data: any) => data.id + ":" + data.nationalResettlementAgency.id;

    const handleShowActiveClick = () => {
        setShowActive(!showActive);
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
    };

    return (
        !error ?
            <Container>
                <VerifyDeleteNationalResettlementUserModal
                    show={showDeleteNationalResettlementUserModal}
                    confirmHandler={handleDeleteNationalResettlementUser}
                    closeHandler={handleDeleteNationalResettlementUserModalClose}
                />
                <VerifyReactivateNationalResettlementUserModal
                    show={showReactivateNationalResettlementUserModal}
                    confirmHandler={handleReactivateNationalResettlementUser}
                    closeHandler={handleReactivateNationalResettlementUserModalClose}
                />
                <VerifyReinviteModal
                    show={showReinviteUserModal}
                    confirmHandler={handleReinviteUser}
                    closeHandler={handleReinviteUserModalClose}
                ></VerifyReinviteModal>
                <CreateNationalResettlementUserModal
                    show={showCreateNationalResettlementUserModal}
                    confirmHandler={handleCreateNationalResettlementUser}
                    closeHandler={handleCreateNationalResettlementUserModalClose}
                />
                <h3>National Resettlement Users</h3>
                <Row className='mb-3'>
                    <Col className='d-flex'>
                        <Button
                            className='mb-3'
                            onClick={() => setShowCreateNationalResettlementUserModal(true)}>
                            Add New User
                        </Button>
                    </Col>
                    <Col >
                        <Button className='mb-3' style={{ "float": "right" }} onClick={handleShowActiveClick}>{showActive ? 'Show Inactive Users' : 'Show Active Users'}</Button>
                    </Col>
                </Row>
                <div className="ag-theme-alpine" style={{ height: 700 }}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        getRowNodeId={getRowNodeId}
                        frameworkComponents={{
                            nationalResettlementUserListActionRenderer: NationalResettlementUserListActionRenderer
                        }}
                        rowData={showActive ? activeNationalResettleMentUsers : inactiveNationalResettlementUsers}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        pagination={true}
                        paginationAutoPageSize={true}
                    >
                        <AgGridColumn headerName="Last Name" field="lastName" sort='asc' sortIndex={2}></AgGridColumn>
                        <AgGridColumn headerName="First Name" field="firstName"></AgGridColumn>
                        <AgGridColumn headerName="Email" field="email"></AgGridColumn>
                        <AgGridColumn headerName="National Resettlement Agency" field="nationalResettlementAgency.name"></AgGridColumn>

                        <AgGridColumn cellRenderer="nationalResettlementUserListActionRenderer" cellRendererParams={{
                            verifyDeleteNationalResettlementUser: () => { setShowDeleteNationalResettlementUserModal(true) },
                            verifyReactivateNationalResettlementUser: () => { setShowReactivateNationalResettlementUserModal(true) },
                            verifyReinviteUser: () => { setShowReinviteUserModal(true)},
                        }}></AgGridColumn>
                    </AgGridReact>
                </div>
            </Container>
            : <Exception message={error.message}></Exception>
    );
}

ListNationalResettlementUsers.displayName = ListNationalResettlementUsers.name;