import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faClipboardList, faPen } from '@fortawesome/free-solid-svg-icons';
import { Stack } from 'react-bootstrap';
import { CreateCaseActivityDTO } from '../../../volunteer-tracking-client';

type LocalOfficeCaseActivitiesActionRendererProps = {
    caseActivity: CreateCaseActivityDTO,
    onViewClick: (selectedCaseActivity: CreateCaseActivityDTO) => void,
    onEditClick: (selectedCaseActivity: CreateCaseActivityDTO) => void
}

export default (props: LocalOfficeCaseActivitiesActionRendererProps) => {
    return (
        <Stack direction='horizontal' gap={2}>
            <span style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faClipboardList} onClick={() => props.onViewClick(props.caseActivity)} title='View Activity Summary' />
            </span>
            <span style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faPen} onClick={() => props.onEditClick(props.caseActivity)} title='Edit Activity' />
            </span>
        </Stack>
    );
};

