import { InactivateNationalResettlementUserDTO, NationalResettlementUserClient, NationalResettlementUserDTO, ReinviteUserDTO } from '../volunteer-tracking-client';

const client: NationalResettlementUserClient = new NationalResettlementUserClient();

export const NationalResettlementUserService = {
    getNationalResettlementUser: async (principalId: string | undefined) => {
        const result: NationalResettlementUserDTO = await client.getByPrincipalId(principalId)
        return result
    },
    getByEmail: async (email: string | undefined) => {
        const result: NationalResettlementUserDTO = await client.getByEmail(email)
        return result
    },
    deleteNationalResettlementUser: async (dto: InactivateNationalResettlementUserDTO | undefined) => {
        const result = await client.deleteNationalResettlementUser(dto)
        return result
    },
    reactivateNationalResettlementUser: async (dto: InactivateNationalResettlementUserDTO) => {
        await client.reactivateNationalResettlementUser(dto)
    },
    createNationalResettlementUser: async (dto: NationalResettlementUserDTO) => {
        const result = await client.createNationalResettlementInvite(dto)
        return result
     },
     reinviteNationalOffficeUser: async (dto: ReinviteUserDTO) => {
        await client.reinviteUser(dto)
     }
}