import React, { useState } from "react"
import { Button, Col, Form, Modal } from "react-bootstrap"
import { NationalResettlementAgencyDTO } from "../../volunteer-tracking-client"

type VerifyDeleteModalProps = {
    show: boolean,
    confirmHandler: (agencyDto:NationalResettlementAgencyDTO) => void
    closeHandler: () => void,
    agency:NationalResettlementAgencyDTO

}


export const EditNationalResettlementAgencyModal = (props: VerifyDeleteModalProps) => {
    const [agency, setAgency] = useState<NationalResettlementAgencyDTO>(props.agency)
    

    const onConfirm = () => {
        props.confirmHandler(agency)
    }

    const handleInputChange = (event: any): void => {
        var key = event.target.name;
        var value = event.target.value;
        setAgency(prevState => ({ ...prevState, [key]: value }));
    }

    return (
        <Modal show={props.show} onHide={props.closeHandler} onShow={()=>setAgency(props.agency)} centered>
            <Modal.Header closeButton>
                <Modal.Title>UpdateNational Resettlement Agency</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Col sm={12}>
          <Form.Group className='mb-3' controlId='name'>
            <Form.Label>Agency Name</Form.Label>
            <Form.Control type="input"  value={agency.name ?? ''} placeholder='Agency Name' name='name'
              onChange={(event: any) => { handleInputChange(event) }}  />
          </Form.Group>
        </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.closeHandler}>Cancel</Button>
                <Button variant="primary" onClick={onConfirm}>Yes</Button>
            </Modal.Footer>
        </Modal>
    )
}