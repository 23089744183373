import React, { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { CaseDTO, CaseClient } from '../../volunteer-tracking-client';
import { useAppContext } from '../../AppContext';
import { Container } from 'react-bootstrap';
import { NationalResettlementUserService } from '../../services/NationalResettlementUserService';
import { Exception } from '../Exception';

const caseClient: CaseClient = new CaseClient()

export const NationalResettlementAgencyCases = () => {
    const [cases, setCases] = useState<CaseDTO[]>([]);
    const [error, setError] = useState<Error>();

    const app = useAppContext();

    useEffect(() => {
        const getResettlementOfficeCases = async () => {
            try{
            const resettlementOfficeUser = await NationalResettlementUserService.getNationalResettlementUser(app.user?.uniqueId);
            if (resettlementOfficeUser) {
                await caseClient.getResettlementAgencyCases(parseInt(localStorage.getItem("selectedOrganizationId")!))
                    .then((resp) => {

                        const cases: CaseDTO[] | undefined = resp.map(c => {
                            c.familyMembers = c.familyMembers?.filter(f => f.isPrimary)
                            return c
                        }).sort((c1: CaseDTO, c2: CaseDTO) => {
                            if (!c1.status) {
                                return 1
                            }
                            if (!c2.status) {
                                return -1
                            }
                            if (c1.status < c2.status) {
                                return -1
                            }
                            if (c2.status < c1.status) {
                                return -1
                            }
                            return 0
                        })
                        setCases(cases);
                    });
            }}catch(ex:any){

                setError(ex)
            }
        };

        getResettlementOfficeCases();
    }, []);

    const onGridReady = (params: any) => {
        let model = params.api.getFilterModel();
        model = {
            status: {
                filterType: 'text',
                type: 'contains',
                filter: 'Active'
            }
        };
        params.api.setFilterModel(model);
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
    };

    const getRowNodeId = (data: any) => data.id;

    return (
        !error?
        <Container>
            <h3>Clients</h3>
            <div className="ag-theme-alpine" style={{ height: 700 }}>
                <AgGridReact
                    getRowNodeId={getRowNodeId}
                    onGridReady={onGridReady}
                    rowData={cases}
                    pagination={true}
                    paginationAutoPageSize={true}
                    defaultColDef={defaultColDef}
                    rowSelection={'single'}
                >
                    <AgGridColumn headerName="Client #" field="number"></AgGridColumn>
                    <AgGridColumn headerName='Status' colId='status' field='status.name'
                        filterParams={
                            {
                                newRowsAction: 'keep'
                            }
                        }
                    ></AgGridColumn>
                    <AgGridColumn headerName="Local Office" field="localOffice.name"></AgGridColumn>
                    <AgGridColumn headerName="Arrival Date" field="clientDOA" cellRenderer={(data) => {
                        return data.value ? (new Date(data.value)).toLocaleDateString() : '';
                    }}></AgGridColumn>
                    <AgGridColumn headerName="Primary Last Name" valueGetter={params => {
                        return params.data.familyMembers[0].lastName
                    }}></AgGridColumn>
                    <AgGridColumn headerName="Primary First Name" valueGetter={params => {
                        return params.data.familyMembers[0].firstName
                    }}></AgGridColumn>
                    <AgGridColumn headerName="City" valueGetter={params => {
                        return params.data.familyMembers[0].city
                    }}></AgGridColumn>
                    <AgGridColumn headerName="State/Territory" valueGetter={params => {
                        return params.data.familyMembers[0].state.name
                    }}></AgGridColumn>
                    <AgGridColumn headerName="Zip Code" valueGetter={params => {
                        return params.data.familyMembers[0].zipCode
                    }}></AgGridColumn>
                    <AgGridColumn headerName="Contact Number" valueGetter={params => {
                        return params.data.familyMembers[0].contactNumber
                    }}></AgGridColumn>
                    <AgGridColumn headerName="Community Sponsorship Type" valueGetter={params => {
                        if (params.data.caseSponsorshipGroups && params.data.caseSponsorshipGroups[0]) {
                            return params.data.caseSponsorshipGroups[0].type.type
                        }
                    }}></AgGridColumn>

                </AgGridReact>
            </div>
        </Container >:
        <Exception message={error.message}></Exception>
    );
}
