
import React, { useEffect, useState } from "react"
import { Button, Col, Form, FormLabel, Row, Stack } from "react-bootstrap"
import { CaseActivityBlobClient, CreateCaseActivityTaskDTO } from "../../../volunteer-tracking-client"
import { InKindDonationItemComponent } from "./InKindDonationItemComponent"

type InKindDonationProperties = {
    onChangeHandler: (selectedActivity: CreateCaseActivityTaskDTO) => void
    selectedActivity: CreateCaseActivityTaskDTO
    caseNumber: string,
    review?: boolean
}

export const InKindDonationComponent = (props: InKindDonationProperties) => {
    const { onChangeHandler, selectedActivity, caseNumber } = props
    const [receipt, setReceipt] = useState<any>([])
    const [fileUploaded, setFileUploaded] = useState<boolean>(false)
    const [inKindDonationMade, setInKindDonationMade] = useState<boolean>(selectedActivity.inKindDonationMade || false)


    selectedActivity.inKindReceiptFileName = selectedActivity.inKindReceiptFilePath ?
        selectedActivity.inKindReceiptFilePath.split('/').pop() : ''

    useEffect(() => {
        setInKindDonationMade(props.selectedActivity.inKindDonationMade ?? inKindDonationMade);
    }, [props.selectedActivity.inKindDonationMade]);

    const blobClient = new CaseActivityBlobClient()

    const handleFileSelect = (event: any) => {
        const receipt = {
            ...event.target.files[0], ...{
                data: event.target.files[0],
                fileName: event.target.files[0].name
            }
        }
        setReceipt(receipt)
    }

    const handleInKindDonationClick = (event: any) => {
        setInKindDonationMade(!inKindDonationMade)
        onChangeHandler({
            ...selectedActivity, ...{
                [event.target.name]: event.target.value == 'true' ? true : false
            }
        })
    }

    const addInKindDonation = (event: any) => {
        if (!selectedActivity.inKindDonations) {
            selectedActivity.inKindDonations = []
        }
        selectedActivity.inKindDonations?.push({})
        onChangeHandler({
            ...selectedActivity, ...{
                inKindDonations: selectedActivity.inKindDonations
            }
        })
    }

    const uploadReceipt = async (event: any) => {
        const response = await blobClient.uploadBlob(caseNumber, receipt)
        setFileUploaded(true)
        onChangeHandler({
            ...selectedActivity, ...{
                inKindReceiptFileName: response.fileName,
                inKindReceiptFilePath: response.path,
            }
        })
    }

    const downloadReceipt = async (event: any) => {
        await blobClient.getBlob(selectedActivity.inKindReceiptFilePath).then(resp => {
            var blob = new Blob([resp.data], { type: resp.data.type });
            var filename = selectedActivity.inKindReceiptFilePath?.substring(selectedActivity.inKindReceiptFilePath.lastIndexOf('/')+1) ?? "";
            var csvURL = window.URL.createObjectURL(blob);
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', filename);
            tempLink.click();
        });
    }

    const removeInKindDonation = (index: number) => {
        selectedActivity.inKindDonations?.splice(index, 1)
        onChangeHandler({
            ...selectedActivity, ...{
                inKindDonations: selectedActivity.inKindDonations,
                inKindDonationTotal: selectedActivity.inKindDonations?.reduce((a, b) => {
                    return a + Number((b.amount || 0))
                }, 0)
            }
        })
    }

    return (
        <>
            <Form.Group>
                <h3>Was an in-kind donation made?</h3>
                <Stack direction="horizontal">
                    <Form.Check inline type="radio"
                        checked={
                            selectedActivity.inKindDonationMade?.toString() == "true"}
                        label="Yes"
                        name="inKindDonationMade"
                        value="true"
                        onChange={handleInKindDonationClick}
                        disabled={props.review ?? false}
                    />
                    <Form.Check inline type="radio"
                        checked={
                            selectedActivity.inKindDonationMade ? selectedActivity.inKindDonationMade.toString() == "false" : true}
                        label="No"
                        name="inKindDonationMade"
                        value="false"
                        onChange={handleInKindDonationClick}
                        disabled={props.review ?? false}
                    />
                </Stack>
            </Form.Group>
            {inKindDonationMade &&
                <>
                    {selectedActivity.inKindDonations?.length == 0 &&
                        <Form.Label className='text-danger'>You must add at least one in kind donation</Form.Label>
                    }
                    <Form.Group className="mb-3">
                        <Row>
                            <Col md={2}>
                                <FormLabel className="mr-3">In-Kind: Items donated</FormLabel>
                            </Col>
                            {props.review === undefined || !props.review &&
                                <Col md={2}>
                                    <Button onClick={addInKindDonation} disabled={props.review ?? false}>Add new item</Button>
                                </Col>
                            }
                        </Row>
                    </Form.Group>
                    <Row>
                        <Col md={8}>
                            {selectedActivity.inKindDonations?.map((ikd, index) => {
                                return (
                                    <InKindDonationItemComponent
                                        key={index}
                                        index={index}
                                        inKindDonation={ikd}
                                        onRemove={removeInKindDonation}
                                        review={props.review ?? false}
                                        onChangeHandler={(ikd) => {
                                            selectedActivity.inKindDonations![index] = ikd
                                            onChangeHandler({
                                                ...selectedActivity, ...{
                                                    inKindDonationTotal: selectedActivity.inKindDonations?.reduce((a, b) => {
                                                        return a + Number((b.amount || 0))
                                                    }, 0),
                                                    inKindDonations: selectedActivity.inKindDonations
                                                }
                                            })
                                        }} />
                                )
                            })}
                        </Col>
                    </Row>
                    < Form.Group className="mb-3">
                        <Row>
                            <Col md={2}>
                                <Form.Label>Total Cost</Form.Label>
                            </Col>
                            <Col md={2}>
                                <Form.Control
                                    disabled={true}
                                    name='inKindDonationTotal'
                                    value={selectedActivity.inKindDonationTotal}
                                    onChange={(event: any) => {
                                        onChangeHandler({ ...selectedActivity, ...{ inKindDonationTotal: event.target.value } })
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {(fileUploaded)&&
                            <Row>
                                <Col md={8}>
                                    <span className='text-success'>{selectedActivity.inKindReceiptFileName } uploaded successfully</span>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col md={2}>
                                <Form.Label>Upload a receipt</Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control type="file" name="receipt"
                                    onChange={handleFileSelect}
                                    disabled={props.review ?? false} />
                            </Col>
                            {props.review === undefined || !props.review &&
                                <Col md={2}>
                                    <Button onClick={uploadReceipt}>Upload</Button>
                                </Col>
                            }
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col md={2}>
                                <Form.Label>Receipt Filename: </Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Label>{selectedActivity.inKindReceiptFileName ?? ""}</Form.Label>
                            </Col>
                            {selectedActivity.inKindReceiptFilePath &&
                                <Col md={4}>
                                    <Button onClick={downloadReceipt}>Download</Button>
                                </Col>
                            }
                        </Row>
                    </Form.Group>
                </>
            }
        </>
    )
}