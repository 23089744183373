import React, {
    useContext,
    createContext,
    useState,
    MouseEventHandler,
    useEffect
} from 'react';
import "isomorphic-fetch"
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { useMsal } from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { NationalResettlementUserService } from './services/NationalResettlementUserService';
import { LocalOfficeUserService } from './services/LocalOfficeUserService';
import { VolunteerService } from './services/VolunteerService';

export interface AppUser {
    uniqueId?: string,
    idToken?: string
    displayName?: string,
    email?: string,
    avatar?: string,
    timeZone?: string,
    timeFormat?: string,
    roles?: string[],
    roleSelections?: any,
    isAuthenticated?: boolean,
    homeAccountId?: string
};

export interface AppError {
    message: string,
    debug?: string
};

type AppContext = {
    user?: AppUser;
    error?: AppError;
    signIn?: any;
    signOut?: MouseEventHandler<HTMLElement>;
    signOutSilent?: any;
    addRole?: any,
    displayError?: Function;
    clearError?: Function;
    authProvider?: AuthCodeMSALBrowserAuthenticationProvider;
}

const appContext = createContext<AppContext>({
    user: undefined,
    error: undefined,
    signIn: undefined,
    signOut: undefined,
    signOutSilent: undefined,
    addRole: undefined,
    displayError: undefined,
    clearError: undefined,
    authProvider: undefined
});

export function useAppContext(): AppContext {
    return useContext(appContext);
}


interface ProvideAppContextProps {
    children: React.ReactNode;
}

export default function ProvideAppContext({ children }: ProvideAppContextProps) {
    const auth = useProvideAppContext();
    return (
        <appContext.Provider value={auth}>
            {children}
        </appContext.Provider>
    );
}

function useProvideAppContext() {
    const msal = useMsal();
    const [user, setUser] = useState<AppUser | undefined>(undefined);
    const [error, setError] = useState<AppError | undefined>(undefined);

    useEffect(() => {
        const checkUser = async () => {
            if (!user) {
                try {
                    // Check if user is already signed in
                    const account = msal.instance.getActiveAccount();
                    const user: string | null = localStorage.getItem('user')
                    if (account && user) {
                        setUser(JSON.parse(user))
                        // Get the user from Microsoft Graph
                    }
                } catch (err: any) {
                    displayError(err.message);
                }
            }
        };
        checkUser();
    });;

    const displayError = (message: string, debug?: string) => {
        setError({ message, debug });
    }

    const clearError = () => {
        setError(undefined);
    }

    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance as PublicClientApplication,
        {
            account: msal.instance.getActiveAccount()!,
            scopes: [
                'user.read',
                'mailboxsettings.read',
                'calendars.readwrite'
            ],
            interactionType: InteractionType.Popup
        }
    );

    const signIn = async () => {
        let loginRequest = {
            scopes: ["user.read", "api://" + process.env["REACT_APP_APP_ID"] + "/access_as_user"], // optional Array<string>
            redirectUri: window.location.protocol + '//' + window.location.host + '/blank.html'
        };
        await msal.instance.acquireTokenSilent(loginRequest)
            .then(async result => {
                await setSession(result)
                document.location = '/landing'
            })

            .catch(async (err) => {
                const result = await msal.instance.loginPopup({
                    scopes: [
                        'user.read', "api://" + process.env["REACT_APP_APP_ID"] + "/access_as_user"
                    ],
                    prompt: 'select_account'
                });
                await setSession(result)
                document.location = '/landing'
            });
    };



    const signOut = async () => {
        localStorage.removeItem("selectedOrganizationId");
        localStorage.removeItem("selectedRole");
        await msal.instance.logoutPopup();
        setUser(undefined);

    };

    const signOutSilent = async () => {
        localStorage.removeItem("selectedOrganizationId");
        localStorage.removeItem("selectedRole");
        const currentAccount = msal.instance.getAccountByHomeId(msal.accounts[0].homeAccountId)
        await msal.instance.logoutRedirect({ account: currentAccount })
        setUser(undefined);

    };

    const setSession = async (idToken: any) => {
        let roles = [];
        if (idToken.idTokenClaims.roles) {
            roles = idToken.idTokenClaims.roles;
        }

        const user: AppUser = {
            uniqueId: idToken.uniqueId,
            email: idToken.account.username,
            idToken: idToken,
            displayName: idToken.idTokenClaims.name,
            roleSelections: [],
            roles: roles,
            isAuthenticated: true,
            homeAccountId:idToken.account.homeAccountId
        }
        await setDefaultRole(user)
        localStorage.setItem('user', JSON.stringify(user));

    }

    const setDefaultRole = async (user: AppUser) => {

        let selectedRole: string = 'None';
        let selectedOrganizationId: number | undefined = 0;
        if (user.roles?.includes("Volunteer.All")) {

            const v = await VolunteerService.getVolunteerByPrincipalId(user.uniqueId)
            if (v?.localOfficeVolunteers && v?.localOfficeVolunteers.length > 0) {
                selectedOrganizationId = v.localOfficeVolunteers.find(lov => lov.isActive)?.localOfficeId
                v.localOfficeVolunteers.filter(lov => lov.isActive).forEach(lo => {

                    user.roleSelections.push({ organizationId: lo.localOfficeId, role: "Volunteer.All", roleName: "Volunteer: " + lo.localOffice?.name, sort: 1 })
                })
                selectedRole = "Volunteer.All"
            }
        }

        if (user.roles?.includes("OfficeUser.All")) {
            const lou = await LocalOfficeUserService.getByPrincipalId(user.uniqueId)
            if (lou?.localOfficeLocalOfficeUsers && lou?.localOfficeLocalOfficeUsers.length > 0) {
                selectedOrganizationId = lou.localOfficeLocalOfficeUsers.find(lou => lou.isActive)?.localOfficeId
                lou.localOfficeLocalOfficeUsers.filter(lou => lou.isActive).forEach(lo => {
                    user.roleSelections.push({ organizationId: lo.localOfficeId, role: "OfficeUser.All", roleName: "Local Office: " + lo.localOffice?.name, sort: 2 })
                })
                selectedRole = "OfficeUser.All"
            }
        }

        if (user.roles?.includes("NationalOfficeUser.All")) {
            const nru = await NationalResettlementUserService.getNationalResettlementUser(user.uniqueId)
            if (nru?.nationalResettlementAgencyNationalResettlementUsers && nru?.nationalResettlementAgencyNationalResettlementUsers.length > 0) {
                selectedOrganizationId = nru.nationalResettlementAgencyNationalResettlementUsers.find(nru => nru.isActive)?.nationalResettlementAgencyId
                nru.nationalResettlementAgencyNationalResettlementUsers.filter(nru => nru.isActive).forEach(nra => {
                    user.roleSelections.push({ organizationId: nra.nationalResettlementAgencyId, role: "NationalOfficeUser.All", roleName: "Resettlement Agency: " + nra.nationalResettlementAgency?.name, sort: 3 })

                })
                selectedRole = "NationalOfficeUser.All"
            }
        }

        if (user.roles?.includes("GlobalAdmin.All")) {
            selectedRole = "GlobalAdmin.All"
            selectedOrganizationId = 0
            user.roleSelections.push({ organizationId: 0, role: "GlobalAdmin.All", roleName: "Global Admin", sort: 4 })
        }

        user.roleSelections.sort((a: any, b: any) => {
            if (a.sort < b.sort) {
                return 1
            } else if (a.sort > b.sort) {
                return -1
            }
            else return 0
        })
        if (selectedRole) {
            localStorage.setItem("selectedRole", selectedRole)
        }
        if (typeof selectedOrganizationId !== 'undefined' && selectedOrganizationId >= 0) {
            localStorage.setItem("selectedOrganizationId", selectedOrganizationId.toString())
        }

    }

    const addRole = (user: AppUser, role: string, organizationId: number, name: string) => {
        if (user.roleSelections.some((rs: any) => rs.organizationId === organizationId && rs.role === role)) {
            return
        }

        if (role === "Volunteer.All") {
            user.roleSelections.push({ organizationId: organizationId, role: "Volunteer.All", roleName: "Volunteer: " + name, sort: 1 })
        }

        if (role === "OfficeUser.All") {
            user.roleSelections.push({ organizationId: organizationId, role: "OfficeUser.All", roleName: "Local Office: " + name, sort: 2 })
        }

        if (role === "NationalOfficeUser.All") {
            user.roleSelections.push({ organizationId: organizationId, role: "NationalOfficeUser.All", roleName: "Resettlement Agency " + name, sort: 3 })
        }

        if (!user.roles?.includes(role)) {
            user.roles?.push(role)
        }
        user.roleSelections.sort((rs: any) => rs.sort)
        setUser((prevState) => ({ ...prevState, ...user }))
        localStorage.setItem("user", JSON.stringify(user))

    }


    return {
        user,
        error,
        signIn,
        signOut,
        signOutSilent,
        displayError,
        clearError,
        addRole,
        authProvider
    };
}

