import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../AppContext';
import { CaseActivityService } from '../../../services/CaseActivityService';
import { VolunteerService } from '../../../services/VolunteerService';
import { CaseActivityClient, CaseDTO, CreateCaseActivityDTO, VolunteerDTO } from '../../../volunteer-tracking-client';
import { CaseActivityValidator } from './CaseActivityValidator';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { CaseActivitySection4 } from './CaseActivitySection4';
import { CreateCaseActivityReview } from './CreateCaseActivityReview';
import { CaseActivitySection3 } from './CaseActivitySection3';
import { CaseActivitySection2 } from './CaseActivitySection2';
import { CaseActivitySection1 } from './CaseActivitySection1';
import { useNavigate, useParams } from "react-router-dom";
import { CaseService } from '../../../services/CaseService';
import { Exception } from '../../Exception';

const caseActivityClient: CaseActivityClient = new CaseActivityClient();

export const CaseActivity = () => {
    const navigate = useNavigate();
    const { caseId, caseActivityId } = useParams();

    const [volunteer, setVolunteer] = useState<VolunteerDTO>()
    const [localOfficeId, setLocalOfficeId] = useState<number>()
    const [caseDTO, setCaseDTO] = useState<CaseDTO>()
    const [caseActivity, setCaseActivity] = useState<CreateCaseActivityDTO>({
        caseId: caseId ? parseInt(caseId) : 0,
        caseActivityTasks: [],
        createdDate: new Date()
    })
    const [page, setPage] = useState<number>(1)
    const [validations, setValidations] = useState<{ [page: number]: number }>({})
    const [error, setError] = useState<Error>();
    const app = useAppContext()

    useEffect(() => {
        const getVolunteer = async () => {
            const response: VolunteerDTO = await VolunteerService.getVolunteerByPrincipalId(app.user?.uniqueId)
            setVolunteer(response)
            setLocalOfficeId(parseInt(localStorage.getItem("selectedOrganizationId")!))
            if (!caseActivityId) {
                setCaseActivity({ ...caseActivity, ...{ volunteerId: response.id } })
            }
        }

        const getLocalOfficeUser = async () => {
            setLocalOfficeId(parseInt(localStorage.getItem("selectedOrganizationId")!));
        }

        const getCaseActivity = async () => {
            if (caseActivityId) {
                const response: CreateCaseActivityDTO = await caseActivityClient.getCaseActivityForEdit(Number.parseInt(caseActivityId));
                setCaseActivity(response);
                getCase(response.caseId!)
            }
        }

        const getCase = async (caseId: number) => {
            setCaseDTO(await CaseService.getCase(caseId));
        }

        try{
        if (localStorage.getItem("selectedRole") === "Volunteer.All") {
            getVolunteer();
        } else if (localStorage.getItem("selectedRole") === "OfficeUser.All") {
            getLocalOfficeUser();
        }

        if (caseActivityId) {
            getCaseActivity();
        } else {
            getCase(parseInt(caseId!))
        }
    }catch(ex:any){
        setError(ex);
    }

    }, []);



    const handleChanges = (fn: () => any): void => {
        setCaseActivity(prevState => ({ ...prevState, ...fn() }));
    }

    const handleNext = (event: any): void => {
        if (page === 4) {
            setValidations(new CaseActivityValidator().validate(caseActivity))
        }
        if (page < 5) {
            setPage(page + 1)
        }
    }

    const handlePrevious = (event: any): void => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const handleSubmit = async (event: any) => {
        if (caseActivity.id) {
            await CaseActivityService.updateCaseActivity(caseActivity);
            if (app.user?.roles?.includes("OfficeUser.All")) {
                navigate('/localoffice-case-activities/' + caseActivity.caseId);
            } else {
                navigate("/volunteer-case-activities/" + caseActivity.caseId);
            }
        } else {
            await CaseActivityService.createCaseActivity(caseActivity);
            navigate("/volunteer-home");
        }
    }

    return (
        !error?
        <Container>
            <Card>
                <Card.Header>
                    <Row>
                        <Col md={10}>Community Sponsorship Activity Entry</Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col className='header-breadcrumb breadcrumb-border-right'>
                                <Row>
                                    <Col md={3}><div className={'breadcrumb-circle ' + (page === 1 ? 'active active-breadcrumb-circle' : '')} >1
                                        {validations[1] && <div className="validation-circle">{validations[1]}</div>}</div></Col>
                                    <Col className={'d-none d-lg-block ' + (page === 1 ? 'active' : '')}>
                                        <div className='breadcrumb-text'>Client Information</div></Col>
                                </Row>
                            </Col>
                            <Col className='header-breadcrumb breadcrumb-border-right'>
                                <Row>
                                    <Col md={3}><div className={'breadcrumb-circle ' + (page === 2 ? 'active active-breadcrumb-circle' : '')}>2
                                        {validations[2] && <div className="validation-circle">{validations[2]}</div>}</div></Col>
                                    <Col className={'d-none d-lg-block ' + (page === 2 ? 'active' : '')}>
                                        <div className='breadcrumb-text'>Service Activity</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='header-breadcrumb breadcrumb-border-right'>
                                <Row>
                                    <Col md={3}><div className={'breadcrumb-circle ' + (page === 3 ? 'active active-breadcrumb-circle' : '')}>3
                                        {validations[3] && <div className="validation-circle">{validations[3]}</div>}</div></Col>
                                    <Col className={'d-none d-lg-block ' + (page === 3 ? 'active' : '')}>
                                        <div className='breadcrumb-text'>Case Notes</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='header-breadcrumb breadcrumb-border-right'>
                                <Row>
                                    <Col md={3}><div className={'breadcrumb-circle ' + (page === 4 ? 'active active-breadcrumb-circle' : '')}>4
                                        {validations[4] && <div className="validation-circle">{validations[4]}</div>}</div></Col>
                                    <Col className={'d-none d-lg-block ' + (page === 4 ? 'active' : '')}>
                                        <div className='breadcrumb-text'>Donations</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='header-breadcrumb'>
                                <Row>
                                    <Col md={3}><div className={'breadcrumb-circle ' + (page === 5 ? 'active active-breadcrumb-circle' : '')}>5</div></Col>
                                    <Col className={'d-none d-lg-block ' + (page === 5 ? 'active' : '')}>
                                        <div className='breadcrumb-text'>Review</div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Title>

                    {(page === 1) &&
                        <CaseActivitySection1
                            onChangeHandler={handleChanges}
                            caseActivity={caseActivity}
                            volunteer={volunteer}
                            review={caseActivity.id ? true : false} />
                    }
                    {(page === 2) &&
                        <CaseActivitySection2
                            onChangeHandler={handleChanges}
                            caseActivity={caseActivity}
                            localOfficeId={localOfficeId!}
                            clientDOA={new Date(caseDTO!.clientDOA!)}
                        />
                    }
                    {(page === 3) &&
                        <CaseActivitySection3
                            onChangeHandler={handleChanges}
                            caseActivity={caseActivity}></CaseActivitySection3>

                    }
                    {(page === 4) &&
                        <CaseActivitySection4
                            onChangeHandler={handleChanges}
                            caseActivity={caseActivity}
                            volunteer={volunteer}></CaseActivitySection4>

                    }
                    {(page === 5) &&
                        <CreateCaseActivityReview
                            caseActivity={caseActivity}
                            volunteer={volunteer!} />

                    }
                    <Row className='text-center mt-5'>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Row>
                                {(page > 1) &&
                                    <Col>
                                        <Button onClick={handlePrevious}>Previous</Button>
                                    </Col>
                                }
                                {(page < 5) &&
                                    <Col>
                                        <Button onClick={handleNext}>Next</Button>
                                    </Col>
                                }
                                {(page === 5 && Object.keys(validations).length === 0) &&
                                    <Col>
                                        <Button onClick={handleSubmit}>Submit</Button>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>:<Exception message={error.message}/>
    )
}
