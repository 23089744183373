import React, { useEffect, useState } from 'react';
import './CaseReview.css';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { CaseSponsorshipGroupDTO, CountryDTO, CreateCaseDTO, FaithCommunityTypeDTO, LanguageDTO, LocalOfficeDTO, NationalResettlementAgencyDTO, SponsorshipGroupCommunityTypeDTO, SponsorshipGroupDTO, SponsorshipGroupTypeDTO, StateDTO } from '../../volunteer-tracking-client';

export const CaseReview = (props: {
    hasAnyValidationErrors: () => Map<string, string[]>,
    createCaseDTO: CreateCaseDTO,
    countryList: CountryDTO[],
    stateList: StateDTO[],
    languageList: LanguageDTO[],
    sponsorshipGroupTypes: SponsorshipGroupTypeDTO[],
    natRAList: NationalResettlementAgencyDTO[],
    localOfficeList: LocalOfficeDTO[],
    localOffice: LocalOfficeDTO | undefined,
    sponsorshipCommunityTypes: SponsorshipGroupCommunityTypeDTO[]
    faithCommunityTypes: FaithCommunityTypeDTO[],
    notFound: boolean
}) => {
    const defaultValidationErrors = new Map<string, string[]>([['CaseDetails', []], ['FamilyMember', []], ['CaseSponsorshipGroup', []], ['SponsorshipGroup', []]])
    const [createCaseDTO, setCreateCaseDTO] = useState<CreateCaseDTO>(props.createCaseDTO);
    const [validationErrors, setValidationErrors] = useState<Map<string, string[]>>(defaultValidationErrors);

    useEffect(() => {
        const validationErrors = props.hasAnyValidationErrors();
        setValidationErrors(validationErrors);
    }, []);

    let caseSponsorshipGroup: CaseSponsorshipGroupDTO | undefined = undefined;
    let sponsorshipGroup: SponsorshipGroupDTO | undefined = undefined;
    if (createCaseDTO.caseSponsorshipGroups && createCaseDTO.caseSponsorshipGroups.length > 0) {
        caseSponsorshipGroup = createCaseDTO.caseSponsorshipGroups[0];
        if (caseSponsorshipGroup.sponsorshipGroup) {
            sponsorshipGroup = caseSponsorshipGroup.sponsorshipGroup;
        }
    }

    const faithCommunityTypeID = props.sponsorshipCommunityTypes.find(sct => sct.type === 'Faith Community')?.id;
    const faithCommunityOtherID = props.faithCommunityTypes.find(fc => fc.faithType === 'Other')?.id;

    return (
        <Container>
            <Row className='mb-2'>
                <h2>Client Information</h2>
            </Row>
            <Row className='mb-3 p-3'>
                <Col>
                    <Stack>
                        <Stack direction='horizontal' gap={3}>
                            <p className={validationErrors.get('CaseDetails')?.includes('number') ? 'validation-error' : 'fw-bold'}>Number:</p>
                            <p>{createCaseDTO.number}</p>
                        </Stack>
                        <Stack direction='horizontal' gap={3}>
                            <p className={validationErrors.get('CaseDetails')?.includes('size') ? 'validation-error' : 'fw-bold'}>Size: </p>
                            <p>{createCaseDTO.size}</p>
                        </Stack>
                        <Stack direction='horizontal' gap={3}>
                            <p className={validationErrors.get('CaseDetails')?.includes('clientNativeLanguageID') ? 'validation-error' : 'fw-bold'}>Client Date of Arrival at final Destination: </p><p>{createCaseDTO.clientDOA}</p></Stack>
                        <Stack direction='horizontal' gap={3}>
                            <p className={validationErrors.get('CaseDetails')?.includes('countryOfOriginID') ? 'validation-error' : 'fw-bold'}>Country of Origin: </p>
                            {/* eslint-disable-next-line */}
                            <p>{props.countryList.find(x => x.id == createCaseDTO.countryOfOriginID)?.name}</p>
                        </Stack>
                        <Stack direction='horizontal' gap={3}>
                            <p className={validationErrors.get('CaseDetails')?.includes('clientNativeLanguageID') ? 'validation-error' : 'fw-bold'}>Native Language: </p>
                            {/* eslint-disable-next-line */}
                            <p>{props.languageList.find(x => x.id == createCaseDTO.clientNativeLanguageID)?.name}</p>
                        </Stack>
                        <Stack direction='horizontal' gap={3}>
                            <p className={validationErrors.get('CaseDetails')?.includes('grantTagOther') || validationErrors.get('CaseDetails')?.includes('grantTag') ? 'validation-error' : 'fw-bold'}>Grant Tag: </p>
                            {/* eslint-disable-next-line */}
                            <p>{createCaseDTO.grantTag == 'Other' ? createCaseDTO.grantTagOther : createCaseDTO.grantTag}</p>
                        </Stack>
                    </Stack>
                </Col>
                <Col>
                    <Stack>
                        <Stack direction='horizontal' gap={3}>
                            <p className={validationErrors.get('CaseDetails')?.includes('localOfficeID') ? 'validation-error' : 'fw-bold'}>Local Office: </p>
                            {/* eslint-disable-next-line */}
                            <p>{props.localOfficeList.find(x => x.id == createCaseDTO.localOfficeID)?.name}</p>
                        </Stack>
                        <Stack direction='horizontal' gap={3}>
                            <p className={validationErrors.get('CaseDetails')?.includes('nationalResettlementAgencyID') ? 'validation-error' : 'fw-bold'}>National Resettlement Agency: </p>
                            {/* eslint-disable-next-line */}
                            <p>{props.natRAList.find(x => x.id == props.localOffice?.nationalResettlementAgency?.id)?.name}</p>
                        </Stack>
                        <Stack direction='horizontal' gap={3}><p className='fw-bold'>Client has US Tie: </p><p>{createCaseDTO.clientHasUSTie?.toString()}</p></Stack>
                        <Stack direction='horizontal' gap={3}><p className='fw-bold'>US Tie Name: </p><p>{createCaseDTO.clientUSTieName}</p></Stack>
                        <Stack direction='horizontal' gap={3}><p className='fw-bold'>US Tie Phone Number:</p><p> {createCaseDTO.clientUSTiePhoneNumber}</p></Stack>
                        {/* eslint-disable-next-line */}
                        <Stack direction='horizontal' gap={3}><p className='fw-bold'>US Tie Primary Language: </p><p>{props.languageList.find(x => x.id == createCaseDTO.clientUSTiePrimaryLanguageID)?.name}</p></Stack>
                    </Stack>
                </Col>
            </Row>
            <Row className='mb-2'>
                <h2>Client Address</h2>
            </Row>
            {createCaseDTO.familyMembers?.map((familyMember, index) => {
                const pageName = index === 0 ? 'FamilyMember' : 'FamilyMember' + index.toString();
                return (
                    <Row key={index} className='mb-3 p-3'>
                        <Col>
                            <Stack>
                                <Stack direction='horizontal' gap={3}><p className='fw-bold'>Primary:</p><p>{familyMember.isPrimary?.toString()}</p></Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get(pageName)?.includes('firstName') ? 'validation-error' : 'fw-bold'}>First Name:</p>
                                    <p>{familyMember.firstName}</p>
                                </Stack>
                                <Stack direction='horizontal' gap={3}><p className='fw-bold'>Middle Name:</p><p>{familyMember.middleName}</p></Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get(pageName)?.includes('lastName') ? 'validation-error' : 'fw-bold'}>Last Name:</p>
                                    <p>{familyMember.lastName}</p>
                                </Stack>
                                <Stack direction='horizontal' gap={3}><p className={validationErrors.get(pageName)?.includes('contactNumber') ? 'validation-error' : 'fw-bold'}>Contact Number:</p><p>{familyMember.contactNumber}</p></Stack>
                            </Stack>
                        </Col>
                        <Col>
                            <Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get(pageName)?.includes('addressLine1') ? 'validation-error' : 'fw-bold'}>Address:</p>
                                    <p>{familyMember.addressLine1}</p>
                                </Stack>
                                <Stack direction='horizontal' gap={3}><p className='fw-bold'>Address Line 2:</p><p>{familyMember.addressLine1}</p></Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get(pageName)?.includes('city') ? 'validation-error' : 'fw-bold'}>City:</p>
                                    <p>{familyMember.city}</p>
                                </Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get(pageName)?.includes('stateID') ? 'validation-error' : 'fw-bold'}>State/Territory:</p>
                                    {/* eslint-disable-next-line */}
                                    <p>{props.stateList.find(x => x.id == familyMember.stateID)?.name}</p>
                                </Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get(pageName)?.includes('zipCode') ? 'validation-error' : 'fw-bold'}>Zip:</p>
                                    <p>{familyMember.zipCode}</p>
                                </Stack>
                            </Stack>
                        </Col>
                    </Row>
                );
            })}
            {caseSponsorshipGroup &&
                <>
                    <Row className='mb-2'>
                        <h2>Volunteer Information</h2>
                    </Row>
                    <Row className='mb-3 p-3'>
                        <Col>
                            <Stack>
                                <Stack direction='horizontal' gap={3}><p className='fw-bold'>Paired with Community Sponsorship Group:</p><p>{caseSponsorshipGroup.pairedWithCommunitySponsorship?.toString()}</p></Stack>
                                <Stack direction='horizontal' gap={3}><p className='fw-bold'>SponsorshipGroup Type:</p><p>{props.sponsorshipGroupTypes.find(x => x.id === caseSponsorshipGroup?.typeID)?.type}</p></Stack>
                                {caseSponsorshipGroup?.pairedWithCommunitySponsorship &&
                                    <Stack direction='horizontal' gap={3}>
                                        <p className={validationErrors.get('CaseSponsorshipGroup')?.includes('signedMoU') ? 'validation-error' : 'fw-bold'}>Signed MOU: </p>
                                        <p>{caseSponsorshipGroup?.signedMoU?.toString()}</p>
                                    </Stack>
                                }
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get('CaseSponsorshipGroup')?.includes('coreServices') ? 'validation-error' : 'fw-bold'}>Core Service Deliverables: </p>
                                    <p>{caseSponsorshipGroup?.coreServices}</p>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col>
                            <Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get('CaseSponsorshipGroup')?.includes('requiredCashContribution') ? 'validation-error' : 'fw-bold'}>Required Cash Contribution: </p>
                                    <p>{caseSponsorshipGroup?.requiredCashContribution}</p>
                                </Stack>
                                <Stack direction='horizontal' gap={3}><p className='fw-bold'>Required In Kind Contributions: </p><p>{caseSponsorshipGroup?.requiredInKindContributions}</p></Stack>
                                <Stack direction='horizontal' gap={3}><p className='fw-bold'>All Core Members Trained: </p><p>{caseSponsorshipGroup?.allCoreMembersTrained?.toString()}</p></Stack>
                                <Stack direction='horizontal' gap={3}><p className='fw-bold'>All Core Members Provided Background Checks:</p><p>{caseSponsorshipGroup?.allCoreMembersProvidedBackgroundChecks?.toString()}</p></Stack>
                            </Stack>
                        </Col>
                    </Row>
                </>
            }
            {caseSponsorshipGroup && (props.createCaseDTO.caseVolunteers || !caseSponsorshipGroup?.pairedWithCommunitySponsorship) &&
                <>
                    <Row className='mb-2'>
                        <h2>Case Volunteer Details</h2>
                    </Row>
                    {props.createCaseDTO.caseVolunteers?.map((caseVolunteer, index) => {
                        return (
                            <Row key={index} className='mb-3 p-3'>
                                <Col>
                                    <Stack>
                                        <Stack>
                                            <Stack direction='horizontal' gap={3}>
                                                <p className={validationErrors.get('CaseVolunteer')?.includes('email') ?
                                                    props.notFound ? 'validation-error no-marginbot' : 'validation-error'
                                                    : props.notFound ? 'fw-bold no-marginbot' : 'fw-bold'}>Email:</p>
                                                <p className={props.notFound ? 'no-marginbot' : ''}>{caseVolunteer?.volunteer?.email}</p></Stack>
                                            {props.notFound &&
                                                <p className='not-found-error'>Unable to find internal volunteer. Check to make sure the volunteers email address is correct.</p>
                                            }
                                        </Stack>
                                        <Stack direction='horizontal' gap={3}>
                                            <p className={validationErrors.get('CaseVolunteer')?.includes('firstName') ? 'validation-error' : 'fw-bold'}>First Name:</p>
                                            <p>{caseVolunteer?.volunteer?.firstName}</p></Stack>
                                        <Stack direction='horizontal' gap={3}>
                                            <p className={validationErrors.get('CaseVolunteer')?.includes('lastName') ? 'validation-error' : 'fw-bold'}>Last Name:</p>
                                            <p>{caseVolunteer?.volunteer?.lastName}</p></Stack>
                                    </Stack>
                                </Col>
                                <Col>
                                    <Stack>
                                        <Stack direction='horizontal' gap={3}><p className='fw-bold'>Cell Phone:</p><p>{caseVolunteer?.volunteer?.cellPhone}</p></Stack>
                                        <Stack direction='horizontal' gap={3}><p className='fw-bold'>Date of Match:</p><p>{caseVolunteer?.dateOfMatch}</p></Stack>
                                        <Stack direction='horizontal' gap={3}><p className='fw-bold'>Time Commitment (Months):</p><p>{caseVolunteer?.timeCommitment}</p></Stack>
                                    </Stack>
                                </Col>
                            </Row>
                        )
                    })}
                </>
            }
            {sponsorshipGroup && caseSponsorshipGroup && caseSponsorshipGroup?.typeID !== 3 &&
                <>
                    <Row className='mb-2'>
                        <h2>Sponsorship Group Details</h2>
                    </Row>
                    <Row className='mb-3 p-3'>
                        <Col>
                            <Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get('SponsorshipGroup')?.includes('name') ? 'validation-error' : 'fw-bold'}>Group Name:</p>
                                    <p>{sponsorshipGroup.name}</p></Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get('CaseSponsorshipGroup')?.includes('dateOfMatch') ? 'validation-error' : 'fw-bold'}>Date of Match:</p>
                                    <p>{caseSponsorshipGroup?.dateOfMatch?.toString()}</p></Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get('SponsorshipGroup')?.includes('sponsorshipGroupCommunityTypeID') ? 'validation-error' : 'fw-bold'}>Sponsorship Community Type:</p>
                                    <p>{props.sponsorshipCommunityTypes.find(sct => sct.id === sponsorshipGroup?.sponsorshipGroupCommunityTypeID)?.type}</p></Stack>
                                {sponsorshipGroup.sponsorshipGroupCommunityTypeID === faithCommunityTypeID &&
                                    <>
                                        <Stack direction='horizontal' gap={3}>
                                            <p className={validationErrors.get('SponsorshipGroup')?.includes('faithCommunityTypeID') ? 'validation-error' : 'fw-bold'}>Faith Community Type:</p>
                                            <p>{props.faithCommunityTypes.find(fc => fc.id === sponsorshipGroup?.faithCommunityTypeID)?.faithType}</p></Stack>
                                        {sponsorshipGroup.faithCommunityTypeID === faithCommunityOtherID &&
                                            <Stack direction='horizontal' gap={3}><p className='fw-bold'>Faith Community Other:</p><p>{sponsorshipGroup.faithCommunityOther}</p></Stack>
                                        }
                                    </>
                                }
                            </Stack>
                        </Col>
                        <Col>
                            <Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get('SponsorshipGroup')?.includes('totalGroupMembers') ? 'validation-error' : 'fw-bold'}>Total Group Members:</p>
                                    <p>{sponsorshipGroup?.totalGroupMembers}</p></Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get('CaseSponsorshipGroup')?.includes('commitmentDurationMonths') ? 'validation-error' : 'fw-bold'}>Duration of Commitment:</p>
                                    <p>{caseSponsorshipGroup.commitmentDurationMonths}</p></Stack>
                                <Stack direction='horizontal' gap={3}>
                                    <p className={validationErrors.get('CaseSponsorshipGroup')?.includes('commitmentDurationMonthsMax') ? 'validation-error' : 'fw-bold'}>Duration of Commitment Max:</p>
                                    <p>{caseSponsorshipGroup.commitmentDurationMonthsMax}</p></Stack>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <h2>Sponsorship Group Volunteer Details</h2>
                    </Row>
                    {sponsorshipGroup.sponsorshipGroupVolunteers?.map((sgv, index) => {
                        const pageName = 'SponsorshipGroupVolunteer' + index.toString();
                        return (
                            <Row key={index} className='mb-3 p-3'>
                                <Col>
                                    <Stack direction='horizontal' gap={3}>
                                        <Stack direction='horizontal' gap={3}><p className='fw-bold'>Is Group Leader:</p>
                                            <p>{sgv.isGroupLeader ? sgv.isGroupLeader.toString() : 'false'}</p></Stack>
                                        <Stack direction='horizontal' gap={3}>
                                            <p className={validationErrors.get(pageName)?.includes('email') ? 'validation-error' : 'fw-bold'}>Email:</p>
                                            <p>{sgv.volunteer?.email}</p></Stack>
                                        <Stack direction='horizontal' gap={3}>
                                            <p className={validationErrors.get(pageName)?.includes('firstName') ? 'validation-error' : 'fw-bold'}>First Name:</p>
                                            <p>{sgv.volunteer?.firstName}</p></Stack>
                                        <Stack direction='horizontal' gap={3}>
                                            <p className={validationErrors.get(pageName)?.includes('lastName') ? 'validation-error' : 'fw-bold'}>Last Name:</p>
                                            <p>{sgv.volunteer?.lastName}</p></Stack>
                                    </Stack>
                                </Col>
                            </Row>
                        );
                    })
                    }
                </>
            }
        </Container>
    );
}
CaseReview.displayName = CaseReview.name;