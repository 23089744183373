import { faCaretSquareDown, faCaretSquareUp } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Card, Col, Collapse, Form, FormLabel, Row, Stack } from "react-bootstrap"
import { InputService } from "../../../services/InputService"
import { CaseActivityElaborationProperties } from "./CaseActivityElaborationProperties"
import { InKindDonationComponent } from "./InKindDonationComponent"
import { TransportationComponent } from "./TransportationComponent"

export const CaseActivityElaborationForm = (props: CaseActivityElaborationProperties) => {
    const { onChangeHandler, label, selectedActivity, caseNumber, review } = props
    const [useInterpreter, setUseInterpreter] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(props.sectionOpened);

    useEffect(() => {
        setUseInterpreter(props.selectedActivity?.interpreterUsed ?? useInterpreter);
    }, [props.selectedActivity?.interpreterUsed]);

    const handleChange = (event: any) => {
        const fieldName: string = event.target.name as string
        const value = event.target.value
        onChangeHandler({ ...selectedActivity, ...{ [fieldName]: value } })
    }

    const handleInterpretationClick = (event: any) => {
        setUseInterpreter(!useInterpreter)
        onChangeHandler({
            ...selectedActivity, ...{
                [event.target.name]: event.target.value == 'true' ? true : false,
                ['interpreterType']: undefined
            }
        })
    }

    const hoursInvalid = () => {
        if (selectedActivity?.hoursSpent == undefined) {
            return true;
        }
        if (selectedActivity?.hoursSpent == 0 && selectedActivity.minutesSpent == 0) {
            return true;
        }
        return false;
    }

    const minutesInvalid = () => {
        if (selectedActivity?.minutesSpent == undefined) {
            return true;
        } else {
            if (selectedActivity?.minutesSpent == 0 && selectedActivity.hoursSpent == 0) {
                return true;
            }
            if (selectedActivity?.minutesSpent > 59) {
                return true;
            }
            return false;
        }
    }

    return (
        <>
            <Row className={props.review ? "mt-3 ms-1 me-3" : "mt-3"} onClick={() => setOpen(!open)}
                aria-expanded={open}
                style={{
                    cursor: "pointer",
                    backgroundColor: "lightgray",
                    paddingTop: "5px",
                    borderRadius: "5px"
                }}>
                <Col xs={11}>
                    <h3>{label}</h3>
                </Col>
                {open ?
                    <Col xs={1}>
                        <FontAwesomeIcon
                            icon={faCaretSquareUp} style={{ color: "#1b6ec2" }} />
                    </Col> :
                    <Col xs={1}>
                        <FontAwesomeIcon
                            icon={faCaretSquareDown} style={{ color: "#1b6ec2" }} />
                    </Col>
                }
            </Row>
            <Collapse in={open}>
                <form>
                    <div className={props.review ? 'ms-1 me-3' : ''}>
                        <Row>
                            <Col>
                                <Form.Group className='mb-3' >
                                    <Form.Label>Case Notes Documentation</Form.Label>
                                    <textarea rows={4} className='form-control' style={{ "minWidth": "100%" }}
                                        required
                                        value={selectedActivity?.caseNoteDocumentation ?? ''}
                                        name='caseNoteDocumentation'
                                        onChange={handleChange}
                                        readOnly={props.review}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        Required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <h3>Was interpretation used?</h3>
                                    <Stack direction="horizontal">
                                        <Form.Check
                                            required
                                            checked={
                                                selectedActivity?.interpreterUsed ? selectedActivity?.interpreterUsed.toString() == 'false' : true}
                                            inline
                                            label="No"
                                            name="interpreterUsed"
                                            type="radio"
                                            value="false"
                                            disabled={props.review}
                                            onChange={handleInterpretationClick} />
                                        <Form.Check
                                            checked={selectedActivity?.interpreterUsed?.toString() == 'true'}
                                            inline
                                            label="Yes"
                                            name="interpreterUsed"
                                            type="radio"
                                            value="true"
                                            disabled={props.review}
                                            onChange={handleInterpretationClick} />
                                    </Stack>
                                </Form.Group>

                            </Col>
                            <Col md={8}>
                                {useInterpreter &&
                                    <Form.Group className="mb-3">
                                        <FormLabel>How was this service provided</FormLabel>
                                        <Stack direction="horizontal">
                                            <Form.Check
                                                required
                                                isInvalid={selectedActivity?.interpreterType === undefined}
                                                checked={selectedActivity?.interpreterType == "In-Person"}
                                                inline
                                                label="In-Person"
                                                name="interpreterType"
                                                type="radio"
                                                value="In-Person"
                                                disabled={props.review}
                                                onChange={handleChange} />
                                            <Form.Check
                                                checked={selectedActivity?.interpreterType == "Virtual"}
                                                isInvalid={selectedActivity?.interpreterType === undefined}
                                                inline
                                                label="Virtual"
                                                name="interpreterType"
                                                type="radio"
                                                value="Virtual"
                                                disabled={props.review}
                                                onChange={handleChange} />
                                            <Form.Check
                                                checked={selectedActivity?.interpreterType == "Phone"}
                                                isInvalid={selectedActivity?.interpreterType === undefined}
                                                inline
                                                label="Phone"
                                                name="interpreterType"
                                                value="Phone"
                                                type="radio"
                                                disabled={props.review}
                                                onChange={handleChange} />
                                        </Stack>
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Time spent on activity/activities</Form.Label>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Control isInvalid={hoursInvalid()}
                                                name='hoursSpent'
                                                type="number"
                                                value={selectedActivity?.hoursSpent}
                                                placeholder='Hours'
                                                min={0}
                                                onKeyDown={InputService.HandleNumberInput}
                                                onChange={handleChange}
                                                readOnly={props.review} />
                                            <Form.Control.Feedback type='invalid'>
                                                Required
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Control isInvalid={minutesInvalid()}
                                                name='minutesSpent'
                                                type='number'
                                                value={selectedActivity?.minutesSpent}
                                                placeholder='Minutes'
                                                min={0}
                                                max={59}
                                                onKeyDown={InputService.HandleNumberInput}
                                                onChange={handleChange}
                                                readOnly={props.review} />
                                            <Form.Control.Feedback type='invalid'>
                                                Required
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className='mb-3' >
                                    <Form.Label>Date Activity Was Completed</Form.Label>
                                    <Form.Control onChange={handleChange}
                                        isInvalid={!selectedActivity?.activityDate || new Date(selectedActivity.activityDate).getFullYear() > 9999}
                                        required
                                        type='date'
                                        placeholder='MM/DD/yyyy'
                                        name='activityDate'
                                        value={selectedActivity?.activityDate?.toLocaleString().split('T')[0] || ''}
                                        readOnly={props.review} />
                                    <Form.Control.Feedback type='invalid'>
                                        Invalid Date
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InKindDonationComponent
                                    selectedActivity={selectedActivity!}
                                    onChangeHandler={onChangeHandler}
                                    caseNumber={caseNumber}
                                    review={props.review}></InKindDonationComponent>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TransportationComponent
                                    selectedActivity={selectedActivity!}
                                    onChangeHandler={onChangeHandler}
                                    caseNumber={caseNumber}
                                    review={props.review ?? false}></TransportationComponent>
                            </Col>
                        </Row>

                    </div>
                </form>
            </Collapse>
        </>
    )
}