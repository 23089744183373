import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EmbedInfoService } from "../../services/EmbedInfoService";
import { ReportService } from "../../services/ReportService";
import { models, Report, service, Embed, Page } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import './Report.css'
import { useAppContext } from "../../AppContext";
import { EmbedInfoDTO, NationalResettlementUserDTO } from "../../volunteer-tracking-client";
import { NationalResettlementUserService } from "../../services/NationalResettlementUserService";
import { Exception } from "../Exception";

export const NationalOfficeReportEmbed = () => {
	const [user, setUser] = useState<NationalResettlementUserDTO>()
	const [report, setReport] = useState<Report>();
	const [reportName, setReportName] = useState<string>();
	const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: undefined,
	})
	const [error, setError] = useState<Error>()
	const { reportId } = useParams();
	const app = useAppContext();

	const eventHandlersMap = new Map([
		['loaded', function () {
			console.log('Report has loaded');
		}],
		['rendered', function () {
			console.log('Report has rendered');

		}],
		['error', function (event?: service.ICustomEvent<any>) {
			if (event) {
				console.error(event.detail);
			}
		}]
	]);

	useEffect(() => {
		const getEmbeddConfig = async () => {
			const user = await NationalResettlementUserService.getNationalResettlementUser(app.user?.uniqueId)
			setUser(user);
			const report = await ReportService.getReport(parseInt(reportId || '0'))
			setReportName(report.reportName);
			const dto: EmbedInfoDTO = {
				workspaceId: report.workspaceId,
				reportId: report.reportId
			}
			const result = await EmbedInfoService.getEmbedInfo(dto)
			setReportConfig({
				...reportConfig, embedUrl: result.embedReport[0].embedUrl + "&filter=" + report.tableName + "/NationalResettlementAgencyID eq " + parseInt(localStorage.getItem("selectedOrganizationId")!), accessToken: result.embedToken.token,
				settings: {
					panes: {
						filters: {
							expanded: false,
							visible: false
						}
					},
				}
			})
		}
		try{
			getEmbeddConfig()
		}catch(ex:any){
			setError(ex);
		}
	}, [])

	return (
		!error?
		<>
			<div style={{ width: '100%' }}>
				<h2>{reportName}</h2>
			</div>
			<div>
				<PowerBIEmbed
					embedConfig={reportConfig}
					eventHandlers={eventHandlersMap}
					cssClassName={"report-style-class"}
					getEmbeddedComponent={(embedObject: Embed) => {
						console.log(embedObject)
						console.log(reportConfig.embedUrl)
						console.log(`Embedded object of type "${embedObject.embedtype}" received`);
						setReport(embedObject as Report);
					}}
				/>
				<div className="hr"></div>
			</div>
		</>:<Exception message={error.message}></Exception>
	);
}