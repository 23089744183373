import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import { Navigate} from "react-router-dom";
import { useAppContext } from "../AppContext";
import { UnauthorizedComponent } from "./UnauthorizedComponent";

const ProtectedRoute = ({ children, roles, redirectTo}:{children:any, roles?: string[], redirectTo:string}) => {
    const app = useAppContext()
    return (
        <React.Fragment>
            <AuthenticatedTemplate>{
                roles && app.user?.roles?.some(x => roles.indexOf(x) >= 0)? children:<UnauthorizedComponent></UnauthorizedComponent>
            }</AuthenticatedTemplate> 
            <UnauthenticatedTemplate><Navigate to={redirectTo}></Navigate></UnauthenticatedTemplate>
        </React.Fragment>
        )
}

export default ProtectedRoute