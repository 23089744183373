import React, { useEffect, useState } from "react"
import { Alert, Button, Card, Fade, Form, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LocalOfficeCaseActivityCheckboxService } from "../../services/LocalOfficeCaseActivityCheckboxService"
import { LocalOfficeCaseActivityCheckboxDTO, LocalOfficeDTO, UpdateLocalOfficeCaseActivitiesDTO } from "../../volunteer-tracking-client";
import { Exception } from "../Exception";
import { LocalOfficeService } from "../../services/LocalOfficeService";
import { SponsorshipGroupCheckboxSelections } from "./SponsorshipGroupCheckboxSelections";
import { WelcomeCorpCheckboxSelections } from "./WelcomeCorpCheckboxSelections";

export const LocalOfficeSettings = () => {
    const [localOfficeCaseActivities, setLocalOfficeCaseActivities] = useState<LocalOfficeCaseActivityCheckboxDTO[]>([])
    const [error, setError] = useState<Error>();
    const [alertHidden, setAlertHidden] = useState<boolean>(true)
    const [localOffice, setLocalOffice] = useState<LocalOfficeDTO>({ isWelcomeCorp: false })
    const updateLocalOfficeCaseActivitiesDTO: UpdateLocalOfficeCaseActivitiesDTO = {};
    const navigate = useNavigate();

    useEffect(() => {

        const getCaseActivitiesForLocalOffice = async () => {
            try {
                const localOffice = await LocalOfficeService.getLocalOffice(parseInt(localStorage.getItem("selectedOrganizationId")!))
                setLocalOffice(localOffice);
                const localOfficeCaseActivities =
                    await LocalOfficeCaseActivityCheckboxService.getCaseActivitiesForLocalOffice(parseInt(localStorage.getItem("selectedOrganizationId")!))
                setLocalOfficeCaseActivities(localOfficeCaseActivities.filter(l => l.caseActivityCheckbox?.name !== "other"))
                updateLocalOfficeCaseActivitiesDTO.localOfficeId = parseInt(localStorage.getItem("selectedOrganizationId")!)

            } catch (error: any) {
                setError(error);
            }
        }
        getCaseActivitiesForLocalOffice()

    }, [])

    const showAlert = () => {
        setAlertHidden(false)
        window.setTimeout(() => {
            setAlertHidden(true)
        }, 2000)
    }

    const handleChange = (event: any) => {
        setLocalOfficeCaseActivities(prevState => ([...localOfficeCaseActivities]))
    }

    const handleRadioSelection = (event: any) => {
        if (event.target.value === 'true') {
            setLocalOffice({ ...localOffice, ...{ isWelcomeCorp: true } })
        } else {
            setLocalOffice({ ...localOffice, ...{ isWelcomeCorp: false } })
        }
    }


    const cancel = () => {
        navigate('/local-office-home');
    }

    const saveChanges = (event: any) => {
        updateLocalOfficeCaseActivitiesDTO.localOfficeCaseActivities = localOfficeCaseActivities;
        updateLocalOfficeCaseActivitiesDTO.isWelcomeCorp = localOffice.isWelcomeCorp;
        updateLocalOfficeCaseActivitiesDTO.localOfficeId = localOffice.id
        try {
            LocalOfficeCaseActivityCheckboxService.updateCaseActivitiesForLocalOffice(updateLocalOfficeCaseActivitiesDTO)
            showAlert()
        } catch (error: any) {
            setError(error)
        }
    }

    return (
        !error?
        <>
            <Alert variant="success" hidden={alertHidden} transition={Fade}>
                Settings Updated!
            </Alert>
            <Card>
                <Card.Body>
                    <div>Please select Welcome Corps or Reception and Placement Activities</div>
                </Card.Body>
            </Card>
            <Form.Group className='mb-3' controlId='isWelcomeCorp'>
                <Stack direction='horizontal' gap={3}>
                    <Form.Check
                        type='radio'
                        label='Reception and Placement'
                        name='isWelcomeCorp'
                        value='false'
                        checked={!localOffice?.isWelcomeCorp}
                        onChange={handleRadioSelection}
                    />
                    <Form.Check
                        type='radio'
                        label='Welcome Corps'
                        name='isWelcomeCorp'
                        value='true'
                        checked={localOffice?.isWelcomeCorp}
                        onChange={handleRadioSelection}
                    />
                </Stack>
            </Form.Group>
            {!localOffice?.isWelcomeCorp && <SponsorshipGroupCheckboxSelections
                handleChange={handleChange}
                localOfficeCaseActivities={localOfficeCaseActivities}
            ></SponsorshipGroupCheckboxSelections>}
            {localOffice?.isWelcomeCorp && <WelcomeCorpCheckboxSelections
                handleChange={() => { }}
                localOfficeCaseActivities={localOfficeCaseActivities}></WelcomeCorpCheckboxSelections>}
            <Button className='mt-3 me-3' variant='primary' onClick={cancel}>
                Cancel
            </Button>
            <Button className='mt-3' variant='primary' onClick={saveChanges}>
                Save
            </Button>
        </>:<Exception message={error.message}></Exception>
    )
}