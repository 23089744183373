import { CaseSponsorshipGroupDTO, SponsorshipGroupDTO } from "../volunteer-tracking-client";

export const CreateCaseValidationService = {
    hasError: (page: string, fieldName: string, value: any): boolean => {
        if (page === 'CaseVolunteer') {
            switch (fieldName) {
                case 'firstName':
                case 'lastName':
                case 'email':
                    if (!value || value === '') {
                        return true;
                    }
                    return false;
                default:
                    return false;
            }
        } else {
            switch (fieldName) {
                case 'number':
                case 'clientNativeLanguageID':
                case 'countryOfOriginID':
                case 'localOfficeID':
                case 'nationalResettlementAgencyID':
                case 'email':
                case 'firstName':
                case 'lastName':
                case 'addressLine1':
                case 'city':
                case 'stateID':
                case 'zipCode':
                case 'grantTag':
                case 'grantTagOther':
                    if (!value || value === '') {
                        return true;
                    }
                    return false;
                case 'size':
                    if (!value || value === '') {
                        return true;
                    }
                    if (Number.parseInt(value) <= 0) {
                        return true;
                    }

                    return false;
                case 'contactNumber':
                    if (!value || value === '') {
                        return true;
                    }
                    if (value.length === 10 && !(value as string).match(/[^$,.\d]/)) {
                        return false;
                    }
                    if (value.length < 14 || value.length > 14) {
                        return true;
                    }
                    return false;
                case 'coreServiceDeliverables':
                    if (!value || value === '') {
                        return true;
                    }
                    if (page === 'SponsorshipGroup') {
                        return value < 8;
                    } else {
                        return value === 0;
                    }
                case 'clientDOA':
                    if (value) {
                        return new Date(value).getFullYear() > 9999
                    } else {
                        return false
                    }
                default:
                    return false;
            }
        }
    },
    caseSponsorshipGroupHasError: (caseSponsorshipGroup: CaseSponsorshipGroupDTO, page: string, fieldName: string, value: any): boolean => {
        switch (fieldName) {
            case 'dateOfMatch':
                if (!value || value === '') {
                    return true;
                }

                if (new Date(value).getFullYear() > 9999) {
                    return true;
                }
                return false;
            case 'commitmentDurationMonths':
                if (!value || value === '') {
                    return true;
                }
                return false;
            case 'commitmentDurationMonthsMax':
                if (!value || value === '') {
                    return true;
                }
                if (caseSponsorshipGroup.commitmentDurationMonths && caseSponsorshipGroup.commitmentDurationMonths > Number.parseInt(value)) {
                    return true;
                }
                return false;
            case 'coreServices':
                if (!value || value === '') {
                    return true;
                }
                if (caseSponsorshipGroup.typeID !== undefined && (caseSponsorshipGroup.typeID === 2 || caseSponsorshipGroup.typeID === 3)) {
                    return value === 0;
                } else if (caseSponsorshipGroup.typeID !== undefined && caseSponsorshipGroup.typeID === 1) {
                    return value < 8;
                } else {
                    return value === 0;
                }
            case 'requiredCashContribution':
                if (caseSponsorshipGroup.requiredCashContributionNA === true) {
                    return false;
                }
                if ((!value || value === '')) {
                    return true;
                }
                if (value) {
                    return value <= 0;
                }
                return false;
            case 'signedMoU':
                if ((!value || value === '') && (caseSponsorshipGroup.typeID !== undefined && (caseSponsorshipGroup.typeID === 2 || caseSponsorshipGroup.typeID === 4))) {
                    return false;
                }
                if ((!value || value === '')) {
                    return true;
                }
                return !value;
            default:
                return false;
        }
    },
    sponsorshipGroupHasError: (sponsorshipGroup: SponsorshipGroupDTO, page: string, fieldName: string, value: any): boolean => {
        switch (fieldName) {
            case 'name':
            case 'groupLeaderName':
            case 'groupLeaderContact':
            case 'typeID':
            case 'email':
            case 'firstName':
            case 'lastName':
                if (!value || value === '') {
                    return true;
                }
                return false;
            case 'totalGroupMembers':
                if (!value || value === '') {
                    return true;
                }
                if (sponsorshipGroup.sponsorshipGroupVolunteers && value < sponsorshipGroup.sponsorshipGroupVolunteers.filter(sgv => !sgv.isDeleted).length) {
                    return true;
                }
                return false;
            case 'sponsorshipGroupCommunityTypeID':
                if (!value || value === '') {
                    return true;
                }
                return false;
            case 'faithCommunityTypeID':
                if (sponsorshipGroup.sponsorshipGroupCommunityTypeID === 1) {
                    if (!value || value === '') {
                        return true;
                    }
                }
                return false;
            default:
                return false;
        }
    }
}