import React from "react"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { CreateCaseActivityDTO } from "../../../volunteer-tracking-client";

type VolunteerCaseActivityButtonProperties = {
    caseActivity: CreateCaseActivityDTO
}

export const VolunteerCaseActivityButtons = (props:VolunteerCaseActivityButtonProperties) => {
    const {caseActivity} = props
    const navigate = useNavigate();
    const onViewClick = async (selectedCaseActivity: CreateCaseActivityDTO) => {
        navigate('/volunteer-case-activity-summary/' + selectedCaseActivity.id);
    }

    const onEditClick = async (selectedCaseActivity: CreateCaseActivityDTO) => {
        navigate("/edit-case-activity/" + selectedCaseActivity.id);
    }

    return(
        <div className='case-item-buttons'>
                <Button size='sm' onClick={() => onEditClick(caseActivity)}>Edit Activity</Button>
                <Button size='sm' onClick={() => onViewClick(caseActivity)}>View Activity</Button>
        </div>
    )
}