import React, { useState } from "react"
import { Button, Col, Form, Modal } from "react-bootstrap"
import { LocalOfficeDTO } from "../../volunteer-tracking-client"

type VerifyDeleteModalProps = {
    show: boolean,
    confirmHandler: (localOfficeDto:LocalOfficeDTO) => void
    closeHandler: () => void,
    localOffice:LocalOfficeDTO

}


export const EditLocalOfficeModal = (props: VerifyDeleteModalProps) => {
    const [localOffice, setLocalOffice] = useState<LocalOfficeDTO>(props.localOffice)
    

    const onConfirm = () => {
        props.confirmHandler(localOffice)
    }

    const handleInputChange = (event: any): void => {
        var key = event.target.name;
        var value = event.target.value;
        setLocalOffice(prevState => ({ ...prevState, [key]: value }));
    }

    return (
        <Modal show={props.show} onHide={props.closeHandler} onShow={()=>setLocalOffice(props.localOffice)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Update Local Office</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Col sm={12}>
          <Form.Group className='mb-3' controlId='name'>
            <Form.Label>Local Office Name</Form.Label>
            <Form.Control type="input"  value={localOffice.name ?? ''} placeholder='Local Office Name' name='name'
              onChange={(event: any) => { handleInputChange(event) }}  />
          </Form.Group>
        </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.closeHandler}>Cancel</Button>
                <Button variant="primary" onClick={onConfirm}>Yes</Button>
            </Modal.Footer>
        </Modal>
    )
}