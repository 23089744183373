import { faCaretSquareDown, faCaretSquareUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react"
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { LocalOfficeCaseActivityCheckboxProps } from "./LocalOfficeCaseActivityCheckboxProps";

export const LocalOfficeCheckboxActivities = (props: LocalOfficeCaseActivityCheckboxProps) => {
    const { localOfficeCaseActivities, section, onChangeHandler, sectionOpened, readonly } = props
    const [open, setOpen] = useState<boolean>(sectionOpened);

    const questions = localOfficeCaseActivities.filter(lo => lo.caseActivityCheckbox?.section === section)

    const handleChange = (event: any) => {
        if (readonly) {
            return false;
        }
        const idx: number = localOfficeCaseActivities.findIndex(x => x.id === parseInt(event.target.id))
        localOfficeCaseActivities[idx].active = !localOfficeCaseActivities[idx].active
        onChangeHandler(event)
    }

    return (
        <>
            <Row className="mt-3" onClick={() => { setOpen(!open) }}
                aria-expanded={false}
                style={{
                    cursor: "pointer",
                    backgroundColor: "lightgray",
                    paddingTop: "5px",
                    borderRadius: "5px"
                }}>
                <Col xs={11}>
                    <h3>{props.header}</h3>
                </Col>
                {open ?
                    <Col xs={1}>
                        <FontAwesomeIcon
                            icon={faCaretSquareUp} style={{ color: "#1b6ec2" }} />
                    </Col> :
                    <Col xs={1}>
                        <FontAwesomeIcon
                            icon={faCaretSquareDown} style={{ color: "#1b6ec2" }} />
                    </Col>
                }
            </Row>
            <Collapse in={open}>
                <div key={props.header}>
                    {
                        questions.map(a => {
                            return (
                                <Fragment key={"fragment-" + a.id}>
                                    <Form.Group className="mb-3" id={a.id?.toString()}>
                                        <Form.Check type="checkbox" label={a.caseActivityCheckbox?.label}
                                            id={a.id!.toString()}
                                            name={a.caseActivityCheckbox?.name}
                                            checked={a.active}
                                            onChange={handleChange}
                                            key={a.caseActivityCheckbox?.name + "-" + a.id}
                                            style={{ display: "inherit" }}
                                        />
                                    </Form.Group>
                                </Fragment>)
                        })
                    }
                </div>
            </Collapse>
        </>

    )
}