import React from "react"
import { Container } from "react-bootstrap"
import { NationalResettlementAgencyCases } from "./NationalResettlementAgencyCases"

export const NationalOfficeUserHome = () =>{
    return (
        <Container>
            <NationalResettlementAgencyCases></NationalResettlementAgencyCases>
        </Container>
    )
}