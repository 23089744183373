import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { CaseService } from "../../../services/CaseService"
import { CaseDTO, GetVolunteerCasesDTO } from "../../../volunteer-tracking-client"
import { CaseActivitySectionProps } from "./CaseActivitySectionProps"
import { Exception } from "../../Exception"

export const CaseSelectionComponent = (props: CaseActivitySectionProps) => {
    const { volunteer, caseActivity, onChangeHandler } = props
    const [cases, setCases] = useState<CaseDTO[]>([]);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        const getCases = async () => {
            if (volunteer) {
                const volunteerCasesDTO: GetVolunteerCasesDTO = {
                    volunteerID: volunteer.id,
                    localOfficeID: parseInt(localStorage.getItem("selectedOrganizationId")!)
                }
                const resp = await CaseService.getVolunteerCases(volunteerCasesDTO);
                setCases(resp);
                onChangeHandler(() => {
                    if (resp.length == 1) {
                        return {
                            caseNumber: resp[0].number,
                            caseId: resp[0].id
                        }
                    }
                })
            }
        };
        try{
            getCases()
        }catch(ex:any){
            setError(ex)
        }
    }, [volunteer]);

    useEffect(() => {
        const getLocalOfficeCase = async () => {
            if (!volunteer && props.caseActivity.id && props.caseActivity.caseId) {
                const resp = await CaseService.getCase(props.caseActivity.caseId);
                setCases([resp]);
            }
        }
        try{
        getLocalOfficeCase();
        }catch(ex:any){
            setError(ex)
        }
    }, [caseActivity])

    return (
        !error?
        <>
            <Form.Group className='mb-3' >
                <Form.Label>Which client is this service provided to?</Form.Label>
                <Form.Control
                    name='caseId'
                    as='select'
                    value={cases.length == 1 ? cases[0].id : caseActivity?.caseId}
                    disabled={true}
                    onChange={(event: any) => {
                        onChangeHandler(() => {
                            return {
                                caseNumber: cases.find(c => c.id == event.target.value)?.number,
                                caseId: event.target.value
                            }
                        })

                    }}
                >
                    <option value=''>Select Client</option>
                    {cases.map((c, index) => {
                        const familyMember = c.familyMembers?.find(f => f.isPrimary)
                        return <option key={index} value={c.id}>{familyMember?.firstName + " " + familyMember?.lastName}</option>
                    })
                    }
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                    Required
                </Form.Control.Feedback>
            </Form.Group>
        </>:<Exception message={error.message}></Exception>
    )
}