import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { CaseActivitySectionProps } from './CaseActivitySectionProps';

export const CaseActivitySection4 = (props: CaseActivitySectionProps) => {
    const { onChangeHandler, caseActivity, review } = props
    const [financialDonationMade, setFinancialDonationMade] = useState<boolean>(caseActivity.financialDontationMade || false)

    useEffect(() => {
        setFinancialDonationMade(props.caseActivity.financialDontationMade ?? financialDonationMade);
    }, [props.caseActivity.financialDontationMade])

    const handleChange = (event: any) => {
        if (event.target.name === 'financialDonationAmount') {
            event.target.value = event.target.value.match(/^\$?(\d*\.?\d{0,2})/)[1]
        }
        return onChangeHandler(() => { return { [event.target.name]: event.target.value } })
    }

    const handleFinancialDonationClick = (event: any) => {
        setFinancialDonationMade(!financialDonationMade)
        return onChangeHandler(() => {
            return {
                [event.target.name]: event.target.value === 'true' ? true : false,
                financialDonationAmount: '',
                financialDonationPurpose: ''
            }
        })
    }

    return (
        <>
            <Row>
                <Col md={4}>
                    <Form.Group>
                        <h3>Was a financial donation made?</h3>
                        <Stack direction="horizontal">
                            <Form.Check
                                checked={
                                    caseActivity.financialDontationMade?.toString() === "true"}
                                inline
                                label="Yes"
                                name="financialDontationMade"
                                type="radio"
                                value="true"
                                onChange={handleFinancialDonationClick}
                                disabled={review} />
                            <Form.Check
                                checked={caseActivity.financialDontationMade ? caseActivity.financialDontationMade.toString() === "false" : true}
                                inline
                                label="No"
                                name="financialDontationMade"
                                type="radio"
                                value="false"
                                onChange={handleFinancialDonationClick}
                                disabled={review} />
                        </Stack>
                    </Form.Group>

                </Col>
            </Row>

            {financialDonationMade &&
                <>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col md={2}>
                                <Form.Label>Amount</Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control
                                    placeholder='Amount'
                                    isInvalid={caseActivity.financialDonationAmount?.toString() == ''}
                                    min={0}
                                    name='financialDonationAmount'
                                    value={'$' + caseActivity.financialDonationAmount || ''}
                                    disabled={review}
                                    onChange={handleChange} />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col md={4}>
                                <Form.Label>Purpose of donation</Form.Label>
                                <Form.Control
                                    placeholder='Purpose of donation'
                                    isInvalid={!caseActivity.financialDonationPurpose}
                                    name="financialDonationPurpose"
                                    value={caseActivity.financialDonationPurpose || ''}
                                    disabled={review}
                                    onChange={handleChange} />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form.Group>
                </>
            }
            <Form.Group className="mb-3">
                <Row>
                    <Col md={12}>
                        <label>
                            Optional: Please add additional information about this donation.
                        </label>
                    </Col>
                    <Col md={12}>
                        <textarea rows={4} className='form-control' style={{ "minWidth": "100%" }}
                            name="otherCaseActivityInformation"
                            value={caseActivity.otherCaseActivityInformation || ''}
                            onChange={handleChange}
                            disabled={review} />
                    </Col>
                </Row>
            </Form.Group>
        </>
    )

}
