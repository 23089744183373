
import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { CaseActivityCheckboxService } from "../../../services/CaseActivityCheckboxService";
import { CaseActivityCheckboxDTO, CreateCaseActivityTaskDTO } from "../../../volunteer-tracking-client";
import { CaseActivitySection2ReviewProps } from "../../LocalOffice/LocalOfficeCaseActivity/CaseActivitySection2ReviewProps";



export const CaseActivitySection2Review = (props: CaseActivitySection2ReviewProps) => {
    const { caseActivityTasks, header, section } = props
    const [questions, setQuestions] = useState<CaseActivityCheckboxDTO[]>([])
    const [tasks, setTasks] = useState<CreateCaseActivityTaskDTO[]>([])

    useEffect(() => {
        const getQuestions = async () => {
            const checkboxIds: number[] = caseActivityTasks.map(a => { return a.caseActivityCheckboxId || 0 })
            const checkboxList = (await CaseActivityCheckboxService.getActivityCheckboxesByIds(checkboxIds))
                .filter(q => q.section == section)
            setQuestions(checkboxList)
            setTasks(caseActivityTasks.filter(t => { return checkboxList.find(c => c.id == t.caseActivityCheckboxId) }))

        }
            getQuestions()
    }, []);

    return (
        tasks.length > 0?
            <>
                <div> <h4>{header}</h4></div>
                <div>

                    {
                        tasks.map(t => {
                            return (
                                <Fragment key={t.caseActivityCheckboxId}>
                                    <Form.Group className="mb-3" >
                                        <Form.Check type="checkbox" label={questions.find(q => q.id == t.caseActivityCheckboxId)?.label}
                                            checked={true}
                                            readOnly={true} />
                                    </Form.Group>
                                    {
                                        t.otherCaseActivityTaskText &&
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                value={t.otherCaseActivityTaskText}
                                                disabled />
                                        </Form.Group>
                                    }
                                </Fragment>
                            )

                        })
                    }
                </div>
            </ >
            : <></>
    )
}