import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CreateCaseDTO, FamilyMemberDTO, StateDTO } from '../../volunteer-tracking-client';
import { FamilyMemberForm } from './FamilyMembers/FamilyMemberForm';

export const ClientFamilyMemberDetails = (props: {
    handleChange: (key: any, value: any) => void,
    familyMembers: FamilyMemberDTO[],
    createCaseDTO: CreateCaseDTO,
    stateList: StateDTO[],
    handleFamilyMemberAdd: (key: any, value: FamilyMemberDTO) => void,
    handleFamilyMemberRemove: (key: any, index: number) => void
}) => {
    const handleFamilyMemberUpdate = (index: number, value: FamilyMemberDTO) => {
        let updatedList = props.familyMembers;
        updatedList = updatedList.map((familyMember, listIndex) => {
            return listIndex === index ? value : familyMember;
        });

        if (value.isPrimary) {
            updatedList = updatedList.map(familyMember => {
                if (!familyMember.isPrimary) {
                    familyMember.addressLine1 = value.addressLine1;
                    familyMember.addressLine2 = value.addressLine2;
                    familyMember.city = value.city;
                    familyMember.stateID = value.stateID;
                    familyMember.zipCode = value.zipCode;
                    return familyMember;
                } else {
                    return familyMember;
                }
            });
        }

        props.handleChange('familyMembers', updatedList);
    }

    const handleRemoveFamilyMember = (index: number) => {
        props.handleFamilyMemberRemove('familyMembers', index);
    }

    const handleAddFamilyMember = (_event: any) => {
        const primaryFamilyMember = props.familyMembers.filter(familyMember => familyMember.isPrimary)[0];
        const newFamilyMember: FamilyMemberDTO = {
            addressLine1: primaryFamilyMember.addressLine1,
            addressLine2: primaryFamilyMember.addressLine2,
            city: primaryFamilyMember.city,
            stateID: primaryFamilyMember.stateID,
            zipCode: primaryFamilyMember.zipCode,
            firstName: '',
            lastName: ''
        };
        props.handleFamilyMemberAdd('familyMembers', newFamilyMember);
    }

    return (
        <>
            {props.familyMembers?.map((familyMember, index) => {
                if (index === 0) {
                    return (
                        <Container key={index}>
                            <Row key={index}>
                                <FamilyMemberForm handleUpdate={handleFamilyMemberUpdate}
                                    handleRemove={handleRemoveFamilyMember}
                                    index={index}
                                    familyMember={familyMember}
                                    createCaseDTO={props.createCaseDTO}
                                    statesList={props.stateList} />
                            </Row>
                            <Row className='mb-3'>
                                <Col sm={8}>
                                    <h2>Additional Family Members</h2>
                                </Col>
                                <Col sm={4} className='d-flex justify-content-end'>
                                    <Button onClick={handleAddFamilyMember}>Add Family Member</Button>
                                </Col>
                            </Row>
                        </Container>);
                } else {
                    return (
                        <Container key={index}>
                            <Row className='my-3'>
                                <FamilyMemberForm handleUpdate={handleFamilyMemberUpdate}
                                    handleRemove={handleRemoveFamilyMember}
                                    index={index}
                                    familyMember={familyMember}
                                    createCaseDTO={props.createCaseDTO}
                                    statesList={props.stateList} />
                            </Row>
                        </Container>);
                }
            })
            }
        </>
    );
}
ClientFamilyMemberDetails.displayName = ClientFamilyMemberDetails.name;