import React, { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { LocalOfficeDTO, LocalOfficeClient } from '../../volunteer-tracking-client';
import { Button, Container } from 'react-bootstrap';
import { VerifyRemoveLocalOfficeModal } from './VerifyRemoveLocalOfficeModal';
import { LocalOfficeService } from '../../services/LocalOfficeService';
import { GridApi } from 'ag-grid-community';
import LocalOfficeListActionRenderer from './LocalOfficeListActionRenderer';
import { Exception } from '../Exception'; import { useNavigate } from 'react-router-dom';
import { EditLocalOfficeModal } from './EditLocalOfficeModal';
;

const localOfficeClient: LocalOfficeClient = new LocalOfficeClient()

export const ResettlementAgencyLocalOffices = () => {
    const [localOffices, setLocalOffices] = useState<LocalOfficeDTO[]>([]);
    const [showRemoveLocalOfficeModal, setShowRemoveLocalOfficeModal] = useState(false);
    const [selectedLocalOffice, setSelectedLocalOffice] = useState<LocalOfficeDTO>({})
    const [showEditLocalOfficeModal, setShowEditLocalOfficeModal] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi>();
    const [error, setError] = useState<Error>();
    const navigate = useNavigate();

    useEffect(() => {
        const getLocalOfficeVolunteers = async () => {
            await localOfficeClient.getByResettlementAgencyId(parseInt(localStorage.getItem("selectedOrganizationId")!))
                .then((resp) => { setLocalOffices(resp); });
        };
        try{
            getLocalOfficeVolunteers();
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    const onGridReady = (params: any) => {
        setGridApi(params.api)
        params.api.sizeColumnsToFit()
    }

    const handleRemoveLocalOffice = async () => {
        const row: LocalOfficeDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                await LocalOfficeService.removeLocalOffice(row[0].id)
                gridApi?.applyTransaction({ remove: [row[0]] })
                setShowRemoveLocalOfficeModal(false)
            } catch (error: any) {
                setError(error)
            }
        }
    }

    const handleRemoveLocalOfficeModalClose = () => {
        setShowRemoveLocalOfficeModal(false)
    }

    const getRowNodeId = (data: any) => data.id;

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
    };

    async function handleUpdateLocalOffice(localOfficeDto: LocalOfficeDTO): Promise<void> {
        await LocalOfficeService.updateLocalOffice(localOfficeDto)
        gridApi?.applyTransaction({ update: [{id: localOfficeDto.id, ...localOfficeDto}] })
        setShowEditLocalOfficeModal(false)
    }

    return (
        !error ?
            <Container>
                <VerifyRemoveLocalOfficeModal
                    show={showRemoveLocalOfficeModal}
                    confirmHandler={handleRemoveLocalOffice}
                    closeHandler={handleRemoveLocalOfficeModalClose}
                ></VerifyRemoveLocalOfficeModal>
                <h3>Local Offices</h3>
                <Button className="mb-3" onClick={() => { navigate("/create-local-office") }}>Create Local Office</Button>
                <div className="ag-theme-alpine" style={{ height: 700 }}>
                    <AgGridReact
                        getRowNodeId={getRowNodeId}
                        frameworkComponents={{
                            localOfficeListActionRenderer: LocalOfficeListActionRenderer
                        }}
                        onGridReady={onGridReady}
                        rowData={localOffices}
                        pagination={true}
                        paginationAutoPageSize={true}
                        defaultColDef={defaultColDef}
                        rowSelection={'single'}>
                        <AgGridColumn headerName="Office Name" field="name"></AgGridColumn>
                        <AgGridColumn headerName="Address Line 1" field="address1"></AgGridColumn>
                        <AgGridColumn headerName="Address Line 2" field="address2"></AgGridColumn>
                        <AgGridColumn headerName="City" field="city"></AgGridColumn>
                        <AgGridColumn headerName="State/Territory" field="state.name"></AgGridColumn>
                        <AgGridColumn headerName="Zip" field="postalCode"></AgGridColumn>
                        <AgGridColumn cellRenderer="localOfficeListActionRenderer" cellRendererParams={{
                            verifyRemoveLocalOffice: () => { setShowRemoveLocalOfficeModal(true) },
                            showEditLocalOfficeModal: (data:LocalOfficeDTO) => {
                                setSelectedLocalOffice(data)
                                setShowEditLocalOfficeModal(true)}
                        }}></AgGridColumn>
                    </AgGridReact>
                    <EditLocalOfficeModal localOffice={selectedLocalOffice}
                        show={showEditLocalOfficeModal} confirmHandler={(handleUpdateLocalOffice)}
                         closeHandler={() => {setShowEditLocalOfficeModal(false)}}></EditLocalOfficeModal>
                </div>
            </Container>
            : <Exception message={error.message}></Exception>
    );
}
