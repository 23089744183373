import React from "react"
import { useEffect } from "react"
import { useAppContext } from "../AppContext"
import Home from "./Home"

export const SignInComponent = () => {
    const app = useAppContext()
    useEffect(() => {
        const signIn = () => {
            if (app.signIn) {
                app.signIn()
            }
        }
        signIn()
    }, [])
    return (<Home></Home>)
}