import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { CreateCaseValidationService } from '../../../services/CreateCaseValidationService';
import { CreateCaseDTO, SponsorshipGroupVolunteerDTO, VolunteerDTO } from '../../../volunteer-tracking-client';
import './SponsorshipGroup.css';

export const SponsorshipGroupVolunteer = (props: {
    handleVolunteerUpdate: (index: number, value: SponsorshipGroupVolunteerDTO) => void,
    handleRemove: (index: number) => void,
    createCaseDTO: CreateCaseDTO,
    index: number,
    sponsorshipGroupVolunteer: SponsorshipGroupVolunteerDTO
}) => {
    const validationPageName = 'SponsorshipGroupVolunteer' + props.index.toString();

    const handleVolunteerChange = (event: any) => {
        const key: keyof VolunteerDTO = event.target.name;
        let value = event.target.value;
        if (props.sponsorshipGroupVolunteer.volunteer) {
            props.sponsorshipGroupVolunteer.volunteer[key] = value;
            props.handleVolunteerUpdate(props.index, props.sponsorshipGroupVolunteer);
        }
    }

    const hasValidationError = (page: string, field: string, value: any): boolean => {
        const hasError = CreateCaseValidationService.hasError(page, field, value);
        if (!hasError && field.includes('email')) {
            const validEmailExpression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const validEmail = (validEmailExpression.test(String(value).toLowerCase()));
            return !validEmail;
        }

        return hasError;
    }

    return (
        <>
            <Row>
                <Col>
                    <Form.Group className='mb-3' controlId='email'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control onChange={handleVolunteerChange}
                            isInvalid={hasValidationError(validationPageName, 'email', props.sponsorshipGroupVolunteer.volunteer?.email)}
                            name='email'
                            placeholder='Email'
                            value={props.sponsorshipGroupVolunteer.volunteer?.email ?? ''} />
                        <Form.Control.Feedback type='invalid'>
                            {props.sponsorshipGroupVolunteer.volunteer?.email ? 'Please enter a valid email address' : 'Required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='mb-3' controlId='firstName'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control onChange={handleVolunteerChange}
                            isInvalid={hasValidationError(validationPageName, 'firstName', props.sponsorshipGroupVolunteer.volunteer?.firstName)}
                            name='firstName'
                            placeholder='First name'
                            value={props.sponsorshipGroupVolunteer.volunteer?.firstName ?? ''} />
                        <Form.Control.Feedback type='invalid'>
                            Required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='mb-3' controlId='lastName'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control onChange={handleVolunteerChange}
                            isInvalid={hasValidationError(validationPageName, 'lastName', props.sponsorshipGroupVolunteer.volunteer?.lastName)}
                            name='lastName'
                            placeholder='Last name'
                            value={props.sponsorshipGroupVolunteer.volunteer?.lastName ?? ''} />
                        <Form.Control.Feedback type='invalid'>
                            Required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                {!props.sponsorshipGroupVolunteer.isGroupLeader &&
                    <Col sm={1} className='my-auto'>
                        <FontAwesomeIcon className='trash' icon={faTrashAlt} onClick={() => props.handleRemove(props.index)} />
                    </Col>
                }
            </Row>
        </>
    );
}
SponsorshipGroupVolunteer.displayName = SponsorshipGroupVolunteer.name;