import React, { useState } from "react"
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { SponsorshipGroupDTO } from "../../volunteer-tracking-client"

type EditSponsorshipGroupModalProps = {
    show: boolean,
    sponsorshipGroup: SponsorshipGroupDTO,
    confirmHandler: (sponsorshipGroup: SponsorshipGroupDTO) => void
    closeHandler: () => void
}


export const EditSponsorshipGroupModal = (props: EditSponsorshipGroupModalProps) => {
    const [sg, setSG] = useState<SponsorshipGroupDTO>(props.sponsorshipGroup);
    const onConfirm = () => {
        props.confirmHandler(sg);
    }

    const handleInputChange = (event: any) => {
        const key: keyof SponsorshipGroupDTO = event.target.name;
        let value = event.target.value;

        if (key === 'isDeleted') {
            value = value === 'true';
        }

        setSG(prevState => ({ ...prevState, [key]: value }));
    }

    return (
        <Modal show={props.show} onHide={props.closeHandler} centered size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Edit Sponsorship Group</Modal.Title>
            </Modal.Header>
            <Container>
                <Form>
                    <Row className='my-3'>
                        <Col>
                            <Form.Group className='mb-3' controlId='name'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control onChange={handleInputChange}
                                    name='name' value={sg.name} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='totalGroupMembers'>
                                <Form.Label>Total Group Members</Form.Label>
                                <Form.Control disabled={true}
                                    type='number'
                                    min={0}
                                    name='totalGroupMembers' value={sg.totalGroupMembers} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='isDeleted'>
                                <Form.Label>Deleted</Form.Label>
                                <Form.Control
                                    name='isDeleted'
                                    as='select'
                                    value={sg.isDeleted?.toString()}
                                    onChange={handleInputChange}
                                >
                                    <option value={'true'}>True</option>
                                    <option value={'false'}>False</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.closeHandler}>Cancel</Button>
                <Button variant="primary" onClick={onConfirm}>Update</Button>
            </Modal.Footer>
        </Modal>
    )
}