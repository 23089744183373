import React from 'react';
import { Col, Container } from 'react-bootstrap';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <Container>
                <Col>
                    <span>Ⓒ {new Date().getFullYear()} Church World Service</span>
                    {/* <span>
                        <a href=''>Privacy Policy</a>
                    </span> */}
                </Col>
                <Col>
                    <p>
                    WelcomeWorks and its content were developed as a project of CWS under an agreement paid for by the U.S. Department of State. Content does not represent the policy of the Federal Government.
                    </p>
                </Col>
            </Container>
        </footer>
    );
}

export default Footer