import { CaseActivityCheckboxClient, LocalOfficeActivityCheckboxBySectionDTO } from '../volunteer-tracking-client';

const client: CaseActivityCheckboxClient = new CaseActivityCheckboxClient();

export const CaseActivityCheckboxService = {
    getActivityCheckboxesBySection: async (section: string | undefined) => {
        return await client.getActivityCheckboxesBySection(section)
    },
    getActivityCheckboxesByIds: async (ids: number[]) => {
        const result = await client.getActivityCheckboxesByIds(ids)
        return result
    }, getLocalOfficeActivityCheckboxesBySection: async (localOfficeId: number, section: string) => {
        const dto: LocalOfficeActivityCheckboxBySectionDTO = { localOfficeId: localOfficeId, section: section }
        return await client.getLocalActivityCheckboxesBySection(dto)
    }
}