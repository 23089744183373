import { faTrashAlt } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Col, Form, FormLabel, Row } from "react-bootstrap"
import { InKindDonationDTO } from "../../../volunteer-tracking-client"

type InKindDonationProperties = {
    inKindDonation: InKindDonationDTO
    index: number
    onRemove?: (index: number) => void
    onChangeHandler: (inKindDonation: InKindDonationDTO) => void
    review?: boolean
}

export const InKindDonationItemComponent = (props: InKindDonationProperties) => {
    const { inKindDonation, onRemove, onChangeHandler } = props

    const handleChange = (event: any) => {
        if (event.target.name == 'amount') {
            event.target.value = event.target.value.match(/^\$?(\d*\.?\d{0,2})/)[1]
        }
        return onChangeHandler({ ...inKindDonation, ...{ [event.target.name]: event.target.value } })
    }

    return (
        <Row>
            <Col md={4}>
                <Form.Group className="mb-3">
                    <FormLabel>Name</FormLabel>
                    <Form.Control isInvalid={!inKindDonation.name}
                        required
                        value={inKindDonation.name ?? ''}
                        name='name'
                        onChange={handleChange}
                        disabled={props.review ?? false}></Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Required
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={3}>
                <Form.Group className="mb-3">
                    <FormLabel>Amount</FormLabel>
                    <Form.Control isInvalid={!inKindDonation.amount}
                        required
                        value={'$' + (inKindDonation.amount || '')}
                        name='amount' onChange={handleChange}
                        disabled={props.review ?? false}></Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Required
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            {props.review === undefined || !props.review &&
                <Col md={1} className="d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon className='trash text-danger' icon={faTrashAlt} onClick={() => {
                        if (onRemove) { onRemove(props.index) }
                    }} />
                </Col>
            }
        </Row>
    )

}