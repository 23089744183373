import { GrantType } from '@azure/msal-common/dist/utils/Constants';
import React from 'react';
import { Form, Container, Row, Col, Stack } from 'react-bootstrap';
import { CreateCaseValidationService } from '../../services/CreateCaseValidationService';
import { InputService } from '../../services/InputService';
import { CountryDTO, CreateCaseDTO, LanguageDTO, SpokenLanguageDTO } from '../../volunteer-tracking-client';

export const CaseDetails = (props: {
    handleChange: (key: any, value: any) => void,
    createCaseDTO: CreateCaseDTO,
    languageList: LanguageDTO[],
    countryList: CountryDTO[]
}) => {
    const validationPageName = 'CaseDetails';

    const handleInputChange = (event: any) => {
        const key = event.target.name;
        let value = event.target.value;

        if (key === 'clientDOA' && value === '') {
            value = undefined;
        }

        props.handleChange(key, value);
    }

    const handleCheckBoxChange = (event: any) => {
        let key = event.target.name;
        let value = event.target.checked;
        if (key === 'clientDoesNotHaveHasUSTie') {
            key = 'clientHasUSTie';
            value = !value;
        }

        if (value === false) {
            props.handleChange('clientUSTieName', undefined);
            props.handleChange('clientUSTiePrimaryLanguage', undefined);
            props.handleChange('clientUSTiePhoneNumber', undefined);
        }

        props.handleChange(key, value);
    }

    const handleLanguageSelectChange = (event: any) => {
        const key = event.target.name;
        const value = event.target.value as number;
        // eslint-disable-next-line
        const selectedLanguage = props.languageList?.filter(language => language.id == value)[0];

        props.handleChange(key, selectedLanguage?.id ?? undefined);
    }

    const handleSpokenLanguageSelectChange = (event: any) => {
        const key = 'otherLanguagesSpoken';
        const value = event.target.value;

        const oldSpokenLanguageList = props.createCaseDTO.otherLanguagesSpoken ? props.createCaseDTO.otherLanguagesSpoken : [];

        // eslint-disable-next-line
        const selectedLanguage = props.languageList.filter(language => language.name == value)[0];
        if (selectedLanguage && selectedLanguage.name) {
            const newSpokenLanguageDTO = {
                languageId: selectedLanguage.id
            } as SpokenLanguageDTO;

            if (!oldSpokenLanguageList.some(sl => sl.languageId === newSpokenLanguageDTO.languageId)) {
                oldSpokenLanguageList.push(newSpokenLanguageDTO);
            } else {
                oldSpokenLanguageList.splice(oldSpokenLanguageList.indexOf(newSpokenLanguageDTO), 1);
            }

            props.handleChange(key, oldSpokenLanguageList);
        }
    }

    const handleCountrySelectChange = (event: any) => {
        const key = event.target.name;
        const value = event.target.value as number;
        // eslint-disable-next-line
        const selectedCountry = props.countryList.filter(country => country.id == value)[0];

        props.handleChange(key, selectedCountry ? selectedCountry.id : undefined);
    }

    const hasValidationError = (page: string, field: string, value: any): boolean => {
        const hasError = CreateCaseValidationService.hasError(page, field, value);
        return hasError;
    }

    const handleGrantTagOnChange = (event: any) => {
        const key = event.target.name;
        const value = event.target.value as string;
        props.createCaseDTO.grantTagOther = ''
        handleInputChange(event)
    }

    return (
        <Container>
            <Form>
                <Row>
                    <Col>
                        <Form.Group className='mb-3' controlId='caseNumber'>
                            <Form.Label>Case Number</Form.Label>
                            <Form.Control isInvalid={hasValidationError(validationPageName, 'number', props.createCaseDTO.number)} onChange={handleInputChange}
                                placeholder='Enter case number' name='number' value={props.createCaseDTO.number ? props.createCaseDTO.number : ''}
                                disabled={props.createCaseDTO.id !== undefined} />
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='caseClientDOA'>
                            <Form.Label>Client Date of Arrival at final destination</Form.Label>
                            <Form.Control onChange={handleInputChange}
                                type='date'
                                placeholder='Enter Client Date of Arrival'
                                value={props.createCaseDTO.clientDOA ? props.createCaseDTO.clientDOA.toString().split('T')[0] : ''}
                                isInvalid={hasValidationError(validationPageName, 'clientDOA', props.createCaseDTO.clientDOA)}
                                name='clientDOA' />
                            <Form.Control.Feedback type='invalid'>
                                Invalid Date
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='caseClientNativeLanguage'>
                            <Form.Label>Client Native Language</Form.Label>
                            <Form.Control
                                name='clientNativeLanguageID'
                                as='select'
                                disabled={props.createCaseDTO.id !== undefined}
                                value={props.createCaseDTO.clientNativeLanguageID ?? undefined}
                                onChange={handleLanguageSelectChange}
                                isInvalid={hasValidationError(validationPageName, 'clientNativeLanguageID', props.createCaseDTO.clientNativeLanguageID)}
                            >
                                <option value={undefined}>Select Language</option>
                                {props.languageList.map((language, index) => {
                                    return <option key={index} value={language.id}>{language.name}</option>
                                })
                                }
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='mb-3' controlId='grantTag'>
                            <Form.Label>Grant Tag</Form.Label>
                            <Form.Control
                                name='grantTag'
                                as='select'
                                value={props.createCaseDTO.grantTag ?? undefined}
                                onChange={handleGrantTagOnChange}
                                isInvalid={hasValidationError(validationPageName, 'grantTag', props.createCaseDTO.grantTag)}>
                                <option value=''>Select Grant Tag</option>
                                <option value="R&amp;P">R&amp;P</option>
                                <option value="APA">APA</option>
                                <option value="MG">MG</option>
                                <option value="PC">PC</option>
                                <option value="Other">Other</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='grantTagOther' hidden={props.createCaseDTO.grantTag !== "Other"}>
                            <Form.Label>Enter Grant Tag</Form.Label>
                            <Form.Control isInvalid={props.createCaseDTO.grantTag == 'Other' && props.createCaseDTO.grantTagOther?.trim() == ''} onChange={handleInputChange}
                                placeholder='Enter Grant Tag' name='grantTagOther'
                                value={props.createCaseDTO.grantTagOther ? props.createCaseDTO.grantTagOther : ''}
                            />
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3' controlId='caseSize'>
                            <Form.Label>Case Size</Form.Label>
                            <Form.Control type='number'
                                isInvalid={hasValidationError(validationPageName, 'size', props.createCaseDTO.size)}
                                min={0}
                                placeholder='Case size'
                                disabled={props.createCaseDTO.id !== undefined}
                                onKeyDown={InputService.HandleNumberInput}
                                value={props.createCaseDTO.size ?? ''}
                                name='size'
                                onChange={handleInputChange} />
                            <Form.Control.Feedback type='invalid'>
                                {(props.createCaseDTO.size && props.createCaseDTO.size == 0) ? 'Must be greater than 0.' : 'Required'}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='caseClientCountryOfOrigin'>
                            <Form.Label>Client Country of Origin</Form.Label>
                            <Form.Control
                                as='select'
                                name='countryOfOriginID'
                                disabled={props.createCaseDTO.id !== undefined}
                                value={props.createCaseDTO.countryOfOriginID ?? undefined}
                                onChange={handleCountrySelectChange}
                                isInvalid={hasValidationError(validationPageName, 'countryOfOriginID', props.createCaseDTO.countryOfOriginID)}
                            >
                                <option value={undefined}>Select Country</option>
                                {props.countryList.map((country, index) => {
                                    return <option key={index} value={country.id}>{country.name}</option>
                                })
                                }
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='caseClientOtherLanguages'>
                            <Form.Label>Other Languages Spoken</Form.Label>
                            <Form.Control
                                as='select'
                                multiple
                                name='otherLanguagesSpoken'
                                disabled={props.createCaseDTO.id !== undefined}
                                value={props.createCaseDTO.otherLanguagesSpoken?.map(sl => {
                                    // eslint-disable-next-line
                                    return props.languageList.find(x => x.id == sl.languageId)?.name;
                                }) as Array<string>}
                                onClick={handleSpokenLanguageSelectChange}
                            >
                                {props.languageList.map((language, index) => {
                                    return <option key={index} value={language.name}>{language.name}</option>
                                })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className='mb-3' controlId='caseClientHasUSTie'>
                            <Form.Label>Does the client have a U.S. Tie?</Form.Label>
                            <Stack direction='horizontal' gap={3}>
                                <Form.Check
                                    type='radio'
                                    label='Yes'
                                    name='clientHasUSTie'
                                    checked={props.createCaseDTO.clientHasUSTie ? props.createCaseDTO.clientHasUSTie : false}
                                    onChange={handleCheckBoxChange}
                                />
                                <Form.Check
                                    type='radio'
                                    label='No'
                                    name='clientDoesNotHaveHasUSTie'
                                    checked={props.createCaseDTO.clientHasUSTie ? !props.createCaseDTO.clientHasUSTie : true}
                                    onChange={handleCheckBoxChange}
                                />
                            </Stack>
                        </Form.Group>
                    </Col>
                </Row>
                {props.createCaseDTO.clientHasUSTie &&
                    <Row>
                        <Col>
                            <Form.Group className='mb-3' controlId='caseClientUSTieName'>
                                <Form.Label>Name (First and Last name)</Form.Label>
                                <Form.Control isInvalid={false} onChange={(event) => props.handleChange(event.target.name, event.target.value)}
                                    name='clientUSTieName'
                                    placeholder='Name'
                                    value={props.createCaseDTO.clientUSTieName ? props.createCaseDTO.clientUSTieName : ''} />
                                <Form.Control.Feedback type='invalid'>
                                    Invalid
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='caseClientNativeLanguage'>
                                <Form.Label>Primary Language Spoken</Form.Label>
                                <Form.Control
                                    name='clientUSTiePrimaryLanguageID'
                                    as='select'
                                    value={props.createCaseDTO.clientUSTiePrimaryLanguageID ? props.createCaseDTO.clientUSTiePrimaryLanguageID : undefined}
                                    onChange={handleLanguageSelectChange}
                                >
                                    <option value={undefined}>Select Language</option>
                                    {props.languageList.map((language, index) => {
                                        return <option key={index} value={language.id}>{language.name}</option>
                                    })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='caseClientUSTiePhoneNumber'>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control isInvalid={false} onChange={handleInputChange}
                                    name='clientUSTiePhoneNumber'
                                    placeholder='Phone number'
                                    max={14}
                                    value={InputService.FormatPhoneNumber(props.createCaseDTO.clientUSTiePhoneNumber) ?? ''} />
                            </Form.Group>
                        </Col>
                    </Row>
                }
            </Form>
        </Container>
    );
}
CaseDetails.displayName = CaseDetails.name;