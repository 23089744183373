import React from 'react';
import { Card, Form, FormGroup } from 'react-bootstrap';
import { CreateCaseActivityDTO, VolunteerDTO } from '../../../volunteer-tracking-client';
import { CaseActivitySection1 } from './CaseActivitySection1';
import { CaseActivitySection2Review } from './CaseActivitySection2Review';
import { CaseActivitySection4 } from './CaseActivitySection4';

type CreateCaseActivityReviewProps = {
    caseActivity: CreateCaseActivityDTO,
    volunteer:VolunteerDTO
}

export const CreateCaseActivityReview = (props: CreateCaseActivityReviewProps) => {
    const { caseActivity, volunteer } = props
    const totalMinutes = caseActivity.caseActivityTasks?.reduce(
        (a, b) => { return a +  Number(b.minutesSpent || 0) }, 0) || 0
    const hoursInMinutes = Math.floor(totalMinutes / 60)
    const minutesRemainder = Number(((totalMinutes % 60) / 60).toFixed(2))
    const totalTime = (caseActivity.caseActivityTasks?.reduce(
        (a, b) => { return a + Number(b.hoursSpent || 0) }, 0) || 0) + hoursInMinutes + minutesRemainder 
    

    return (
        <>
        <Card>
            <Card.Body>
                <Card.Title>Client Information</Card.Title>
                    <CaseActivitySection1
                        review={true}
                        onChangeHandler={() => { }}
                        caseActivity={props.caseActivity}
                        volunteer={volunteer}></CaseActivitySection1>  
                    <FormGroup className='mb-3'>
                        <Form.Label>Time Spent On Activity/Activities:  {totalTime} hours</Form.Label>
                    </FormGroup>  
            </Card.Body>
        </Card>

        <Card>
            <Card.Body>
                    <Card.Title>Service Activity</Card.Title>
                    {caseActivity.caseActivityTasks?.length == 0 &&
                <div className="text-danger" >
                   You must select at least one activity
                </div>
            }
                    <CaseActivitySection2Review
                        caseActivityTasks={caseActivity.caseActivityTasks!}
                        header="Pre-Arrival"
                        section="PreArrival" />
                    <CaseActivitySection2Review
                        caseActivityTasks={caseActivity.caseActivityTasks!}
                        header="Arrival"
                        section="Arrival" />
                    <CaseActivitySection2Review
                        caseActivityTasks={caseActivity.caseActivityTasks!}
                        header="Service"
                        section="PostArrival" />
                    <CaseActivitySection2Review
                        caseActivityTasks={caseActivity.caseActivityTasks!}
                        header="Additional wrap-around services"
                        section="AdditionalServices"/>
            </Card.Body>
            </Card>
            
            <Card>
            <Card.Body>
                <Card.Title>Donations</Card.Title>
                    <CaseActivitySection4
                        caseActivity={caseActivity}
                        onChangeHandler={() => { }}
                        review={true}/>
            </Card.Body>
        </Card>
    </>
               
    );
}
