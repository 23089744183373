import React, { useEffect, useState } from 'react';
import { LocalOfficeUserDTO } from '../../volunteer-tracking-client';
import { useAppContext } from '../../AppContext';
import { NationalResettlementUserService } from '../../services/NationalResettlementUserService';
import { LocalOfficeUserList } from '../Common/LocalOfficeUserList';
import { LocalOfficeUserService } from '../../services/LocalOfficeUserService';
import { Exception } from '../Exception';

export const ResettlementAgencyLocalOfficeUsers = () => {
    const [localOfficeUsers, setLocalOfficeUsers] = useState<LocalOfficeUserDTO[]>([]);
    const [error, setError] = useState<Error>()

    const app = useAppContext();

    useEffect(() => {
        const getResettmentAgencyVolunteers = async () => {
            const resettlementUser = await NationalResettlementUserService.getNationalResettlementUser(app.user?.uniqueId);
            if (resettlementUser) {
                const resettlementAgencyId:number = parseInt(localStorage.getItem("selectedOrganizationId")!)
                await LocalOfficeUserService.getResettlementAgencyLocalOfficeUsers(resettlementAgencyId)
                    .then((resp) => {
                        const expandedUsers: LocalOfficeUserDTO[] = [];
                        resp.forEach(u => u.localOfficeLocalOfficeUsers?.forEach(lou => {
                            if(lou.localOffice?.nationalResettlementAgency?.id === resettlementAgencyId){
                                const user = { ...u, ...{ localOffice: lou.localOffice, isActive: lou?.isActive } }
                                expandedUsers.push(user)
                            }
                        }))
                        setLocalOfficeUsers(expandedUsers);

                    });
            }
        };
        try{
            getResettmentAgencyVolunteers();
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    return (
        !error?
        <LocalOfficeUserList localOfficeUsers={localOfficeUsers}></LocalOfficeUserList>
        :<Exception message={error.message}></Exception>
    );
}
