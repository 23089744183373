import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {faEdit } from '@fortawesome/free-solid-svg-icons';

export default (props: any) => {

    const showEditNationalAgencyModal = () => {
        props.showEditNationalAgencyModal(props.data)
    }

    return (
        <span style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faEdit} title='Edit Agency Name' onClick={showEditNationalAgencyModal} />
        </span>
    );
};



