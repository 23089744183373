import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
export default (props: any) => {
    const editGroupVolunteer = () => {
        props.editGroupVolunteer(props.data);
    }

    return (
        <span style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faPen} title='Edit' onClick={editGroupVolunteer} />
        </span>
    );
};

