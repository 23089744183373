import React from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { InputService } from '../../services/InputService';
import { CreateLocalOfficeUserDTO, LocalOfficeUserDTO } from '../../volunteer-tracking-client';

type CreateLocalOfficeUserProps = {
  handleInputChange: (event: any) => void,
  handleEmailChange: (event: any) => void,
  localOfficeUserDTO: LocalOfficeUserDTO | CreateLocalOfficeUserDTO | undefined,
  emailInvalidMessage?: string
  readonly?: boolean
}

export const CreateLocalOfficeUserForm = (props: CreateLocalOfficeUserProps) => {
  return (
    <>
      <Row>
        <Col sm={6}>
          <Form.Group className='mb-3' controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control type='email' isInvalid={props.emailInvalidMessage ? true : false} value={props.localOfficeUserDTO?.email ?? ''} placeholder='Email Address' name='email'
              onChange={(event: any) => { props.handleInputChange(event) }} onBlur={props.handleEmailChange} />
            <Form.Control.Feedback type="invalid">
              {props.emailInvalidMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className='mb-3' controlId='firstName'>
            <Form.Label>First Name</Form.Label>
            <Form.Control required placeholder='First Name' name='firstName' readOnly={props.readonly} value={props.localOfficeUserDTO?.firstName ?? ''} onChange={props.handleInputChange} />
            <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className='mb-3' controlId='lastName'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control required placeholder='Last Name' name='lastName' readOnly={props.readonly} value={props.localOfficeUserDTO?.lastName ?? ''} onChange={props.handleInputChange} />
            <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className='mb-3' controlId='phone'>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control required placeholder='Phone Number' name='phone' readOnly={props.readonly} value={InputService.FormatPhoneNumber(props.localOfficeUserDTO?.phone) ?? ''} onChange={props.handleInputChange} />
            <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

      </Row>
    </>
  );
}
