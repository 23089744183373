import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { CaseActivityCheckboxComponent } from './CaseActivityCheckboxComponent';
import { SelectedActivitiesProps } from './SelectedActivitiesProps';
import { LocalOfficeDTO } from '../../../volunteer-tracking-client';
import { LocalOfficeService } from '../../../services/LocalOfficeService';
import { Exception } from '../../Exception';


export const CaseActivitySection2 = (props: SelectedActivitiesProps) => {
    const { onChangeHandler, caseActivity, clientDOA, localOfficeId } = props
    const [localOffice, setLocalOffice] = useState<LocalOfficeDTO>({})
    const [error, setError] = useState<Error>()
    useEffect(() => {
        const getLocalOffice = async () => {
            const localOffice = await LocalOfficeService.getLocalOffice(localOfficeId)
            setLocalOffice(localOffice)
        }
        try{
            getLocalOffice()
        }catch(ex:any){
            setError(ex);
        }

    }, [])

    return (
        !error?
        <>
            {caseActivity.caseActivityTasks?.length === 0 &&
                <div className="text-danger" >
                    You must select at least one activity
                </div>
            }
            <Card>
                <Card.Body>
                    <div>Which activity/activities did you complete?</div>
                </Card.Body>
            </Card>
            {localOffice?.isWelcomeCorp &&
                <>
                    <CaseActivityCheckboxComponent
                        onChangeHandler={onChangeHandler}
                        caseActivityTasks={caseActivity!.caseActivityTasks || []}
                        localOfficeId={localOfficeId}
                        clientDOA={clientDOA!}
                        header="Pre-Arrival"
                        section='wcPreArrival'
                        sectionOpened={true} />
                    <CaseActivityCheckboxComponent
                        onChangeHandler={onChangeHandler}
                        caseActivityTasks={caseActivity!.caseActivityTasks || []}
                        localOfficeId={localOfficeId}
                        clientDOA={clientDOA!}
                        header="Day of Arrival"
                        section='wcDayOfArrival'
                        sectionOpened={false} />
                    <CaseActivityCheckboxComponent
                        onChangeHandler={onChangeHandler}
                        caseActivityTasks={caseActivity!.caseActivityTasks || []}
                        localOfficeId={localOfficeId}
                        clientDOA={clientDOA!}
                        header="Weeks 1-4"
                        section='wcWeeks1-4'
                        sectionOpened={false} />
                    <CaseActivityCheckboxComponent
                        onChangeHandler={onChangeHandler}
                        caseActivityTasks={caseActivity!.caseActivityTasks || []}
                        localOfficeId={localOfficeId}
                        clientDOA={clientDOA!}
                        header="Weeks 5-12"
                        section='wcWeeks5-12'
                        sectionOpened={false} />
                </>
            }

            {!localOffice?.isWelcomeCorp &&
                <>
                    <CaseActivityCheckboxComponent
                        onChangeHandler={onChangeHandler}
                        caseActivityTasks={caseActivity!.caseActivityTasks || []}
                        localOfficeId={localOfficeId}
                        clientDOA={clientDOA!}
                        header="Pre-Arrival"
                        section='PreArrival'
                        sectionOpened={true} />
                    <CaseActivityCheckboxComponent
                        onChangeHandler={onChangeHandler}
                        caseActivityTasks={caseActivity!.caseActivityTasks || []}
                        localOfficeId={localOfficeId}
                        clientDOA={clientDOA!}
                        header="Arrival"
                        section='Arrival'
                        sectionOpened={false} />
                    <CaseActivityCheckboxComponent
                        onChangeHandler={onChangeHandler}
                        caseActivityTasks={caseActivity!.caseActivityTasks || []}
                        localOfficeId={localOfficeId}
                        clientDOA={clientDOA!}
                        header="Service"
                        section='PostArrival'
                        sectionOpened={false} />
                    <CaseActivityCheckboxComponent
                        onChangeHandler={onChangeHandler}
                        caseActivityTasks={caseActivity!.caseActivityTasks || []}
                        localOfficeId={localOfficeId}
                        clientDOA={clientDOA!}
                        header="Additional wrap-around services"
                        section='AdditionalServices'
                        sectionOpened={false} />
                </>
            }
        </>:<Exception message={error.message}></Exception>
    )

}
