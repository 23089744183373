import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { SponsorshipGroupClient, SponsorshipGroupVolunteerDTO } from "../../volunteer-tracking-client";
import GroupVolunteerActionRenderer from "./GroupVolunteerActionRenderer";
import { Exception } from "../Exception";
import { EditGroupVolunteerModal } from "./EditGroupVolunteerModal";

const client: SponsorshipGroupClient = new SponsorshipGroupClient();

export const GroupVolunteerList = (props: {
    sponsorshipGroupVolunteers: SponsorshipGroupVolunteerDTO[],
    handleGroupChange: (sponsorshipGroupVolunteers: SponsorshipGroupVolunteerDTO[]) => void
}) => {
    const [sgvs, setSGVS] = useState<SponsorshipGroupVolunteerDTO[]>(props.sponsorshipGroupVolunteers);
    const [showEditGroupVolunteerModal, setShowEditGroupVolunteerModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState<SponsorshipGroupVolunteerDTO>({});
    const [error, setError] = useState<Error>();

    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
    };

    const handleEditClick = (data: SponsorshipGroupVolunteerDTO) => {
        setSelectedRow(data);
        setShowEditGroupVolunteerModal(true);
    }

    const handleGroupVolunteerChange = async (newGroupVolunteer: SponsorshipGroupVolunteerDTO) => {
        const updatedVolunteers = sgvs?.map(sgv => {
            if (sgv.id === newGroupVolunteer.id) {
                sgv = newGroupVolunteer;
            }

            return sgv;
        });

        try {
            await client.updateSponsorshipGroupVolunteer(newGroupVolunteer);
            setSGVS(updatedVolunteers);
            props.handleGroupChange(updatedVolunteers);
        } catch (ex: any) {
            setError(ex);
        }
        setShowEditGroupVolunteerModal(false);
    }

    return (
        !error ?
            <Container>
                {showEditGroupVolunteerModal &&
                    <EditGroupVolunteerModal
                        show={showEditGroupVolunteerModal}
                        groupVolunteer={selectedRow}
                        confirmHandler={handleGroupVolunteerChange}
                        closeHandler={() => setShowEditGroupVolunteerModal(false)}
                    />
                }
                <div className="ag-theme-alpine" style={{ height: '800' }}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        rowData={sgvs}
                        frameworkComponents={{
                            groupVolunteerActionRenderer: GroupVolunteerActionRenderer
                        }}
                        onGridReady={onGridReady}
                        domLayout='autoHeight'
                        rowSelection={'single'}>
                        <AgGridColumn headerName="Email" field="volunteer.email"></AgGridColumn>
                        <AgGridColumn headerName="First Name" field="volunteer.firstName"></AgGridColumn>
                        <AgGridColumn headerName="Last Name" field="volunteer.lastName"></AgGridColumn>
                        <AgGridColumn headerName="Active Member" valueGetter={params => {
                            return !params.data.isDeleted
                        }}></AgGridColumn>
                        <AgGridColumn headerName='Actions' cellRenderer="groupVolunteerActionRenderer" cellRendererParams={{
                            editGroupVolunteer: (data: SponsorshipGroupVolunteerDTO) => { handleEditClick(data) }
                        }}></AgGridColumn>
                    </AgGridReact>
                </div>
            </Container> :
            <>
                <Exception message={error.message}></Exception>
            </>
    )

} 