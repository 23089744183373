import React, { useEffect, useState } from 'react';
import { VolunteerDTO, VolunteerClient } from '../../volunteer-tracking-client';
import { VolunteerList } from '../Common/VolunteerList';
import { Container } from 'react-bootstrap';
import { Exception } from '../Exception';

const volunteerClient: VolunteerClient = new VolunteerClient()

export const LocalOfficeVolunteers = () => {
    const [volunteers, setVolunteers] = useState<VolunteerDTO[]>([]);
    const [error, setError] = useState<Error>()

    useEffect(() => {
        const getLocalOfficeVolunteers = async () => {
            await volunteerClient.getLocalOfficeVolunteers(parseInt(localStorage.getItem("selectedOrganizationId")!))
                .then((resp) => {
                    const expandedUsers: VolunteerDTO[] = [];
                    resp.forEach(u => u.localOfficeVolunteers?.forEach(lou => {
                        const user = { ...u, ...{ localOffice: lou.localOffice, isActive: lou?.isActive } }
                        expandedUsers.push(user)
                    }))

                    setVolunteers(expandedUsers);
                });
        };
        try{
            getLocalOfficeVolunteers();
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    return (
        !error?
        <Container>
            <VolunteerList volunteers={volunteers}></VolunteerList>
        </Container>:<Exception message={error.message}></Exception>
    );
}

LocalOfficeVolunteers.displayName = LocalOfficeVolunteers.name;