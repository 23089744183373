import { GridApi } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { InactivateVolunteerDTO, ReinviteUserDTO, VolunteerListDTO } from "../../volunteer-tracking-client";
import { Exception } from "../Exception";
import { VerifyRemoveVolunteerModal } from "./VerifyRemoveVolunteerModal";
import VolunteerListActionRenderer from "./UserListActionRenderer";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { VolunteerService } from "../../services/VolunteerService";
import { Button, Col, Row } from "react-bootstrap";
import { VerifyReactivateVolunteerModal } from "./VerifyReactivateVolunteerModal";
import { VerifyReinviteModal } from "./VerifyReinviteModal";

type VolunteerListProperties = {
    volunteers: VolunteerListDTO[]
}

export const VolunteerList = (props: VolunteerListProperties) => {
    const [gridApi, setGridApi] = useState<GridApi>();
    const [showRemoveVolunteerModal, setShowVolunteerModal] = useState(false);
    const [showReactivateVolunteerModal, setShowReactivateVolunteerModal] = useState(false);
    const [showReinviteUserModal, setShowReinviteUserModal] = useState(false)
    const [error, setError] = useState<Error>();
    const [activeVolunteers, setActiveVolunteers] = useState<VolunteerListDTO[]>([]);
    const [inactiveVolunteers, setInactiveVolunteers] = useState<VolunteerListDTO[]>([]);
    const [showActive, setShowActive] = useState<boolean>(true);

    useEffect(() => {
        const activeVolunteers = props.volunteers.filter(volunteer => volunteer.isActive);
        setActiveVolunteers(activeVolunteers);
        const inactiveVolunteers = props.volunteers.filter(volunteer => !volunteer.isActive);
        setInactiveVolunteers(inactiveVolunteers);

    }, [props.volunteers]);

    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit()
        setGridApi(params.api)
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
    };

    const handleRemoveVolunteer = async () => {
        const row: VolunteerListDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                const inactiveVolunteersDTO: InactivateVolunteerDTO = {
                    principalId: row[0].principalId,
                    localOfficeId: parseInt(localStorage.getItem("selectedOrganizationId")!)
                }
                await VolunteerService.deleteVolunteer(inactiveVolunteersDTO)
                gridApi?.applyTransaction({ remove: [row[0]] })
                const index = activeVolunteers.findIndex(v => v.id === row[0].id)
                activeVolunteers.splice(index, 1);
                row[0] = { ...row[0], ...{ isActive: false } }
                inactiveVolunteers.push(row[0])
                setShowVolunteerModal(false)
            } catch (error: any) {
                setError(error)
            }
        }
    }

    const handleReactivateVolunteer = async () => {
        const row: VolunteerListDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                const inactiveVolunteersDTO: InactivateVolunteerDTO = {
                    principalId: row[0].principalId,
                    localOfficeId: parseInt(localStorage.getItem("selectedOrganizationId")!)
                }
                await VolunteerService.reactivateVolunteer(inactiveVolunteersDTO)
                gridApi?.applyTransaction({ remove: [row[0]] })
                const index = inactiveVolunteers.findIndex(v => v.id === row[0].id)
                inactiveVolunteers.splice(index, 1);
                row[0] = { ...row[0], ...{ isActive: true } }
                activeVolunteers.push(row[0])
                setShowReactivateVolunteerModal(false)
            } catch (error: any) {
                setError(error)
            }
        }
    }

    const handleReinviteUser = async () => {
        const row: VolunteerListDTO[] | undefined = gridApi?.getSelectedRows()
        if (row) {
            try {
                const reinviteUserDTO: ReinviteUserDTO = {
                    principalId: row[0].principalId,
                }
                await VolunteerService.reinviteVolunteer(reinviteUserDTO)        
                setShowReinviteUserModal(false);
            } catch (error: any) {
                setError(error)
            }
        }
    }

    const handleReinviteUserModalClose = () => {
        setShowReinviteUserModal(false)
    }

    const handleRemoveVolunteerModalClose = () => {
        setShowVolunteerModal(false)
    }

    const handleReactivateVolunteerModalClose = () => {
        setShowReactivateVolunteerModal(false)
    }

    const getRowNodeId = (data: any) => data.id + ":" + data.localOffice.id;

    const handleShowActiveClick = () => {
        setShowActive(!showActive);
    }

    return (
        !error ?
            <>
                <VerifyRemoveVolunteerModal
                    show={showRemoveVolunteerModal}
                    confirmHandler={handleRemoveVolunteer}
                    closeHandler={handleRemoveVolunteerModalClose}
                ></VerifyRemoveVolunteerModal>
                <VerifyReactivateVolunteerModal
                    show={showReactivateVolunteerModal}
                    confirmHandler={handleReactivateVolunteer}
                    closeHandler={handleReactivateVolunteerModalClose}
                ></VerifyReactivateVolunteerModal>
                <VerifyReinviteModal
                    show={showReinviteUserModal}
                    confirmHandler={handleReinviteUser}
                    closeHandler={handleReinviteUserModalClose}
                ></VerifyReinviteModal>
                <Row className='mb-3'>
                    <Col className='d-flex align-items-center'>
                        <h3 className='ps-3'>{showActive ? 'Active ' : 'Inactive '}Volunteers</h3>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <Button onClick={handleShowActiveClick}>{showActive ? 'Show Inactive Volunteers' : 'Show Active Volunteers'}</Button>
                    </Col>
                </Row>
                <div className="ag-theme-alpine" style={{ height: 700 }}>
                    <AgGridReact
                        getRowNodeId={getRowNodeId}
                        frameworkComponents={{
                            volunteerListActionRenderer: VolunteerListActionRenderer
                        }}
                        onGridReady={onGridReady}
                        rowData={showActive ? activeVolunteers : inactiveVolunteers}
                        pagination={true}
                        paginationAutoPageSize={true}
                        defaultColDef={defaultColDef}
                        rowSelection={'single'}
                    >
                        <AgGridColumn headerName="Last Name" field="lastName"></AgGridColumn>
                        <AgGridColumn headerName="First Name" field="firstName"></AgGridColumn>
                        <AgGridColumn headerName="Email" field="email"></AgGridColumn>
                        <AgGridColumn headerName="Local Office" field="localOffice.name"></AgGridColumn>
                        <AgGridColumn headerName="Resettlement Agency" field="localOffice.nationalResettlementAgency.name"></AgGridColumn>
                        <AgGridColumn cellRenderer="volunteerListActionRenderer" cellRendererParams={{
                            verifyRemoveVolunteer: () => { setShowVolunteerModal(true) },
                            verifyReactivateVolunteer: () => { setShowReactivateVolunteerModal(true) },
                            verifyReinviteUser: () => { setShowReinviteUserModal(true)},
                            titleText: "Volunteer"
                        }}></AgGridColumn>
                    </AgGridReact>
                </div>
            </> :
            <>
                <Exception message={error.message}></Exception>
            </>
    )

} 