import React from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useAppContext } from '../AppContext';
import { Navigate } from 'react-router-dom';

export const Landing = () => {
    const app = useAppContext();

    const getLandingPage = () => {
        if (localStorage.getItem("selectedRole") === "GlobalAdmin.All") {
            return '/global-admin-home'
        }
        if (localStorage.getItem("selectedRole") === "NationalOfficeUser.All") {
            return '/national-office-home'
        }

        if (localStorage.getItem("selectedRole") === "OfficeUser.All") {
            return '/local-office-home'
        }

        if (localStorage.getItem("selectedRole") === "Volunteer.All") {
            return '/volunteer-home'
        }
        return '/unauthorized'
    }

    return (
        <Container fluid>
            <Row className="bg-light rounded-3">
                <Col md={8} className="p-4">
                    <Stack gap={3}>
                        <img src='/app_logo.png' width={'50%'} className='d-inline-block align-top' alt=""></img>
                        <AuthenticatedTemplate>
                            {app.user ? <Navigate to={getLandingPage()}></Navigate> : 'Please log in'}
                        </AuthenticatedTemplate>
                    </Stack>
                </Col>
            </Row>
        </Container>
    );
}


