import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../AppContext";
import { VolunteerService } from "../../../services/VolunteerService";
import { CaseActivityClient, CreateCaseActivityDTO, LocalOfficeDTO, VolunteerDTO } from "../../../volunteer-tracking-client";
import { CaseActivitySection1 } from "../../Case/Activity/CaseActivitySection1";
import { CaseActivitySection2Review } from "../../Case/Activity/CaseActivitySection2Review";
import { CaseActivitySection3 } from "../../Case/Activity/CaseActivitySection3";
import { CaseActivitySection4 } from "../../Case/Activity/CaseActivitySection4";
import { CollapseComponent } from "../../Common/CollapseComponent";
import { LocalOfficeService } from "../../../services/LocalOfficeService";
import { WelcomeCoreCaseActivitySection2Review } from "./WelcomeCoreCaseActivitySection2Review";
import { RPCaseActivitySection2Review } from "./RPCaseActivitySection2Review";
import { Exception } from "../../Exception";

const caseActivityClient: CaseActivityClient = new CaseActivityClient();

export const VolunteerCaseActivitySummary = () => {
    const { caseActivityId } = useParams();
    const app = useAppContext();
    const [caseActivity, setCaseActivity] = useState<CreateCaseActivityDTO>();
    const [volunteer, setVolunteer] = useState<VolunteerDTO>();
    const [localOffice, setLocalOffice] = useState<LocalOfficeDTO>();
    const [section1Open, setSection1Open] = useState<boolean>(true);
    const [section2Open, setSection2Open] = useState<boolean>(false);
    const [section3Open, setSection3Open] = useState<boolean>(false);
    const [section4Open, setSection4Open] = useState<boolean>(false);
    const [error, setError] = useState<Error>()

    useEffect(() => {
        const getVolunteer = async () => {
            const response: VolunteerDTO = await VolunteerService.getVolunteerByPrincipalId(app.user?.uniqueId)
            setVolunteer(response)
        }
        const getCaseActivity = async () => {
            if (caseActivityId) {
                const caseActivity = await caseActivityClient.getCaseActivityForEdit(Number.parseInt(caseActivityId));
                setCaseActivity(caseActivity);
            }
        };

        const getLocalOffice = async () => {
            const localOffice: LocalOfficeDTO = await LocalOfficeService.getLocalOffice(parseInt(localStorage.getItem("selectedOrganizationId")!))
            setLocalOffice(localOffice)

        }
        try{
            getVolunteer();
            getLocalOffice();
            if (caseActivityId) {
                getCaseActivity();
            }
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    const handleCollapseHeaderClick = (open: boolean, sectionId: number) => {
        switch (sectionId) {
            case 1:
                setSection1Open(open);
                return;
            case 2:
                setSection2Open(open);
                return;
            case 3:
                setSection3Open(open);
                return;
            case 4:
                setSection4Open(open);
                return;
            default:
                setSection1Open(true);
                setSection2Open(false);
                setSection3Open(false);
                setSection4Open(false);
                return;
        }
    }

    const handleChanges = (fn: () => any): void => {
        setCaseActivity(prevState => ({ ...prevState, ...fn() }));
    }

    return (
        !error?
        <Container className='mb-3'>
            {caseActivity ?
                <>
                    <CollapseComponent open={section1Open} header={'Client Information'} sectionId={1}
                        onClick={handleCollapseHeaderClick}>
                        <CaseActivitySection1
                            onChangeHandler={handleChanges}
                            caseActivity={caseActivity}
                            volunteer={volunteer}
                            review={true} />
                    </CollapseComponent>
                    <CollapseComponent open={section2Open} header={'Service Activity'} sectionId={2}
                        onClick={handleCollapseHeaderClick}>
                        {localOffice?.isWelcomeCorp ?
                            <WelcomeCoreCaseActivitySection2Review
                                caseActivityTasks={caseActivity.caseActivityTasks!}
                            /> : <RPCaseActivitySection2Review
                                caseActivityTasks={caseActivity.caseActivityTasks!}
                            />
                        }
                    </CollapseComponent>
                    <CollapseComponent open={section3Open} header={'Case Notes'} sectionId={3}
                        onClick={handleCollapseHeaderClick}>
                        {caseActivity.caseActivityTasks && caseActivity.caseActivityTasks.length > 0 ?
                            <CaseActivitySection3
                                onChangeHandler={handleChanges}
                                caseActivity={caseActivity}
                                review={true} />
                            :
                            <div className="text-danger" >
                                You must select at least one activity
                            </div>
                        }
                    </CollapseComponent>
                    <CollapseComponent open={section4Open} header={'Donations'} sectionId={4}
                        onClick={handleCollapseHeaderClick}>
                        <CaseActivitySection4
                            caseActivity={caseActivity}
                            onChangeHandler={() => { }}
                            review={true} />
                    </CollapseComponent>
                </>
                :
                <Row className='mt-3'>
                    <p>Client has no associated activity</p>
                </Row>
            }
        </Container>:<Exception message={error.message}></Exception>
    )
}