import React, { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { NationalResettlementAgencyClient, NationalResettlementAgencyDTO } from '../../volunteer-tracking-client';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import NationalResettlementAgencyListActionRenderer from './NationalResettlementAgencyListActionRenderer';
import { EditNationalResettlementAgencyModal } from './EditNationalResettlementAgencyModal';
import { GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { NationalResettlementAgencyService } from '../../services/NationalResettlementAgencyService';
import { Exception } from '../Exception';

const client: NationalResettlementAgencyClient = new NationalResettlementAgencyClient();

export const ListNationalResettlementAgencies = () => {
    const [resettlementAgencies, setResettlementAgencies] = useState<NationalResettlementAgencyDTO[]>([]);
    const [selectedAgency, setSelectedAgency] = useState<NationalResettlementAgencyDTO>({})
    const [gridApi, setGridApi] = useState<GridApi>();
    const [error, setError] = useState<Error>()
    const [showEditNationalResettlementAgencyModal, setShowEditNationalResettlementAgencyModal] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getAllNationalResettlementAgencies = async () => {
            await client.getAllNationalResettlementAgencies()
                .then((resp) => { setResettlementAgencies(resp); });
        };
        try{
            getAllNationalResettlementAgencies();
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    const onGridReady = (params: any) => {
        setGridApi(params.api)
        params.api.sizeColumnsToFit()
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
    };

    const handleUpdateAgency = async (agencyDto:NationalResettlementAgencyDTO) => {
        await NationalResettlementAgencyService.updateNationalResettlementAgency(agencyDto)
        gridApi?.applyTransaction({ update: [{id: agencyDto.id, name: agencyDto.name}] })
        setShowEditNationalResettlementAgencyModal(false)
    }

    return (
        !error?
        <Container>
            <h3>National Resettlement Agencies</h3>
            <Button className="mb-3" onClick={()=>{navigate("/create-national-resettlement-agency")}}>Create National Resettlement Agency</Button>
            <div className="ag-theme-alpine" style={{ height: '100%' }}>
                <AgGridReact
                    defaultColDef={defaultColDef}
                    rowData={resettlementAgencies}
                    onGridReady={onGridReady}
                    domLayout='autoHeight'
                    getRowNodeId={(data)=>{return data.id}}
                    frameworkComponents={{
                        nationalResettlementAgencyListActionRenderer: NationalResettlementAgencyListActionRenderer
                    }}
                >
                    <AgGridColumn headerName="Name" field="name"></AgGridColumn>
                    <AgGridColumn cellRenderer="nationalResettlementAgencyListActionRenderer" width={10} cellRendererParams={{
                            showEditNationalAgencyModal: (data: NationalResettlementAgencyDTO) => {
                                setSelectedAgency(data)
                                setShowEditNationalResettlementAgencyModal(true)}
                        }}></AgGridColumn>
                </AgGridReact>
            </div>
            <EditNationalResettlementAgencyModal agency={selectedAgency}
             show={showEditNationalResettlementAgencyModal} confirmHandler={handleUpdateAgency}
             closeHandler={() => {setShowEditNationalResettlementAgencyModal(false)}}></EditNationalResettlementAgencyModal>
        </Container>:<Exception message={error.message}></Exception>
    );
}

ListNationalResettlementAgencies.displayName = ListNationalResettlementAgencies.name;