import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faPen, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { Stack } from 'react-bootstrap';

export default (props: any) => {
    const viewVolunteers = () => {
        props.viewVolunteers(props.data)
    }

    const editGroup = () => {
        props.editGroup(props.data);
    }

    const verifyRemoveSponsorshipGroup = () => {
        props.verifyRemoveSponsorshipGroup(props.data);
    }

    return (
        <Stack direction='horizontal' gap={3}>
            <span style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faUserEdit} title='View Volunteers' onClick={viewVolunteers} />
            </span>
            <span style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faPen} title='Edit' onClick={editGroup} />
            </span>
            <span style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faTrash} title='Delete' onClick={verifyRemoveSponsorshipGroup} />
            </span>
        </Stack >
    );
};

