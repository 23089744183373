import { faCaretSquareDown, faCaretSquareUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Collapse, Container, Row } from "react-bootstrap";

type CollapseHeaderProps = {
    open: boolean,
    sectionId: number,
    header: string,
    onClick: (open: boolean, sectionId: number) => void,
    children: any
}


export const CollapseComponent = (props: CollapseHeaderProps) => {
    return (
        <>
            <Row className="mt-3 mx-1" onClick={() => props.onClick(!props.open, props.sectionId)}
                aria-expanded={props.open}
                style={{
                    cursor: "pointer",
                    backgroundColor: "lightgray",
                    paddingTop: "5px",
                    borderRadius: "5px"
                }}>
                <Col xs={11}>
                    <h5>{props.header}</h5>
                </Col>
                {props.open ?
                    <Col xs={1}>
                        <FontAwesomeIcon
                            icon={faCaretSquareUp} style={{ color: "#1b6ec2" }} />
                    </Col> :
                    <Col xs={1}>
                        <FontAwesomeIcon
                            icon={faCaretSquareDown} style={{ color: "#1b6ec2" }} />
                    </Col>
                }
            </Row>
            <Collapse in={props.open}>
                <Container>
                    {props.children}
                </Container>
            </Collapse>
        </>
    )
}