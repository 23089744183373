import React, { useEffect, useState } from 'react'
import { Spinner, Form, Button, Alert, Modal, ModalBody, Container } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import { NationalResettlementAgencyService } from '../../services/NationalResettlementAgencyService';
import { NationalResettlementUserDTO, NationalResettlementAgencyDTO, NationalResettlementUserClient, CreateNationalResettlementUserDTO } from '../../volunteer-tracking-client';
import { Exception } from '../Exception';
import { NationalResettlementUserService } from '../../services/NationalResettlementUserService';

const client: NationalResettlementUserClient = new NationalResettlementUserClient();

type CreateResettlementUserProps = {
    show: boolean,
    confirmHandler: (event: any, nru: NationalResettlementUserDTO) => void,
    closeHandler: () => void
}

export const CreateNationalResettlementUserModal = (props: CreateResettlementUserProps) => {
    const [createNationalResettlementUserDTO, setCreateNationalResettlementUserDTO] = useState<CreateNationalResettlementUserDTO>({
        "nationalResettlementAgencyId": parseInt(localStorage.getItem("selectedOrganizationId")!)
    });
    const [nationalResettlementAgencies, setNationalResettlementAgencies] = useState<NationalResettlementAgencyDTO[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
    const [showCreateSuccess, setShowCreateSuccess] = useState(false);
    const [showBadRequest, setShowBadRequest] = useState(false);
    const [emailInvalidMessage, setEmailInvalidMessage] = useState("");
    const [isGlobalAdmin, setIsGlobalAdmin] = useState(false)
    const [error, setError] = useState<Error>();
    const [emailNew, setEmailNew] = useState<boolean>(true);

    const app = useAppContext();

    const handleEmailChange = async (event: any) => {
        const user = await NationalResettlementUserService.getByEmail(createNationalResettlementUserDTO!.email);
        if (user == null) {
            setEmailNew(true)
        } else {
            setEmailNew(false)
            handleInputChange({ target: { name: "firstName", value: user.firstName } })
            handleInputChange({ target: { name: "lastName", value: user.lastName } })
        }
    }

    useEffect(() => {
        const getNationalResettlementUser = async () => {
            setIsGlobalAdmin(localStorage.getItem("selectedRole") === 'GlobalAdmin.All' || false)

        }
        const getNationalResettlementAgencies = async () => {
            setNationalResettlementAgencies(await NationalResettlementAgencyService.getAllNationalResettlementAgencies())
        }
        getNationalResettlementUser();
        getNationalResettlementAgencies();
    }, [])

    const hasError = (key: string) => {
        return (validationErrors.indexOf(key) !== -1);
    }

    const handleInputChange = (event: any): void => {
        var key = event.target.name;
        var value = event.target.value;
        setCreateNationalResettlementUserDTO(prevState => ({ ...prevState, [key]: value }));
    }

    const handleAddNationalResettlementAgencyUser = async (event: any) => {
        event?.preventDefault();

        if (!(await hasValidFields()) || !createNationalResettlementUserDTO) {
            return;
        }
        else {
            setIsLoading(true);
            try {
                var nru = await NationalResettlementUserService.createNationalResettlementUser(createNationalResettlementUserDTO)
                const addedAgency = nru.nationalResettlementAgencyNationalResettlementUsers?.find(nra => nra.nationalResettlementAgencyId == createNationalResettlementUserDTO.nationalResettlementAgencyId)
                if (createNationalResettlementUserDTO.email === app.user?.email) {
                    app.addRole(
                        app.user, "NationalOfficeUser.All", createNationalResettlementUserDTO.nationalResettlementAgencyId, addedAgency?.nationalResettlementAgency?.name)
                }
                nru.nationalResettlementAgency = addedAgency?.nationalResettlementAgency
                nru.isActive = true
                setShowCreateSuccess(true);

                props.confirmHandler(event, nru);
            } catch (e: any) {
                if (e.status === 404) {
                    setShowBadRequest(true)
                } else {
                    setError(e)
                }
            } finally {
                setIsLoading(false);
            }

        }
    }

    const setInitialDTO = () => {
        const initialState: CreateNationalResettlementUserDTO = {}
        if (localStorage.getItem("selectedRole")! !== 'Global.Admin') {
            initialState.nationalResettlementAgencyId = parseInt(localStorage.getItem("selectedOrganizationId")!)
        }
        setCreateNationalResettlementUserDTO(initialState)
    }

    const hasValidFields = async (): Promise<boolean> => {
        var validationErrors = [];

        //firstname
        if (!createNationalResettlementUserDTO || !createNationalResettlementUserDTO?.firstName) {
            validationErrors.push("firstname");
        }

        //lastName
        if (!createNationalResettlementUserDTO || !createNationalResettlementUserDTO?.lastName) {
            validationErrors.push("lastname");
        }

        if (!createNationalResettlementUserDTO || !createNationalResettlementUserDTO?.nationalResettlementAgencyId) {
            validationErrors.push("nationalResettlementAgencyId");
        }

        //email
        const expression = /\S+@\S+/;
        const validEmail = (expression.test(String(createNationalResettlementUserDTO?.email).toLowerCase()));

        if (createNationalResettlementUserDTO?.email) {
            const existing = await client.getByEmail(createNationalResettlementUserDTO.email);
            if (existing?.nationalResettlementAgencyNationalResettlementUsers?.some(
                nra => nra.nationalResettlementAgencyId?.toString() === createNationalResettlementUserDTO.nationalResettlementAgencyId)) {
                validationErrors.push("email");
                setEmailInvalidMessage('National resettlement user with that email already exists for the specified agency.');
            }
        }

        if (!validEmail) {
            validationErrors.push("email");
            setEmailInvalidMessage('Please enter a valid email address.');
        }

        setValidationErrors(validationErrors);

        return validationErrors.length === 0;
    }

    if (error) {
        return <Exception message={error.message}></Exception>
    } else {
        return (
            <>
                <Alert show={showCreateSuccess} variant="success" onClose={() => setShowCreateSuccess(false)} dismissible>
                    <Alert.Heading>Success</Alert.Heading>
                    <p>
                        National Resettlement User Created!
                        It may take up to 5 minutes before the account is fully active.
                    </p>
                </Alert>
                <Alert show={showBadRequest} variant="danger" onClose={() => setShowBadRequest(false)} dismissible>
                    <Alert.Heading>Bad Request</Alert.Heading>
                    <p>
                        There was a problem creating the user.  This is mostly likely due to an invalid email address
                    </p>
                </Alert>
                <Modal show={props.show} onHide={props.closeHandler} onShow={() => {
                    setInitialDTO()
                    setEmailNew(true)
                }} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Create National Resettlement User</Modal.Title>
                    </Modal.Header>
                    <ModalBody>
                        {isLoading &&
                            <Container className='mx-auto text-center mt-5'>
                                <Spinner className='mx-auto' animation='border' variant='primary' />
                            </Container>
                        }
                        {(!isLoading) &&
                            <Form onSubmit={handleAddNationalResettlementAgencyUser}>
                                <Form.Group className='mb-3' controlId='email'>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control isInvalid={hasError('email')} placeholder='Email Address' name='email'
                                        value={createNationalResettlementUserDTO?.email ?? ''} onChange={handleInputChange}
                                        onBlur={handleEmailChange} />
                                    <Form.Control.Feedback type="invalid">
                                        {emailInvalidMessage}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className='mb-3' controlId='firstName' >
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control isInvalid={hasError('firstname')} name='firstName' onChange={handleInputChange}
                                        value={createNationalResettlementUserDTO?.firstName ?? ''} readOnly={!emailNew} />
                                    <Form.Control.Feedback type="invalid">
                                        Required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className='mb-3' controlId='lastName'>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control isInvalid={hasError('lastname')} name='lastName' onChange={handleInputChange}
                                        value={createNationalResettlementUserDTO?.lastName ?? ''} readOnly={!emailNew} />
                                    <Form.Control.Feedback type="invalid">
                                        Required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {isGlobalAdmin &&
                                    <Form.Group className='mb-3' controlId='nationalResettlementAgencyId'>
                                        <Form.Label>National Resettlement Agency</Form.Label>
                                        <Form.Select isInvalid={hasError('nationalResettlementAgencyId')} name='nationalResettlementAgencyId' onChange={handleInputChange}>
                                            <option value='0'>Select</option>
                                            {nationalResettlementAgencies.map((opt: NationalResettlementAgencyDTO) => (
                                                <option key={opt.id} value={opt.id}>{opt.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                }
                                <Button variant='secondary' onClick={props.closeHandler}>Cancel</Button>
                                <Button className='m-3' variant='primary' type='submit'>Create User</Button>
                            </Form>
                        }
                    </ModalBody>
                </Modal>
            </>
        )
    };
}
