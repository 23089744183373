import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

export const CasePageWrapper = (props: {
    step: number,
    nextStep: (() => void) | undefined,
    prevStep: (() => void) | undefined,
    submit: (() => void) | undefined,
    validationErrors: Map<string, string[]>,
    canSubmit: boolean,
    totalSteps: number,
    children: any
}) => {
    const isStartingPage = (props.nextStep !== undefined && props.prevStep === undefined);
    const isSubmitPage = (props.submit !== undefined);
    let caseDetailsErrors: number = 0;
    let familyMemberErrors: number = 0;
    let caseSponsorshipErrors: number = 0;
    let sponsorshipErrors: number = 0;
    if (props.validationErrors) {
        caseDetailsErrors = props.validationErrors.get('CaseDetails')?.length ?? 0;
        props.validationErrors.forEach((value: string[], key: string) => {
            if (key.includes('FamilyMember')) {
                familyMemberErrors += value.length;
            }

            if (key.includes('SponsorshipGroupVolunteer')) {
                sponsorshipErrors += value.length;
            }
        });
        caseSponsorshipErrors += props.validationErrors.get('CaseSponsorshipGroup')?.length ?? 0;
        caseSponsorshipErrors += props.validationErrors.get('CaseVolunteer')?.length ?? 0;
        sponsorshipErrors += props.validationErrors.get('SponsorshipGroup')?.length ?? 0;
    }

    return (
        <Container className='border border-light rounded shadow p-3 mb-5 bg-white rounded'>

            <Row className='pb-3 mb-4'>
                <Col className='header-breadcrumb breadcrumb-border-right'>
                    <Row>
                        <Col md={3}><div className={'breadcrumb-circle ' + (props.step == 1 ? 'active active-breadcrumb-circle' : '')} >1
                            {caseDetailsErrors > 0 && <div className="validation-circle">{caseDetailsErrors}</div>}</div></Col>
                        <Col className={'d-none d-lg-block ' + (props.step == 1 ? 'active' : '')}>
                            <div className='breadcrumb-text'>Client Information</div></Col>
                    </Row>
                </Col>
                <Col className='header-breadcrumb breadcrumb-border-right'>
                    <Row>
                        <Col md={3}><div className={'breadcrumb-circle ' + (props.step == 2 ? 'active active-breadcrumb-circle' : '')}>2
                            {familyMemberErrors > 0 && <div className="validation-circle">{familyMemberErrors}</div>}</div></Col>
                        <Col className={'d-none d-lg-block ' + (props.step == 2 ? 'active' : '')}>
                            <div className='breadcrumb-text'>Client Address</div>
                        </Col>
                    </Row>
                </Col>
                <Col className='header-breadcrumb breadcrumb-border-right'>
                    <Row>
                        <Col md={3}><div className={'breadcrumb-circle ' + (props.step == 3 ? 'active active-breadcrumb-circle' : '')}>3
                            {caseSponsorshipErrors > 0 && <div className="validation-circle">{caseSponsorshipErrors}</div>}</div></Col>
                        <Col className={'d-none d-lg-block ' + (props.step == 3 ? 'active' : '')}>
                            <div className='breadcrumb-text'>Volunteer Information</div>
                        </Col>
                    </Row>
                </Col>
                {props.totalSteps === 5 ?
                    <>
                        <Col className='header-breadcrumb breadcrumb-border-right'>
                            <Row>
                                <Col md={3}><div className={'breadcrumb-circle ' + (props.step == 4 ? 'active active-breadcrumb-circle' : '')}>4
                                    {sponsorshipErrors > 0 && <div className="validation-circle">{sponsorshipErrors}</div>}</div></Col>
                                <Col className={'d-none d-lg-block ' + (props.step == 4 ? 'active' : '')}>
                                    <div className='breadcrumb-text'>Sponsorship Information</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='header-breadcrumb'>
                            <Row>
                                <Col md={3}><div className={'breadcrumb-circle ' + (props.step == 5 ? 'active active-breadcrumb-circle' : '')}>5</div></Col>
                                <Col className={'d-none d-lg-block ' + (props.step == 5 ? 'active' : '')}>
                                    <div className='breadcrumb-text'>Review</div>
                                </Col>
                            </Row>
                        </Col>
                    </>
                    :
                    <Col className='header-breadcrumb'>
                        <Row>
                            <Col md={3}><div className={'breadcrumb-circle ' + (props.step == 4 ? 'active active-breadcrumb-circle' : '')}>4</div></Col>
                            <Col className={'d-none d-lg-block ' + (props.step == 4 ? 'active' : '')}>
                                <div className='breadcrumb-text'>Review</div>
                            </Col>
                        </Row>
                    </Col>
                }
            </Row >
            <Row>
                {props.children}
            </Row>
            <Row className='mt-5'>
                {!isStartingPage &&
                    <Col className='d-flex justify-content-end'>
                        <Button className='w-50' onClick={props.prevStep}>Previous</Button>
                    </Col>
                }
                <Col className={isStartingPage ? 'd-flex justify-content-center' : ''}>
                    <Button className={isStartingPage ? 'w-25' : 'w-50'}
                        disabled={isSubmitPage && !props.canSubmit}
                        onClick={isSubmitPage ? props.submit : props.nextStep}>{isSubmitPage ? 'Submit' : 'Next'}
                    </Button>
                </Col>
            </Row>
        </Container >
    );
}
CasePageWrapper.displayName = CasePageWrapper.name;