import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import { VolunteerService } from '../../services/VolunteerService';
import { VolunteerDTO } from '../../volunteer-tracking-client';
import { AddDemographics } from './AddDemographics';
import { Exception } from '../Exception';

type RegistrationComponentProps = {
    volunteer:VolunteerDTO
}
export const RegistrationComponent = (props:RegistrationComponentProps) => {
    const [startRegistration, setStartRegistration] = useState<boolean>(false)
    const [volunteer, setVolunteer] = useState<VolunteerDTO>({})
    const [error, setError] = useState<Error>()

    const app = useAppContext()

    useEffect(() => {
        const getVolunteer = async () => {
            setVolunteer(await VolunteerService.getVolunteerByPrincipalId(app.user?.uniqueId))
        }
        try{
            getVolunteer()
        }catch(ex:any){
            setError(ex)
        }
    },[])

    const handleRegistration = async (event:any) => {
        setStartRegistration(true)
    }

    return (
        startRegistration ?
            !error?
            <AddDemographics volunteer={volunteer}/>:
            <Exception message={error.message}></Exception>:
            <Container>
                <Card>
                    <Card.Header>
                        <Row> 
                            <Col md={10}><h2>Community Sponsorship Demographic Information</h2></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>
                            <Row className='text-center mb-5'>
                                <Col>
                                    New User Registration
                                </Col>
                            </Row>
                        </Card.Title>
                        <Row>
                            <Col>
                                Thank you for using the Refugee Welcome Collective app! 
                                Knowing information about our volunteers is very helpful to provide metrics to our partners and engage most effectively with our clients and our community. 
                                Your answers to the following questions are optional but would be appreciated! Thank you. 
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row className='text-center mt-5'>
                        <Col>
                            <Button onClick={handleRegistration}>Register</Button>
                        </Col>
                        </Row>
                    </Card.Footer>
                </Card>        
        </Container >
    )
}
