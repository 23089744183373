import { LocalOfficeCaseActivityCheckboxClient, UpdateLocalOfficeCaseActivitiesDTO } from '../volunteer-tracking-client';

const client: LocalOfficeCaseActivityCheckboxClient = new LocalOfficeCaseActivityCheckboxClient();

export const LocalOfficeCaseActivityCheckboxService = {
    getCaseActivitiesForLocalOffice: async (localOfficeId: number | undefined) => {
        return await client.getCaseActivitiesForLocalOffice(localOfficeId)
    },
    updateCaseActivitiesForLocalOffice: async (localOfficeCaseActivities: UpdateLocalOfficeCaseActivitiesDTO) => {
        return await client.updateCaseActivitiesForLocalOffice(localOfficeCaseActivities)
    }
}