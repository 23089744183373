import React from "react";
import { CaseActivitySection2ReviewProps } from "../../LocalOffice/LocalOfficeCaseActivity/CaseActivitySection2ReviewProps";
import { CaseActivitySection2Review } from "../../Case/Activity/CaseActivitySection2Review";

export const RPCaseActivitySection2Review = (props: CaseActivitySection2ReviewProps

) => {
    const caseActivityTasks = props.caseActivityTasks;

    return (
        caseActivityTasks && caseActivityTasks.length > 0 ?
            <>
                <CaseActivitySection2Review
                    caseActivityTasks={caseActivityTasks!}
                    header="Pre-Arrival"
                    section="PreArrival" />
                <CaseActivitySection2Review
                    caseActivityTasks={caseActivityTasks!}
                    header="Arrival"
                    section="Arrival" />
                <CaseActivitySection2Review
                    caseActivityTasks={caseActivityTasks!}
                    header="Service"
                    section="PostArrival" />
                <CaseActivitySection2Review
                    caseActivityTasks={caseActivityTasks!}
                    header="Additional wrap-around services"
                    section="AdditionalServices" />
            </>
            :
            <div className="text-danger" >
                You must select at least one activity
            </div>
    )

}
