import React from "react"
import { Container } from "react-bootstrap"
import { LocalOfficeCases } from "./LocalOfficeCases";

export const LocalOfficeHome = () => {
    return (
        <Container>
            <LocalOfficeCases></LocalOfficeCases>
        </Container>
    );
}

LocalOfficeHome.displayName = LocalOfficeHome.name;