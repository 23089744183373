import React, { useEffect, useState } from "react";
import { Container, Row, Spinner, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CaseActivityClient, CaseClient, CreateCaseActivityDTO, CreateCaseDTO, LocalOfficeDTO } from "../../../volunteer-tracking-client";
import { CaseActivitySection2Review } from "../../Case/Activity/CaseActivitySection2Review";
import { CaseActivitySection3 } from "../../Case/Activity/CaseActivitySection3";
import { CaseActivitySection4 } from "../../Case/Activity/CaseActivitySection4";
import { CollapseComponent } from "../../Common/CollapseComponent";
import { LocalOfficeCaseActivitySection1 } from "./LocalOfficeCaseActivitySection1";
import { WelcomeCoreCaseActivitySection2Review } from "../../Volunteer/VolunteerCaseActivity/WelcomeCoreCaseActivitySection2Review";
import { RPCaseActivitySection2Review } from "../../Volunteer/VolunteerCaseActivity/RPCaseActivitySection2Review";
import { LocalOfficeService } from "../../../services/LocalOfficeService";
import { Exception } from "../../Exception";

const caseActivityClient: CaseActivityClient = new CaseActivityClient();
const caseClient: CaseClient = new CaseClient();

export const LocalOfficeCaseActivitySummary = () => {
    const { caseActivityId } = useParams();
    const [caseActivity, setCaseActivity] = useState<CreateCaseActivityDTO>();
    const [caseDTO, setCase] = useState<CreateCaseDTO>();
    const [section1Open, setSection1Open] = useState<boolean>(true);
    const [section2Open, setSection2Open] = useState<boolean>(false);
    const [section3Open, setSection3Open] = useState<boolean>(false);
    const [section4Open, setSection4Open] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [localOffice, setLocalOffice] = useState<LocalOfficeDTO>()
    const [error, setError] = useState<Error>()

    useEffect(() => {

        const getCaseActivity = async () => {
            if (caseActivityId) {
                setIsLoading(true);
                const caseActivity = await caseActivityClient.getCaseActivityForEdit(Number.parseInt(caseActivityId));
                const c = await caseClient.getCase(caseActivity.caseId);
                setCaseActivity(caseActivity);
                setCase(c);
                setIsLoading(false);
            }
        };

        const getLocalOffice = async () => {
            const localOffice = await LocalOfficeService.getLocalOffice(parseInt(localStorage.getItem("selectedOrganizationId")!))
            setLocalOffice(localOffice)
        }
        try{
            getLocalOffice()
            if (caseActivityId) {
                getCaseActivity();
            }
        }catch(ex:any){
            setError(ex)
        }
    }, []);


    const handleCollapseHeaderClick = (open: boolean, sectionId: number) => {
        switch (sectionId) {
            case 1:
                setSection1Open(open);
                return;
            case 2:
                setSection2Open(open);
                return;
            case 3:
                setSection3Open(open);
                return;
            case 4:
                setSection4Open(open);
                return;
            default:
                setSection1Open(true);
                setSection2Open(false);
                setSection3Open(false);
                setSection4Open(false);
                return;
        }
    }

    const handleChanges = (fn: () => any): void => {
        setCaseActivity(prevState => ({ ...prevState, ...fn() }));
    }

    return (
        !error?
        <Container className='mb-3'>
            {isLoading ?
                <Stack className='mx-auto mt-5'>
                    <Spinner className="mx-auto" animation='border' variant='primary' />
                </Stack>
                :
                <>
                    {caseActivity && caseDTO ?
                        <>
                            <CollapseComponent open={section1Open} header={'Client Information'} sectionId={1}
                                onClick={handleCollapseHeaderClick}>
                                <LocalOfficeCaseActivitySection1
                                    onChangeHandler={handleChanges}
                                    caseActivity={caseActivity}
                                    caseDTO={caseDTO} />
                            </CollapseComponent>
                            <CollapseComponent open={section2Open} header={'Service Activity'} sectionId={2}
                                onClick={handleCollapseHeaderClick}>
                                {localOffice?.isWelcomeCorp ?
                                    <WelcomeCoreCaseActivitySection2Review
                                        caseActivityTasks={caseActivity.caseActivityTasks!}
                                    /> : <RPCaseActivitySection2Review
                                        caseActivityTasks={caseActivity.caseActivityTasks!}
                                    />
                                }
                            </CollapseComponent>
                            <CollapseComponent open={section3Open} header={'Case Notes'} sectionId={3}
                                onClick={handleCollapseHeaderClick}>
                                {caseActivity.caseActivityTasks && caseActivity.caseActivityTasks.length > 0 ?
                                    <CaseActivitySection3
                                        onChangeHandler={handleChanges}
                                        caseActivity={caseActivity}
                                        review={true} />
                                    :
                                    <div className="text-danger" >
                                        You must select at least one activity
                                    </div>
                                }
                            </CollapseComponent>
                            <CollapseComponent open={section4Open} header={'Donations'} sectionId={4}
                                onClick={handleCollapseHeaderClick}>
                                <CaseActivitySection4
                                    caseActivity={caseActivity}
                                    onChangeHandler={() => { }}
                                    review={true} />
                            </CollapseComponent>
                        </>
                        :
                        <Row className='mt-3'>
                            <p>Client has no associated activity</p>
                        </Row>
                    }
                </>
            }
        </Container>:<Exception message={error.message}></Exception>
    )
}