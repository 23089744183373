import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { InputService } from "../../services/InputService";
import { GenderClient, GenderDTO, StateClient, StateDTO } from "../../volunteer-tracking-client";
import { RegistrationComponentProps } from "./RegisrationComponentProps";
import { Exception } from "../Exception";


export const DemographicsSection1 = (props: RegistrationComponentProps) => {
    const [states, setStates] = useState<StateDTO[]>([])
    const [genders, setGenders] = useState<GenderDTO[]>([])
    const [error, setError] = useState<Error>();

    const { volunteer, onChangeHandler } = props
    const stateClient = new StateClient()
    const genderClient = new GenderClient()
    
    const handleChange = (event:any) => {
        onChangeHandler!(() => { return { ...volunteer, ...{ [event.target.name]: event.target.value } } })
    }

    useEffect(() => {
        const getStates = async () => {
            setStates(await stateClient.getAllStates())
        }

        const getGenders = async () => {
            setGenders(await genderClient.getAll())
        }
        try{
            getStates()
            getGenders()
        }catch(ex:any){
            setError(error)
        }

    }, [])
    return (
            !error?
            <Card>
                <Card.Header>
                    <Row> 
                        <Col md={10}><h2>Contact Information</h2></Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        isInvalid={!volunteer.firstName || volunteer.firstName?.trim() == ''}
                                        value={volunteer.firstName || ''}
                                        name="firstName"
                                        onChange={handleChange}
                                        placeholder="First Name"
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        Required
                                    </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        isInvalid={!volunteer.lastName || volunteer.lastName?.trim() == ''}
                                        value={volunteer.lastName || ''}
                                        name="lastName"
                                        onChange={handleChange}
                                        placeholder="Last Name"
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        Required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    isInvalid={!volunteer.addressLine1 || volunteer.addressLine1?.trim() == ''}
                                    value={volunteer.addressLine1 || ''}
                                    name="addressLine1"
                                    onChange={handleChange}
                                    placeholder="Address Line 1"
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    value={volunteer.addressLine2 || ''}
                                    name="addressLine2"
                                    onChange={handleChange}
                                    placeholder="Address Line 2"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    isInvalid={!volunteer.city || volunteer.city?.trim() == ''}
                                    value={volunteer.city || ''}
                                    name="city"
                                    onChange={handleChange}
                                    placeholder="City"
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Control
                                isInvalid={!volunteer.stateId}
                                name='stateId'
                                as='select'
                                value={volunteer.stateId || ' '}
                                onChange={handleChange}
                            >
                                <option key='' value=' ' disabled hidden>State/Territory</option>
                            {states.map((s, index) => {
                                return <option key={index} value={s.id}>{s.name}</option>
                            })
                            }
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Required
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    isInvalid={!volunteer.postalCode || volunteer.postalCode?.trim() == ''}
                                    value={volunteer.postalCode || ''}
                                    name="postalCode"
                                    onChange={handleChange}
                                    placeholder="Zip Code"
                                    onKeyDown={InputService.HandleZipCodeInput}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control
                                    isInvalid={!volunteer.cellPhone || volunteer.cellPhone?.trim() == ''}
                                    value={InputService.FormatPhoneNumber(volunteer.cellPhone) || ''}
                                    name="cellPhone"
                                    onChange={handleChange}
                                    placeholder="Contact Number"
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <div>{volunteer?.email}</div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className='mb-3' >
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control onChange={handleChange}
                                    isInvalid={!volunteer?.dateOfBirth || new Date(volunteer?.dateOfBirth).getFullYear() > 9999}
                                    required
                                    type='date'
                                    placeholder='MM/DD/yyyy'
                                    name='dateOfBirth'
                                    value={volunteer?.dateOfBirth?.toLocaleString().split('T')[0] || ''} />
                                <Form.Control.Feedback type='invalid'>
                                    Invalid Date
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control
                                    isInvalid={!volunteer.genderId}
                                    name='genderId'
                                    as='select'
                                    value={volunteer.genderId || ' '}
                                    onChange={handleChange}
                                >
                                    <option key='' value=' ' disabled hidden>Gender</option>
                                        {genders.map((g, index) => {
                                            return <option key={index} value={g.id}>{g.value}</option>
                                        })}
                                </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                        </Col>
                    </Row>
                    </Card.Body>
                </Card>:<Exception message={error.message}></Exception>        
    )
}