import { VolunteerDTO } from "../../volunteer-tracking-client"


export class DemographicsValidator {
    validations: { [page: number]: number } = {}

    validateSection1(volunteer: VolunteerDTO): number {
        var result = 0
        if (!volunteer.addressLine1 || volunteer.addressLine1.trim()=='') {
            result+=1
        }
        if (!volunteer.cellPhone || volunteer.cellPhone.trim()=='') {
            result+=1
        }

        if (!volunteer.city || volunteer.city.trim()=='') {
            result+=1
        }

        if (!volunteer.dateOfBirth || new Date(volunteer?.dateOfBirth).getFullYear() > 9999) {
            result+=1
        }
        if (!volunteer.email || volunteer.email.trim()=='') {
            result+=1
        }

        if (!volunteer.firstName || volunteer.firstName.trim()=='') {
            result+=1
        }

        if (!volunteer.genderId) {
            result+=1
        }

        if (!volunteer.lastName || volunteer.lastName.trim()=='') {
            result+=1
        }

        if (!volunteer.postalCode || volunteer.postalCode.trim()=='') {
            result+=1
        }

        if (!volunteer.stateId) {
            result+=1
        }
        return result
    }

    validateSection2(volunteer: VolunteerDTO) {
        var result = 0
        if (!volunteer.educationLevelId) {
            result++
        }
        if (!volunteer.employmentTypeId) {
            result++
        }
         if (!volunteer.primaryLanguageId) {
            result++
        }
        if (volunteer.employmentTypeId == 4 && (!volunteer.employmentTypeNotes || volunteer.employmentTypeNotes.trim() == '')) {
            result++
        }

        if (!volunteer.engagementCountId) {
            result++
        }

        if (!volunteer.ethnicityId) {
            result++
        }

        if (!volunteer.immigrationStoryId) {
            result++
        }

        if (volunteer.immigrationStoryId == 2 && (!volunteer.immigrationStoryCountry || volunteer.immigrationStoryCountry.trim() == '')) {
            result++
        }
        return result
    }




    validate = (volunteer: VolunteerDTO) => {
        const section1Count = this.validateSection1(volunteer)
        if (section1Count > 0) {
            this.validations[1] = section1Count
        }

        const section2Count = this.validateSection2(volunteer)
        if (section2Count > 0) {
            this.validations[2] = section2Count
        }

        return this.validations
    }
}