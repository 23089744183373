import React, { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { CaseDTO, CaseClient, MouBlobClient, LocalOfficeClient } from '../../volunteer-tracking-client';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import LocalOfficeCasesActionRenderer from './LocalOfficeCasesActionRenderer';
import CaseStatusRenderer from './CaseStatusRenderer';
import { Exception } from '../Exception';

const localOfficeClient: LocalOfficeClient = new LocalOfficeClient();
const caseClient: CaseClient = new CaseClient()

export const LocalOfficeCases = () => {
    const [cases, setCases] = useState<CaseDTO[]>([]);
    const [error, setError] = useState<Error>();

    const navigate = useNavigate();
    const blobClient = new MouBlobClient();

    useEffect(() => {
        const getLocalOfficeActiveCases = async () => {
            const localOffice = await localOfficeClient.getLocalOffice(parseInt(localStorage.getItem("selectedOrganizationId")!));
            if (localOffice) {
                await caseClient.getLocalOfficeCases(localOffice.id)
                    .then((resp) => {
                        const cases: CaseDTO[] | undefined = resp.map(c => {
                            c.familyMembers = c.familyMembers?.filter(f => f.isPrimary)
                            return c
                        }).sort((c1: CaseDTO, c2: CaseDTO) => {
                            if (!c1.status) {
                                return 1
                            }
                            if (!c2.status) {
                                return -1
                            }
                            if (c1.status < c2.status) {
                                return -1
                            }
                            if (c2.status < c1.status) {
                                return -1
                            }
                            return 0
                        })
                        setCases(cases);
                    });
            }
        };
        try{
            getLocalOfficeActiveCases();
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    const onGridReady = (params: any) => {
        params.columnApi.autoSizeColumns(['number', 'action']);
        let model = params.api.getFilterModel();
        model = {
            status: {
                filterType: 'text',
                type: 'contains',
                filter: 'Active'
            }
        };
        params.api.setFilterModel(model);
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
    };

    const getRowNodeId = (data: any) => data.id;

    const handleEditCase = (data: CaseDTO) => {
        navigate("/edit-case/" + data.id)
    }

    const handleViewCaseActivities = (data: any) => {
        navigate("/localoffice-case-activities/" + data.id)
    }

    const handleDownloadMou = async (data: any) => {
        await blobClient.getBlob(data.caseSponsorshipGroups[0].mouFilePath).then(resp => {
            var blob = new Blob([resp.data], { type: resp.data.type });
            var filename = data.caseSponsorshipGroups[0].mouFilePath.substring(data.caseSponsorshipGroups[0].mouFilePath.lastIndexOf('/') + 1);
            var csvURL = window.URL.createObjectURL(blob);
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', filename);
            tempLink.click();
        });
    }

    const onCellEditingStarted = (params: any) => {
        params.api.refreshCells({
            columns: ["action"],
            rowNodes: [params.node],
            force: true
        });
    }

    const onCellEditingStopped = (params: any) => {
        if (params.newValue !== params.oldValue) {
            const updatedCases = cases.map(c => {
                if (c.id === params.data.id) {
                    // @ts-ignore
                    c['isEditing'] = false;
                }
                return c;
            });
            setCases(updatedCases);
        }
        params.api.refreshCells({
            columns: ["action"],
            rowNodes: [params.node],
            force: true
        });
    }

    const statusSelectChange = async (event: any) => {
        try {
            await caseClient.updateCase(event.data);
        } catch (error: any) {
            setError(error);
        }
    }

    return (
        !error ?
            <Container>
                <h3>Clients</h3>
                <a href='/create-new-case'>
                    <Button className='mb-3'>
                        Add New Client
                    </Button>
                </a>
                <div className="ag-theme-alpine" style={{ height: 700 }}>
                    <AgGridReact
                        getRowNodeId={getRowNodeId}
                        onGridReady={onGridReady}
                        onCellEditingStarted={onCellEditingStarted}
                        onCellEditingStopped={onCellEditingStopped}
                        frameworkComponents={{
                            localOfficeCasesActionRenderer: LocalOfficeCasesActionRenderer,
                            caseStatusRenderer: CaseStatusRenderer
                        }}
                        rowData={cases}
                        defaultColDef={defaultColDef}
                        rowSelection={'none'}
                        suppressRowClickSelection={true}
                        suppressCellSelection={true}
                        singleClickEdit={true}
                        stopEditingWhenCellsLoseFocus={false}
                        pagination={true}
                        paginationAutoPageSize={true}
                        suppressRowTransform={true}
                        rowStyle={{ overflow: 'visible' }}
                    >
                        <AgGridColumn headerName="Client #" field="number"></AgGridColumn>
                        <AgGridColumn headerName='Status' colId='status' field='status.name' editable={true}
                            cellRenderer='caseStatusRenderer'
                            cellEditor='agSelectCellEditor'
                            cellEditorParams={{
                                cellRenderer: 'caseStatusRenderer',
                                values: ['Active', 'Closed'],
                            }}
                            filterParams={
                                {
                                    newRowsAction: 'keep'
                                }
                            }
                            onCellValueChanged={statusSelectChange}
                        ></AgGridColumn>
                        <AgGridColumn headerName="Arrival Date" field="clientDOA" cellRenderer={(data) => {
                            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
                        }}></AgGridColumn>
                        <AgGridColumn headerName="Primary Last Name" valueGetter={params => {
                            return params.data.familyMembers[0].lastName
                        }}></AgGridColumn>
                        <AgGridColumn headerName="Primary First Name" valueGetter={params => {
                            return params.data.familyMembers[0].firstName
                        }}></AgGridColumn>
                        <AgGridColumn headerName="City" valueGetter={params => {
                            return params.data.familyMembers[0].city
                        }}></AgGridColumn>
                        <AgGridColumn headerName="State/Territory" valueGetter={params => {
                            return params.data.familyMembers[0].state.name
                        }}></AgGridColumn>
                        <AgGridColumn headerName="Zip Code" valueGetter={params => {
                            return params.data.familyMembers[0].zipCode
                        }}></AgGridColumn>
                        <AgGridColumn headerName="Contact Number" valueGetter={params => {
                            return params.data.familyMembers[0].contactNumber
                        }}></AgGridColumn>
                        <AgGridColumn headerName="Type Paired" field="sponsorshipGroup.type.type"></AgGridColumn>
                        <AgGridColumn cellStyle={{ overflow: 'visible' }} cellRenderer="localOfficeCasesActionRenderer" pinned='right' headerName='Actions' colId='action' cellRendererParams={{
                            editCase: (data: CaseDTO) => { handleEditCase(data) },
                            viewCaseActivities: (data: CaseDTO) => { handleViewCaseActivities(data) },
                            downloadMou: (data: CaseDTO) => { handleDownloadMou(data) }
                        }}></AgGridColumn>
                    </AgGridReact>
                </div>
            </Container> :
            <>
                <Exception message={error.message}></Exception>
            </>
    );
}
