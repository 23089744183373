import { CreateCaseActivityDTO } from "../../../volunteer-tracking-client";

export class CaseActivityValidator {
    validations: { [page: number]: number } = {}

    validateSection1(caseActivity: CreateCaseActivityDTO): number {
        var result = 0
        if (!caseActivity.caseId || caseActivity.caseId == 0) {
            result = 1
        }
        return result
    }

    validateSection2(caseActivity: CreateCaseActivityDTO) {
        var result = 0
        if (!caseActivity.caseActivityTasks || caseActivity.caseActivityTasks.length == 0) {
            result = 1
        }
        return result
    }

    validateSection3(caseActivity: CreateCaseActivityDTO) {
        const result = caseActivity.caseActivityTasks?.reduce((a, b) => {
            var taskErrors = 0
            if (b.interpreterUsed && b.interpreterType === undefined) {
                taskErrors++
            }
            if (!b.activityDate || new Date(b.activityDate).getFullYear() > 9999) {
                taskErrors++;
            }
            if (b.hoursSpent == undefined) {
                taskErrors++;
            } else {
                if(b.hoursSpent == 0 && b.minutesSpent == 0) {
                    taskErrors++
                }
            }
            if (b.minutesSpent == undefined) {
                taskErrors++;
            } else {
                if(b.minutesSpent == 0 && b.hoursSpent == 0) {
                    taskErrors++
                }
                if(b.minutesSpent > 59) {
                    taskErrors++
                }
            }
            if (b.inKindDonationMade && (!b.inKindDonations || b.inKindDonations.length == 0)) {
                taskErrors++
            }

            taskErrors += b.inKindDonations?.reduce((a, b) => {
                var inKindErrors = 0
                if (!b.amount || b.amount.toString() == '') {
                    inKindErrors++;
                }
                if (!b.name || b.name.trim() == '') {
                    inKindErrors++;
                }
                return a + inKindErrors
            }, 0) || 0

            return a + taskErrors
        }, 0)
        return result || 0




    }

    validateSection4(caseActivity: CreateCaseActivityDTO) {
        var result = 0



        if (caseActivity.financialDontationMade && (!caseActivity.financialDonationAmount || caseActivity.financialDonationAmount.toString() == '')) {
            result++
        }

        if (caseActivity.financialDontationMade && (!caseActivity.financialDonationPurpose || caseActivity.financialDonationPurpose.trim() == '')) {
            result++
        }

        return result
    }


    validate = (caseActivity: CreateCaseActivityDTO) => {
        const section1Count = this.validateSection1(caseActivity)
        if (section1Count > 0) {
            this.validations[1] = section1Count
        }

        const section2Count = this.validateSection2(caseActivity)
        if (section2Count > 0) {
            this.validations[2] = section2Count
        }

        const section3Count = this.validateSection3(caseActivity)
        if (section3Count > 0) {
            this.validations[3] = section3Count
        }

        const section4Count = this.validateSection4(caseActivity)
        if (section4Count > 0) {
            this.validations[4] = section4Count
        }

        return this.validations
    }
}