import React, { useEffect, useState } from "react"
import { msalInstance } from ".."
import { useAppContext } from "../AppContext"

type ErrorProps = {
    message:string
}


export const Exception = (error:ErrorProps) => {
    const app = useAppContext()
    const [loaded, setLoaded] = useState<boolean>(false)
    useEffect(() => {
        const handleException = () =>{
            if (error.message === "Unauthorized" || error.message.includes("no_tokens_found")){
                const account = msalInstance.getAccountByHomeId(app.user?.homeAccountId!)
                localStorage.removeItem("selectedRole")
                localStorage.removeItem("selectedOrganizationId")
                localStorage.removeItem("user")

                msalInstance.logoutRedirect({account:account,
                    postLogoutRedirectUri:"/session-expired"})
            }else{
                setLoaded(true)
            }
        }
        handleException()
    })

    return(
        !loaded?
        <></>:
        <>
            <h2>Something went wrong while processing the request</h2>
            <h3>Contact the site administrator to report the error</h3>
            <p>error message: {error.message}</p>
        </>
    )
}