import React, { useState } from 'react';
import './CaseSponsorshipGroup.css';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { FormattingService } from '../../../services/FormattingService';
import { InputService } from '../../../services/InputService';
import { CaseSponsorshipGroupDTO, CaseVolunteerDTO, CreateCaseDTO, LocalOfficeDTO, MouBlobClient, NationalResettlementAgencyDTO, SponsorshipGroupTypeDTO, VolunteerDTO } from '../../../volunteer-tracking-client';
import { CaseSponsorshipGroupMatched } from './CaseSponsorshipGroupMatched';
import { CreateCaseValidationService } from '../../../services/CreateCaseValidationService';
import { CreateVolunteer } from '../../Volunteer/CreateVolunteer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Exception } from '../../Exception';

export const CaseSponsorshipGroup = (props: {
    handleChange: (key: any, value: any) => void,
    createCaseDTO: CreateCaseDTO,
    natRAList: NationalResettlementAgencyDTO[],
    localOfficeList: LocalOfficeDTO[],
    localOffice: LocalOfficeDTO | undefined,
    sponsorshipGroupTypes: SponsorshipGroupTypeDTO[]
}) => {
    const [mou, setMou] = useState<any>([])
    const [fileUploaded, setFileUploaded] = useState<boolean>(false)
    const [error, setError] = useState<Error>()

    const validationPageName = 'CaseSponsorshipGroup';
    const caseSponsorshipGroup = props.createCaseDTO.caseSponsorshipGroups ? props.createCaseDTO.caseSponsorshipGroups[0] : undefined;
    const isMatched = props.createCaseDTO.matchedWithCommunitySponsorship;
    const isCommunitySponsorship = caseSponsorshipGroup?.pairedWithCommunitySponsorship;
    const isSupportGroup = caseSponsorshipGroup?.typeID === props.sponsorshipGroupTypes.find(x => x.type === 'Support Team')?.id;
    const isCoSponsorship = caseSponsorshipGroup?.typeID === props.sponsorshipGroupTypes.find(x => x.type === 'CoSponsorship')?.id;
    const isPrivateSponsorship = caseSponsorshipGroup?.typeID === props.sponsorshipGroupTypes.find(x => x.type === 'Private Sponsorship')?.id;
    const isIndividual = caseSponsorshipGroup?.typeID === props.sponsorshipGroupTypes.find(x => x.type === 'Individual Volunteer')?.id;

    const blobClient = new MouBlobClient()

    const handleFileSelect = (event: any) => {
        const file = {
            ...event.target.files[0], ...{
                data: event.target.files[0],
                fileName: event.target.files[0].name
            }
        }
        setMou(file)
    }

    const uploadMou = async (event: any) => {
        try{
            const response = await blobClient.uploadBlob(props.createCaseDTO.number, mou)
            setFileUploaded(true)
            if (props.createCaseDTO.caseSponsorshipGroups) {
                props.createCaseDTO.caseSponsorshipGroups[0].mouFilePath = response.path
                props.createCaseDTO.caseSponsorshipGroups[0].mouFilename = response.fileName
            }

            props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        }catch(ex:any){
            setError(error)
        }
    }

    const handleInputChange = (event: any) => {
        let key: keyof CaseSponsorshipGroupDTO = event.target.name;
        let value = event.target.value;

        if (key === 'requiredCashContribution') {
            value = value.replace(/\D/g, '');
        }

        if (props.createCaseDTO.caseSponsorshipGroups) {
            props.createCaseDTO.caseSponsorshipGroups[0][key] = value;
        }

        props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
    }

    const handleInKindContributionsNAChange = (event: any) => {
        const value = event.target.value === 'true';

        if (props.createCaseDTO.caseSponsorshipGroups) {
            if (value) {
                props.createCaseDTO.caseSponsorshipGroups[0]['requiredInKindContributions'] = undefined;
            }
            props.createCaseDTO.caseSponsorshipGroups[0].requiredInKindContributionsNA = value;
            props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        }
    }

    const handleRequiredCashContributionsNAChange = (event: any) => {
        const value = event.target.checked;

        if (props.createCaseDTO.caseSponsorshipGroups) {
            if (value) {
                props.createCaseDTO.caseSponsorshipGroups[0]['requiredCashContribution'] = undefined;
            }
            props.createCaseDTO.caseSponsorshipGroups[0].requiredCashContributionNA = value;
            props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        }
    }

    const handleCheckBoxChange = (event: any) => {
        let key: keyof CaseSponsorshipGroupDTO = event.target.name;
        let value = event.target.checked;

        if (props.createCaseDTO.caseSponsorshipGroups) {
            if (key.endsWith('No')) {
                value = !value;
            }

            if (key.includes('signedMoU')) {
                key = 'signedMoU';
            } else if (key.includes('allCoreMembersTrained')) {
                key = 'allCoreMembersTrained';
            } else if (key.includes('allCoreMembersProvidedBackgroundChecks')) {
                key = 'allCoreMembersProvidedBackgroundChecks';
            }

            props.createCaseDTO.caseSponsorshipGroups[0][key] = value;
        }

        props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
    }

    const hasValidationError = (page: string, field: string, value: any): boolean => {
        let hasError = false;
        if (caseSponsorshipGroup)
            hasError = CreateCaseValidationService.caseSponsorshipGroupHasError(caseSponsorshipGroup, page, field, value);
        return hasError;
    }

    const addCaseVolunteer = () => {
        const newVolunteer = { firstName: '', email: '', lastName: '' } as VolunteerDTO;
        const newCaseVolunteer = { volunteer: newVolunteer } as CaseVolunteerDTO;

        let existingVolunteers = [...props.createCaseDTO.caseVolunteers ?? []];
        existingVolunteers.push(newCaseVolunteer);
        props.handleChange('caseVolunteers', existingVolunteers);
    }

    const handleRemoveVolunteer = (index: number) => {
        let existingVolunteers = [...props.createCaseDTO.caseVolunteers ?? []];
        if (existingVolunteers[index].volunteerID === undefined) {
            existingVolunteers.splice(index, 1);
            props.handleChange('caseVolunteers', existingVolunteers);
        } else {
            existingVolunteers[index].isDeleted = true;
            props.handleChange('caseVolunteers', existingVolunteers);
        }
    }

    return (
        !error?
        <Container>
            <Form>
                <CaseSponsorshipGroupMatched handleChange={props.handleChange}
                    createCaseDTO={props.createCaseDTO}
                    natRAList={props.natRAList}
                    localOfficeList={props.localOfficeList}
                    sponsorshipGroupTypes={props.sponsorshipGroupTypes}
                    isMatched={isMatched}
                    isCommunitySponsorship={isCommunitySponsorship}
                    localOffice={props.localOffice} />
                {isMatched && isCommunitySponsorship !== undefined && (isSupportGroup || isCoSponsorship || isIndividual || isPrivateSponsorship) &&
                    <>
                        {props.createCaseDTO.caseSponsorshipGroups && (isCommunitySponsorship || isCoSponsorship) &&
                            <>
                                <Row className='mb-3'>
                                    <Col>
                                        <Form.Group className='mb-3' controlId='signedMoU'>
                                            <Form.Label>Has the group signed an MOU?</Form.Label>
                                            {fileUploaded &&
                                                <Col md={8}>
                                                    <span className='text-success'>{props.createCaseDTO.caseSponsorshipGroups[0].mouFilename} uploaded successfully</span>
                                                </Col>
                                            }
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        className='signedmou'
                                                        type='radio'
                                                        label='Yes'
                                                        name='signedMoU'
                                                        checked={props.createCaseDTO.caseSponsorshipGroups[0].signedMoU !== undefined &&
                                                            props.createCaseDTO.caseSponsorshipGroups[0].signedMoU ? props.createCaseDTO.caseSponsorshipGroups[0].signedMoU : false}
                                                        onChange={handleCheckBoxChange}
                                                        isInvalid={hasValidationError(validationPageName, 'signedMoU', props.createCaseDTO.caseSponsorshipGroups[0].signedMoU)}
                                                    />
                                                    <Form.Check
                                                        type='radio'
                                                        label='No'
                                                        name='signedMoUNo'
                                                        checked={props.createCaseDTO.caseSponsorshipGroups[0].signedMoU !== undefined &&
                                                            !props.createCaseDTO.caseSponsorshipGroups[0].signedMoU ? !props.createCaseDTO.caseSponsorshipGroups[0].signedMoU : false}
                                                        onChange={handleCheckBoxChange}
                                                    />
                                                    {caseSponsorshipGroup && !isPrivateSponsorship && !isSupportGroup && !caseSponsorshipGroup.signedMoU &&
                                                        <p className='warning'>
                                                            Co-sponsors must have a signed MOU before starting case activity.
                                                        </p>
                                                    }
                                                </Col>
                                                {caseSponsorshipGroup && (isCommunitySponsorship || isCoSponsorship) && caseSponsorshipGroup.signedMoU &&
                                                    <>
                                                        <Col>
                                                            <Form.Control type="file"
                                                                onChange={handleFileSelect} />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Button onClick={uploadMou}>Upload</Button>
                                                        </Col>
                                                    </>
                                                }
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col md={2}>
                                            <Form.Label>MOU Filename</Form.Label>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label>{props.createCaseDTO.caseSponsorshipGroups[0].mouFilename}</Form.Label>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </>
                        }
                        {props.createCaseDTO.caseSponsorshipGroups &&
                            <>
                                <Row>
                                    <Col sm={6}>
                                        <p className='form-label'>How many core services is the {isIndividual ? "volunteer" : "group"} responsible for delivering?</p>
                                    </Col>
                                    <Col sm={6}>
                                        <p className='form-label'>What is the {isIndividual ? "volunteer's" : "group's"} required cash contribution?</p>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col sm={6}>
                                        <Form.Group className='mb-3' controlId='cosponsorshipDeliverables'>
                                            <Form.Control isInvalid={hasValidationError(validationPageName, 'coreServices', props.createCaseDTO.caseSponsorshipGroups[0].coreServices)}
                                                type='number'
                                                name='coreServices'
                                                min={0}
                                                placeholder='Number of core services'
                                                value={props.createCaseDTO.caseSponsorshipGroups[0].coreServices ? props.createCaseDTO.caseSponsorshipGroups[0].coreServices : ''}
                                                onChange={handleInputChange}
                                                onKeyDown={InputService.HandleNumberInput} />
                                            <Form.Control.Feedback type='invalid'>
                                                {(!isCommunitySponsorship || (isCommunitySponsorship && ((isSupportGroup !== undefined && isSupportGroup) || (isPrivateSponsorship !== undefined && isPrivateSponsorship)))) &&
                                                    <p>Must be 0 or more.</p>
                                                }
                                                {isCommunitySponsorship && !isSupportGroup && !isPrivateSponsorship &&
                                                    <p>Must be 8 or more</p>
                                                }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3' controlId='cosponsorshipMinCash'>
                                            <Form.Control isInvalid={hasValidationError(validationPageName, 'requiredCashContribution', props.createCaseDTO.caseSponsorshipGroups[0].requiredCashContribution)}
                                                name='requiredCashContribution'
                                                placeholder='Min.'
                                                disabled={props.createCaseDTO.caseSponsorshipGroups[0].requiredCashContributionNA}
                                                value={props.createCaseDTO.caseSponsorshipGroups[0].requiredCashContribution ? FormattingService.currencyFormat(props.createCaseDTO.caseSponsorshipGroups[0].requiredCashContribution) : ''}
                                                onChange={handleInputChange} />
                                            <Form.Control.Feedback type='invalid'>
                                                Must be 1 or more.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3' controlId='caseRequiredCashNA'>
                                            <Form.Check
                                                type='checkbox'
                                                label='N/A'
                                                checked={props.createCaseDTO.caseSponsorshipGroups[0].requiredCashContributionNA ?? false}
                                                onChange={handleRequiredCashContributionsNAChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group className='mb-3' controlId='inKindContributionDescribe'>
                                        <Form.Label>What is the {isIndividual ? "volunteer's" : "group's"} required in-kind contribution?</Form.Label>
                                        <Stack direction='horizontal' gap={3} className='mb-2'>
                                            <Form.Check
                                                type='radio'
                                                placeholder='Describe in-kind contribution'
                                                label='Describe below'
                                                checked={!props.createCaseDTO.caseSponsorshipGroups[0].requiredInKindContributionsNA}
                                                value='false'
                                                onChange={handleInKindContributionsNAChange}
                                            />
                                            <Form.Check
                                                type='radio'
                                                label='N/A'
                                                checked={props.createCaseDTO.caseSponsorshipGroups[0].requiredInKindContributionsNA}
                                                value="true"
                                                onChange={handleInKindContributionsNAChange}
                                            />
                                        </Stack>
                                        <Form.Control isInvalid={false}
                                            as='textarea'
                                            name='requiredInKindContributions'
                                            value={props.createCaseDTO.caseSponsorshipGroups[0].requiredInKindContributions ? props.createCaseDTO.caseSponsorshipGroups[0].requiredInKindContributions : ''}
                                            disabled={props.createCaseDTO.caseSponsorshipGroups[0].requiredInKindContributionsNA}
                                            onChange={handleInputChange} />
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group className='mb-3' controlId='volunteerActivityResponsibility'>
                                        <Form.Label>Please describe the activities (wrap around services) the {isIndividual ? "volunteer" : "group"} is responsible for.</Form.Label>
                                        <Form.Control isInvalid={false}
                                            as='textarea'
                                            placeholder='Describe activities'
                                            name='volunteerActivityResponsibilities'
                                            value={props.createCaseDTO.caseSponsorshipGroups[0].volunteerActivityResponsibilities ? props.createCaseDTO.caseSponsorshipGroups[0].volunteerActivityResponsibilities : ''}
                                            onChange={handleInputChange} />
                                    </Form.Group>
                                </Row>

                                <Row className='mb-3'>
                                    <Form.Group className='mb-3' controlId='coreMembersReceivedTraining'>
                                        {isIndividual ? <Form.Label>Has the volunteer received training?</Form.Label> :
                                            <Form.Label>Have all core members of the group received training?</Form.Label>
                                        }
                                        <Stack>
                                            <Form.Check
                                                type='radio'
                                                label='Yes'
                                                name='allCoreMembersTrained'
                                                checked={(props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersTrained !== undefined &&
                                                    props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersTrained)
                                                    ? props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersTrained : false}
                                                onChange={handleCheckBoxChange}
                                            />
                                            <Form.Check
                                                type='radio'
                                                label='No'
                                                name='allCoreMembersTrainedNo'
                                                checked={(props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersTrained !== undefined &&
                                                    !props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersTrained)
                                                    ? !props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersTrained : false}
                                                onChange={handleCheckBoxChange}
                                            />
                                            {caseSponsorshipGroup && !caseSponsorshipGroup.allCoreMembersTrained &&
                                                <p>
                                                    <span className='warning'>Note: </span>
                                                    All volunteers that interact directly with clients should receive training.
                                                    If you need help accessing training or would like to request a training,
                                                    please contact the Refugee Welcome Collective.
                                                </p>
                                            }
                                        </Stack>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group className='mb-3' controlId='coreMembersReceivedTraining'>
                                        {isIndividual ? <Form.Label>Has the volunteer provided background checks?</Form.Label> :
                                            <Form.Label>Have background checks been performed on all core members of the group?</Form.Label>
                                        }
                                        <Stack>
                                            <Form.Check
                                                type='radio'
                                                label='Yes'
                                                name='allCoreMembersProvidedBackgroundChecks'
                                                checked={(props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersProvidedBackgroundChecks !== undefined &&
                                                    props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersProvidedBackgroundChecks)
                                                    ? props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersProvidedBackgroundChecks : false}
                                                onChange={handleCheckBoxChange}
                                            />
                                            <Form.Check
                                                type='radio'
                                                label='No'
                                                name='allCoreMembersProvidedBackgroundChecksNo'
                                                checked={(props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersProvidedBackgroundChecks !== undefined &&
                                                    !props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersProvidedBackgroundChecks)
                                                    ? !props.createCaseDTO.caseSponsorshipGroups[0].allCoreMembersProvidedBackgroundChecks : false}
                                                onChange={handleCheckBoxChange}
                                            />
                                            {caseSponsorshipGroup && !caseSponsorshipGroup.allCoreMembersProvidedBackgroundChecks &&
                                                <p>
                                                    <span className='warning'>Note: </span>
                                                    All volunteers that interact directly with clients need to complete a background check.
                                                    If you need assistance on volunteer background checks, please contact the Refugee Welcome Collective.
                                                </p>
                                            }
                                        </Stack>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <hr className='mb-4' />
                                </Row>
                                <Row className='mb-3'>
                                    <Col sm={8}>
                                        <h2>Additional Volunteers</h2>
                                    </Col>
                                    <Col sm={4} className='d-flex justify-content-end'>
                                        <Button onClick={addCaseVolunteer}>Add Volunteer</Button>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <p>Additional volunteers are individuals who perform welcoming activities that promote client integration which are not associated with a community sponsorship group.</p>
                                </Row>
                                {props.createCaseDTO.caseVolunteers?.map((cv, index) => {
                                    if (isIndividual && index !== 0 && !cv.isDeleted) {
                                        return (
                                            <Row key={index}>
                                                <Row>
                                                    <Col className='d-flex justify-content-end'>
                                                        <FontAwesomeIcon className='trash' icon={faTrashAlt} onClick={() => handleRemoveVolunteer(index)} />
                                                    </Col>
                                                </Row>
                                                <CreateVolunteer caseAddVolunteer={true}
                                                    handleCaseChange={props.handleChange}
                                                    caseNumber={props.createCaseDTO.number}
                                                    caseVolunteer={cv}
                                                    isIndividualMatch={!isIndividual} />
                                            </Row>
                                        )
                                    } else if (!isIndividual && !cv.isDeleted) {
                                        return (
                                            <Row key={index}>
                                                <Row>
                                                    <Col className='d-flex justify-content-end'>
                                                        <FontAwesomeIcon className='trash' icon={faTrashAlt} onClick={() => handleRemoveVolunteer(index)} />
                                                    </Col>
                                                </Row>
                                                <CreateVolunteer caseAddVolunteer={true}
                                                    handleCaseChange={props.handleChange}
                                                    caseNumber={props.createCaseDTO.number}
                                                    caseVolunteer={cv}
                                                    isIndividualMatch={isIndividual} />
                                            </Row>
                                        )
                                    }
                                })}
                            </>
                        }
                    </>
                }
            </Form>
        </Container >:<Exception message={error.message}></Exception>
    );
}
CaseSponsorshipGroup.displayName = CaseSponsorshipGroup.name;