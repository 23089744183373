import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Stack } from 'react-bootstrap';
import './LocalOfficeCasesActionRenderer.css';

export default (props: any) => {
    const editCase = () => {
        props.editCase(props.data);
    }

    const viewCaseActivities = () => {
        props.viewCaseActivities(props.data);
    }

    const downloadMou = () => {
        props.downloadMou(props.data);
    }

    return (
        <Stack direction='horizontal' gap={2}>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor: 'white', color: 'gray', border: 'none' }}>
                    <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={viewCaseActivities}>View Case Activities</Dropdown.Item>
                    <Dropdown.Item onClick={editCase}>Edit/View Client Details</Dropdown.Item>
                    {props.data.caseSponsorshipGroups && props.data.caseSponsorshipGroups[0] && props.data.caseSponsorshipGroups[0].mouFilePath &&
                        <Dropdown.Item onClick={downloadMou}>Download MOU</Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </Stack>
    );
};