import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default (props: any) => {
    const viewSponsorshipGroupInformation = () => {
        props.viewSponsorshipGroupInformation(props.data)
    }

    return (
        <>
            {props.data.caseSponsorshipGroups.length > 0 &&
                <span style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faInfoCircle} title='View Sponsorship Information' onClick={viewSponsorshipGroupInformation} />
                </span>
            }
        </>
    );
};

