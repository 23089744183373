import { LocalOfficeClient, LocalOfficeDTO } from '../volunteer-tracking-client';

const client: LocalOfficeClient = new LocalOfficeClient();

export const LocalOfficeService = {

    getByResettlementAgencyId: async (id: number | undefined) => {
        const result = await client.getByResettlementAgencyId(id)
        return result
    },
    getLocalOffice: async (id: number | undefined) => {
        return await client.getLocalOffice(id)
    },
    removeLocalOffice: async (id: number | undefined) => {
        const result = await client.removeLocalOffice(id)
        return result
    },
    updateLocalOffice: async (dto:LocalOfficeDTO) => {
        const result = await client.editLocalOffice(dto)
        return result
    }
}