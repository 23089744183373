import React, { useEffect, useState } from 'react';
import { CaseDTO, CaseClient, VolunteerClient, GetVolunteerCasesDTO } from '../../volunteer-tracking-client';
import { useAppContext } from '../../AppContext';
import { ListCard } from '../Common/ListCard';
import { VolunteerCaseButtons } from './VolunteerCaseButtons';

const volunteerClient: VolunteerClient = new VolunteerClient();
const caseClient: CaseClient = new CaseClient();

export const VolunteerCases = () => {
    const [cases, setCases] = useState<CaseDTO[]>([]);
    const app = useAppContext();

    useEffect(() => {
        const getVolunteerCases = async () => {
            const volunteer = await volunteerClient.getVolunteerByPrincipalId(app.user?.uniqueId);
            if (volunteer) {
                const volunteerCasesDTO: GetVolunteerCasesDTO = {
                    volunteerID: volunteer.id,
                    localOfficeID: parseInt(localStorage.getItem("selectedOrganizationId")!)
                }
                await caseClient.getVolunteerCases(volunteerCasesDTO)
                    .then((resp) => {
                        resp.map(c => {
                            c.familyMembers = c.familyMembers?.filter(f => f.isPrimary)
                            return c
                        }).sort((c1: CaseDTO, c2: CaseDTO) => {
                            if (!c1.status) {
                                return 1
                            }
                            if (!c2.status) {
                                return -1
                            }
                            if (c1.status < c2.status) {
                                return -1
                            }
                            if (c2.status < c1.status) {
                                return -1
                            }
                            return 0
                        })
                        setCases(resp);
                    });
            }
        };

        getVolunteerCases();
    }, []);

    return (
        <>
            <div className="case-subheader">
                <h2>Current Clients</h2>
                <p>Below you will find a list of families that have been assigned to you</p>
            </div>
            {cases.map(c => {
                if (c.familyMembers) {
                    return c.familyMembers.map(fm => {
                        return (
                            <ListCard
                                key={fm.id}
                                title={fm.firstName + ' ' + fm.lastName}>
                                <p>Status: {c.status?.name}</p>
                                <VolunteerCaseButtons selectedCase={c} ></VolunteerCaseButtons>
                            </ListCard>
                        )
                    })
                }
            })}
        </>
    )
}

VolunteerCases.displayName = VolunteerCases.name;