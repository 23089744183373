import React, { useEffect, useState } from 'react';
import { Modal, Spinner, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateCaseValidationService } from '../../services/CreateCaseValidationService';
import { ApiException, CaseClient, CaseVolunteerDTO, CountryClient, CountryDTO, CreateCaseDTO, FaithCommunityTypeDTO, FamilyMemberDTO, LanguageClient, LanguageDTO, LocalOfficeClient, LocalOfficeDTO, NationalResettlementAgencyClient, NationalResettlementAgencyDTO, SponsorshipGroupClient, SponsorshipGroupCommunityClient, SponsorshipGroupCommunityTypeDTO, SponsorshipGroupDTO, SponsorshipGroupTypeDTO, StateClient, StateDTO } from '../../volunteer-tracking-client';
import { Exception } from '../Exception';
import { CaseDetails } from './CaseDetails';
import { CasePageWrapper } from './CasePageWrapper';
import { CaseReview } from './CaseReview';
import { ClientFamilyMemberDetails } from './ClientFamilyMemberDetails';
import { CaseSponsorshipGroup } from './SponsorshipGroup/CaseSponsorshipGroup';
import { SponsorshipGroupSelect } from './SponsorshipGroup/SponsorshipGroupSelect';
import './CreateCase.css';
import { LocalOfficeService } from '../../services/LocalOfficeService';
import { useAppContext } from '../../AppContext';

const caseClient: CaseClient = new CaseClient();
const languageClient: LanguageClient = new LanguageClient();
const stateClient: StateClient = new StateClient();
const countryClient: CountryClient = new CountryClient();
const natraClient: NationalResettlementAgencyClient = new NationalResettlementAgencyClient();
const localOfficeClient: LocalOfficeClient = new LocalOfficeClient();
const sponsorshipGroupClient: SponsorshipGroupClient = new SponsorshipGroupClient();
const sponsorshipGroupCommunityClient: SponsorshipGroupCommunityClient = new SponsorshipGroupCommunityClient();

export const CreateCase = () => {
    const defaultFamilyMember: FamilyMemberDTO = { isPrimary: true }
    const newCase: CreateCaseDTO = {
        statusID: 1,
        familyMembers: [defaultFamilyMember]
    }
    const defaultValidationErrors = new Map<string, string[]>([['CaseDetails', []], ['FamilyMember', []], ['CaseSponsorshipGroup', []], ['SponsorshipGroup', []]])
    const [step, setStep] = useState<number>(1);
    const [createCaseDTO, setCase] = useState<CreateCaseDTO>(newCase);
    const [validationErrors, setValidationErrors] = useState<Map<string, string[]>>(defaultValidationErrors);
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [languageList, setLanguageList] = useState<LanguageDTO[]>([]);
    const [stateList, setStateList] = useState<StateDTO[]>([]);
    const [countryList, setCountryList] = useState<CountryDTO[]>([]);
    const [natRAList, setNatRAList] = useState<NationalResettlementAgencyDTO[]>([]);
    const [localOfficeList, setLocalOfficeList] = useState<LocalOfficeDTO[]>([]);
    const [sponsorshipCommunityTypes, setSponsorshipCommunityTypes] = useState<SponsorshipGroupCommunityTypeDTO[]>([]);
    const [faithCommunityTypes, setFaithCommunityTypes] = useState<FaithCommunityTypeDTO[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error>();
    const [notFound, setNotFound] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [localOffice, setLocalOffice] = useState<LocalOfficeDTO | undefined>(undefined);
    const [sponsorshipGroupTypes, setSponsorshipGroupTypes] = useState<SponsorshipGroupTypeDTO[]>([]);
    const [totalSteps, setTotalSteps] = useState<number>(4);

    const { caseId } = useParams();
    const navigate = useNavigate();
    const app = useAppContext();

    useEffect(() => {
        const getLanguages = async () => {
            await languageClient.getAllLanguages()
                .then((resp) => { setLanguageList(resp); });
        };
        const getStates = async () => {
            await stateClient.getAllStates()
                .then((resp) => { setStateList(resp); });
        };
        const getCountries = async () => {
            await countryClient.getAllCountries()
                .then((resp) => { setCountryList(resp); });
        };
        const getNationalResettlementAgencies = async () => {
            await natraClient.getAllNationalResettlementAgencies()
                .then((resp) => { setNatRAList(resp); })
        };
        const getLocalOffices = async () => {
            await localOfficeClient.getAllLocalOffices()
                .then((resp) => { setLocalOfficeList(resp); })
        };
        const getLocalOffice = async () => {
            const resp = await LocalOfficeService.getLocalOffice(parseInt(localStorage.getItem("selectedOrganizationId")!));
            setLocalOffice(resp);
            createCaseDTO.localOfficeID = resp.id;
        };
        const getSponsorshipGroupTypes = async () => {
            const resp = await sponsorshipGroupClient.getTypes();
            setSponsorshipGroupTypes(resp);
            if (caseId) {
                getCase()
            }
        };
        const getCase = async () => {
            if (caseId) {
                const c: CreateCaseDTO = await caseClient.getCase(parseInt(caseId))
                if (c.caseSponsorshipGroups && c.caseSponsorshipGroups.length > 0) {
                    c.matchedWithCommunitySponsorship = true;
                    const caseVolunteers: CaseVolunteerDTO[] = await caseClient.getCaseCaseVolunteers(parseInt(caseId));
                    c.caseVolunteers = caseVolunteers;
                    if (c.caseSponsorshipGroups[0].sponsorshipGroupID) {
                        const sponsorshipGroup: SponsorshipGroupDTO = await caseClient.getCaseSponsorshipGroup(c.caseSponsorshipGroups[0].sponsorshipGroupID);
                        c.caseSponsorshipGroups[0].sponsorshipGroup = sponsorshipGroup;
                    }

                    if (c.caseSponsorshipGroups[0] && c.caseSponsorshipGroups[0].typeID !== 3 && c.caseSponsorshipGroups[0].sponsorshipGroup) {
                        c.caseSponsorshipGroups[0].pairedWithCommunitySponsorship = true;
                        c.caseSponsorshipGroups[0].sponsorshipGroup.isExisting = true;
                    }
                }
                setCase(c)
            }
        }
        const getSponsorshipCommunityTypes = async () => {
            await sponsorshipGroupCommunityClient.getCommunityTypes()
                .then((resp) => { setSponsorshipCommunityTypes(resp); });
        };
        const getFaithCommunityTypes = async () => {
            await sponsorshipGroupCommunityClient.getFaithCommunityTypes()
                .then((resp) => { setFaithCommunityTypes(resp); });
        };

        setIsLoading(true);
        getLanguages();
        getStates();
        getCountries();
        getNationalResettlementAgencies();
        getLocalOffices();
        getLocalOffice();
        getSponsorshipGroupTypes();

        getSponsorshipCommunityTypes();
        getFaithCommunityTypes();
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (createCaseDTO.caseSponsorshipGroups && createCaseDTO.caseSponsorshipGroups[0] && createCaseDTO.caseSponsorshipGroups[0].typeID &&
            createCaseDTO.caseSponsorshipGroups[0]?.typeID !== sponsorshipGroupTypes.find(x => x.type === 'Individual Volunteer')?.id) {
            setTotalSteps(5);
        } else {
            setTotalSteps(4);
        }
    }, [createCaseDTO]);

    const nextStep = () => {
        setStep(step + 1);
        if (step + 1 === totalSteps) {
            hasAnyValidationErrors();
        }
    }

    const prevStep = () => {
        setStep(step - 1);
        if (step === totalSteps) {
            setNotFound(false);
        }
    }

    const submit = async () => {
        try {
            setIsLoading(true);
            if (createCaseDTO.id === undefined) {
                await caseClient.createNewCase(createCaseDTO)
                    .then(_result => {
                        setStep(0);
                        setSubmitted(true);
                        setIsLoading(false);
                    })
                    .catch(async (err: ApiException) => {
                        setIsLoading(false);
                        if (err.status === 404) {
                            setNotFound(true);
                        } else {
                            throw err;
                        }
                    });
            } else {
                await caseClient.updateExistingCase(createCaseDTO)
                    .then(_result => {
                        setIsLoading(false);
                        navigate('/local-office-home');
                    })
                    .catch(async (err: ApiException) => {
                        setIsLoading(false);
                        if (err.status === 404) {
                            setNotFound(true);
                        } else {
                            throw err;
                        }
                    });
            }
            if (createCaseDTO.caseVolunteers?.some(cv => cv.volunteer?.email === app.user?.email)) {
                app.addRole(app.user, "Volunteer.All", createCaseDTO.localOfficeID, localOffice?.name)
            }
        } catch (ex: any) {
            setError(ex);
            setIsLoading(false);
        }
    }

    const validationErrorsUpdated = () => {
        setCanSubmit(true);
        validationErrors.forEach(page => {
            if (page.length > 0) {
                setCanSubmit(false);
            }
        });
    }

    const handleChange = (key: any, value: any) => {
        setCase(prevState => ({ ...prevState, [key]: value }));
    }

    const handleFamilyMemberAdd = (key: any, value: FamilyMemberDTO) => {
        let existingFamilyMembers = [...createCaseDTO.familyMembers ?? []];
        existingFamilyMembers.push(value);
        handleChange(key, existingFamilyMembers);
    }

    const handleFamilyMemberRemove = (key: any, index: number) => {
        let existingFamilyMembers = [...createCaseDTO.familyMembers ?? []];
        existingFamilyMembers.splice(index, 1);
        handleChange(key, existingFamilyMembers);
    }

    const closeSuccessModal = () => {
        navigate("/local-office-home");
    }

    const hasAnyValidationErrors = (): Map<string, string[]> => {
        //CaseDetails Errors
        hasValidationError('CaseDetails', 'nationalResettlementAgencyID', localOffice?.nationalResettlementAgency?.id);
        hasValidationError('CaseDetails', 'localOfficeID', createCaseDTO.localOfficeID);
        hasValidationError('CaseDetails', 'number', createCaseDTO.number);
        hasValidationError('CaseDetails', 'size', createCaseDTO.size);
        hasValidationError('CaseDetails', 'clientNativeLanguageID', createCaseDTO.clientNativeLanguageID);
        hasValidationError('CaseDetails', 'countryOfOriginID', createCaseDTO.countryOfOriginID);
        hasValidationError('CaseDetails', 'clientDOA', createCaseDTO.clientDOA)
        hasValidationError('CaseDetails', 'grantTag', createCaseDTO.grantTag)
        if (createCaseDTO.grantTag === 'Other') {
            hasValidationError('CaseDetails', 'grantTagOther', createCaseDTO.grantTagOther)
        } else {
            const caseDetailErrors = validationErrors.get('CaseDetails')
            const idx = caseDetailErrors?.findIndex(x => x === 'grantTagOther')
            if ((idx || -1) >= 0) {
                caseDetailErrors?.splice(idx || -1, 1)
                validationErrors.set('CaseDetails', caseDetailErrors || [])
            }
        }


        //CaseFamilyMember Errors
        createCaseDTO.familyMembers?.forEach((fm, index) => {
            let validationPageName = 'FamilyMember';
            if (!fm.isPrimary) {
                validationPageName = 'FamilyMember' + index.toString();
            }
            hasValidationError(validationPageName, 'firstName', fm.firstName);
            hasValidationError(validationPageName, 'lastName', fm.lastName);
            hasValidationError(validationPageName, 'addressLine1', fm.addressLine1);
            hasValidationError(validationPageName, 'city', fm.city);
            hasValidationError(validationPageName, 'stateID', fm.stateID);
            hasValidationError(validationPageName, 'zipCode', fm.zipCode);
            if (fm.isPrimary) {
                hasValidationError(validationPageName, 'contactNumber', fm.contactNumber);
            }
        });

        //CaseVolunteer Errors
        if (createCaseDTO.caseVolunteers && createCaseDTO.caseVolunteers[0]) {
            hasValidationError('CaseVolunteer', 'email', createCaseDTO.caseVolunteers[0].volunteer?.email);
            hasValidationError('CaseVolunteer', 'firstName', createCaseDTO.caseVolunteers[0].volunteer?.firstName);
            hasValidationError('CaseVolunteer', 'lastName', createCaseDTO.caseVolunteers[0].volunteer?.lastName);
        }

        //CaseSponsorshipGroup Errors
        if (createCaseDTO.caseSponsorshipGroups && createCaseDTO.caseSponsorshipGroups[0]) {
            hasValidationError('CaseSponsorshipGroup', 'coreServices', createCaseDTO.caseSponsorshipGroups[0].coreServices);
            hasValidationError('CaseSponsorshipGroup', 'requiredCashContribution', createCaseDTO.caseSponsorshipGroups[0].requiredCashContribution);
            if (createCaseDTO.caseSponsorshipGroups[0].typeID === 1) {
                hasValidationError('CaseSponsorshipGroup', 'signedMoU', createCaseDTO.caseSponsorshipGroups[0].signedMoU);
            }
        }

        //SponsorshipGroup Errors
        if (createCaseDTO.caseSponsorshipGroups && createCaseDTO.caseSponsorshipGroups[0] && createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup) {
            hasValidationError('SponsorshipGroup', 'name', createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup.name);
            hasValidationError('SponsorshipGroup', 'totalGroupMembers', createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup.totalGroupMembers);
            hasValidationError('SponsorshipGroup', 'sponsorshipGroupCommunityTypeID', createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup.sponsorshipGroupCommunityTypeID);
            hasValidationError('SponsorshipGroup', 'faithCommunityTypeID', createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup.faithCommunityTypeID);
            hasValidationError('CaseSponsorshipGroup', 'dateOfMatch', createCaseDTO.caseSponsorshipGroups[0].dateOfMatch);
            hasValidationError('CaseSponsorshipGroup', 'commitmentDurationMonths', createCaseDTO.caseSponsorshipGroups[0].commitmentDurationMonths);
            hasValidationError('CaseSponsorshipGroup', 'commitmentDurationMonthsMax', createCaseDTO.caseSponsorshipGroups[0].commitmentDurationMonthsMax);
            createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup.sponsorshipGroupVolunteers?.forEach((sgv, index) => {
                const page = 'SponsorshipGroupVolunteer' + index.toString();
                hasValidationError(page, 'email', sgv.volunteer?.email);
                hasValidationError(page, 'firstName', sgv.volunteer?.firstName);
                hasValidationError(page, 'lastName', sgv.volunteer?.lastName);
            });
        }

        validationErrorsUpdated();
        return validationErrors;
    }

    const hasValidationError = (page: string, field: string, value: any): boolean => {
        let hasError = false;
        if (page === 'CaseSponsorshipGroup' && createCaseDTO.caseSponsorshipGroups && createCaseDTO.caseSponsorshipGroups[0]) {
            hasError = CreateCaseValidationService.caseSponsorshipGroupHasError(createCaseDTO.caseSponsorshipGroups[0], page, field, value);
        } else if (page.includes('SponsorshipGroup')) {
            if (createCaseDTO.caseSponsorshipGroups && createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup) {
                hasError = CreateCaseValidationService.sponsorshipGroupHasError(createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup, page, field, value);
            }
        }
        else {
            hasError = CreateCaseValidationService.hasError(page, field, value);
        }

        const currentPageErrors = validationErrors.get(page);
        if (currentPageErrors) {
            if (hasError) {
                if (currentPageErrors.includes(field)) return hasError;
                currentPageErrors.push(field);
                const updatedValidationErrors = validationErrors.set(page, currentPageErrors);
                setValidationErrors(updatedValidationErrors);
                return hasError;
            }
            const index = currentPageErrors.indexOf(field, 0);
            if (index > -1) {
                let newPageErrors: string[] = [];
                if (index === 0 && currentPageErrors.length === 1) {
                    currentPageErrors.pop();
                    newPageErrors = [];
                } else if (index === 0 && currentPageErrors.length > 1) {
                    currentPageErrors.shift();
                    newPageErrors = currentPageErrors;
                } else if (index === currentPageErrors.length - 1) {
                    currentPageErrors.pop();
                    newPageErrors = currentPageErrors;
                } else {
                    newPageErrors = currentPageErrors.splice(index, 1);
                }
                const updatedValidationErrors = validationErrors.set(page, newPageErrors);
                setValidationErrors(updatedValidationErrors);
            }
        } else {
            const updatedValidationErrors = validationErrors.set(page, [field]);
            setValidationErrors(updatedValidationErrors);
        }

        return hasError;
    }

    if (error) {
        return (
            <Exception message={error.message}></Exception>
        );
    } else if (isLoading) {
        return (
            <Stack className='mx-auto mt-5'>
                <Spinner className="mx-auto" animation='border' variant='primary' />
            </Stack>
        );
    } else {
        switch (step) {
            case 0:
                return (
                    <Modal show={submitted} onHide={closeSuccessModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Client {createCaseDTO.id === undefined ? 'Created' : 'Updated'} Sucessfully.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>The client was {createCaseDTO.id === undefined ? 'created' : 'updated'} Successfully!
                            {createCaseDTO.id === undefined ? 'Close this modal to continue with case creation.'
                                : 'Close this modal to case list.'}
                        </Modal.Body>
                    </Modal>
                );
            case 1:
                return <CasePageWrapper step={step} nextStep={nextStep} prevStep={undefined}
                    submit={undefined} canSubmit={false} totalSteps={totalSteps}
                    validationErrors={validationErrors}>
                    <CaseDetails handleChange={handleChange}
                        createCaseDTO={createCaseDTO}
                        languageList={languageList}
                        countryList={countryList} />
                </CasePageWrapper>
            case 2:
                return <CasePageWrapper step={step} nextStep={nextStep} prevStep={prevStep}
                    submit={undefined} canSubmit={false} totalSteps={totalSteps} validationErrors={validationErrors}>
                    <ClientFamilyMemberDetails
                        handleChange={handleChange}
                        familyMembers={createCaseDTO.familyMembers ? createCaseDTO.familyMembers : [defaultFamilyMember]}
                        createCaseDTO={createCaseDTO}
                        stateList={stateList}
                        handleFamilyMemberAdd={handleFamilyMemberAdd}
                        handleFamilyMemberRemove={handleFamilyMemberRemove} />
                </CasePageWrapper>
            case 3:
                return <CasePageWrapper step={step} nextStep={nextStep} prevStep={prevStep}
                    submit={undefined} canSubmit={false} totalSteps={totalSteps} validationErrors={validationErrors}>
                    <CaseSponsorshipGroup handleChange={handleChange}
                        createCaseDTO={createCaseDTO}
                        natRAList={natRAList}
                        localOfficeList={localOfficeList}
                        localOffice={localOffice}
                        sponsorshipGroupTypes={sponsorshipGroupTypes} />
                </CasePageWrapper>
            case 4:
                if (totalSteps === 4) {
                    return <CasePageWrapper step={step} nextStep={undefined}
                        prevStep={prevStep} canSubmit={canSubmit} totalSteps={totalSteps} submit={submit}
                        validationErrors={validationErrors}>
                        <CaseReview createCaseDTO={createCaseDTO}
                            languageList={languageList}
                            countryList={countryList}
                            stateList={stateList}
                            natRAList={natRAList}
                            sponsorshipGroupTypes={sponsorshipGroupTypes}
                            localOfficeList={localOfficeList}
                            localOffice={localOffice}
                            notFound={notFound}
                            hasAnyValidationErrors={hasAnyValidationErrors}
                            sponsorshipCommunityTypes={sponsorshipCommunityTypes}
                            faithCommunityTypes={faithCommunityTypes} />
                    </CasePageWrapper>
                } else {
                    return <CasePageWrapper step={step} nextStep={nextStep}
                        prevStep={prevStep} canSubmit={false} totalSteps={totalSteps} submit={undefined}
                        validationErrors={validationErrors}>
                        <SponsorshipGroupSelect handleChange={handleChange}
                            createCaseDTO={createCaseDTO}
                            sponsorshipCommunityTypes={sponsorshipCommunityTypes}
                            faithCommunityTypes={faithCommunityTypes} />
                    </CasePageWrapper>
                }
            case 5:
                return <CasePageWrapper step={step} nextStep={undefined}
                    prevStep={prevStep} canSubmit={canSubmit} totalSteps={totalSteps} submit={submit}
                    validationErrors={validationErrors}>
                    <CaseReview createCaseDTO={createCaseDTO}
                        languageList={languageList}
                        countryList={countryList}
                        stateList={stateList}
                        natRAList={natRAList}
                        sponsorshipGroupTypes={sponsorshipGroupTypes}
                        localOfficeList={localOfficeList}
                        localOffice={localOffice}
                        notFound={notFound}
                        hasAnyValidationErrors={hasAnyValidationErrors}
                        sponsorshipCommunityTypes={sponsorshipCommunityTypes}
                        faithCommunityTypes={faithCommunityTypes} />
                </CasePageWrapper>
            default:
                return <></>
        }
    }
}
CreateCase.displayName = CreateCase.name;