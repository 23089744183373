import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner, Stack } from 'react-bootstrap';
import { CaseActivityCheckboxService } from '../../../services/CaseActivityCheckboxService';
import { CaseActivityCheckboxDTO, CreateCaseActivityDTO, CreateCaseDTO, VolunteerDTO } from '../../../volunteer-tracking-client';
import { Exception } from '../../Exception';

export const VolunteerGroupCaseActivities = (props: {
    createCaseDTO: CreateCaseDTO,
    caseActivities: CreateCaseActivityDTO[],
    volunteer: VolunteerDTO
}) => {
    const [groupMemberIds, setGroupMemberIds] = useState<number[]>([]);
    const [checkboxes, setCheckboxes] = useState<CaseActivityCheckboxDTO[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error>()

    useEffect(() => {
        const getQuestions = async () => {
            setIsLoading(true);
            const preArrival = await CaseActivityCheckboxService.getActivityCheckboxesBySection('PreArrival');
            const arrival = await CaseActivityCheckboxService.getActivityCheckboxesBySection('Arrival');
            const postArrival = await CaseActivityCheckboxService.getActivityCheckboxesBySection('PostArrival');
            const additional = await CaseActivityCheckboxService.getActivityCheckboxesBySection('AdditionalServices');
            const wcPreArrival = await CaseActivityCheckboxService.getActivityCheckboxesBySection('wcPreArrival');
            const wcDayOfArrival = await CaseActivityCheckboxService.getActivityCheckboxesBySection('wcDayOfArrival');
            const wcWeeks1to4 = await CaseActivityCheckboxService.getActivityCheckboxesBySection('wcWeeks1-4');
            const wcWeeks5to12 = await CaseActivityCheckboxService.getActivityCheckboxesBySection('wcWeeks5-12');

            const checkboxesArray = preArrival.concat(arrival, postArrival, additional,
                wcPreArrival, wcDayOfArrival, wcWeeks1to4, wcWeeks5to12);
            setCheckboxes(checkboxesArray);
            setIsLoading(false);
        }
        try{
            getQuestions()
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    useEffect(() => {
        let existingGroupMemberIds = [...groupMemberIds ?? []];
        if (props.createCaseDTO.caseSponsorshipGroups && props.createCaseDTO.caseSponsorshipGroups[0]) {
            props.createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup?.sponsorshipGroupVolunteers?.map((sgv) => {
                if (sgv.volunteerID) {
                    existingGroupMemberIds.push(sgv.volunteerID);

                }
            })
        }
        if (props.createCaseDTO.caseVolunteers) {
            props.createCaseDTO.caseVolunteers.map((cv) => {
                if (cv.volunteerID) {
                    existingGroupMemberIds.push(cv.volunteerID);
                }
            });
        }
        try{
            setGroupMemberIds(existingGroupMemberIds);
        }catch(ex:any){
            setError(ex)
        }
    }, [props.createCaseDTO]);
    return (
        !error?
        <>
            {isLoading ?
                <>
                    <Stack className='mx-auto mt-5'>
                        <Spinner className="mx-auto" animation='border' variant='primary' />
                    </Stack>
                </>
                :
                <>
                    <h1>GROUP PROGRESS</h1>
                    <p>Below is a list of activities completed by members in your group to date. Keep up the good work!</p>
                    { props.caseActivities.map((ca, index) => {
    
                        if (groupMemberIds.filter(gmId => gmId == ca.volunteerId)[0] !== undefined) {
                            return ca.caseActivityTasks?.map(cat => {
                                const checkbox = checkboxes.filter(cb => cb.id == cat.caseActivityCheckboxId)[0];
                                return (
                                    <Row key={(ca.id ?? 0) + (cat.id ?? 0)} className='mb-3'>
                                        <Row>
                                            <Col>
                                                <Form.Check checked={true} readOnly={true} label={checkbox?.label ?? ''} />
                                            </Col>
                                        </Row>
                                        <Row><Col><p>Completed By: {ca.volunteer?.firstName + ' ' + ca.volunteer?.lastName} on {cat.activityDate?.toLocaleString().split('T')[0]}</p></Col></Row>
                                    </Row>
                                );
                            });
                        }
                    })}
                </>
            }
        </>:<Exception message={error.message}></Exception>
    )
}

VolunteerGroupCaseActivities.displayName = VolunteerGroupCaseActivities.name;