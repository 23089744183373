import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { SponsorshipGroupClient, SponsorshipGroupDTO, SponsorshipGroupVolunteerDTO } from "../../volunteer-tracking-client";
import SponsorshipGroupListActionRenderer from "./SponsorshipGroupListActionRenderer";
import { GroupVolunteerList } from "./GroupVolunteerList";
import { VerifyRemoveSponsorshipGroupModal } from "./VerifyRemoveSponsorshipGroupModal";
import { Exception } from "../Exception";
import { EditSponsorshipGroupModal } from "./EditSponsorshipGroupModal";

const sponsorshipGroupClient = new SponsorshipGroupClient();

export const SponsorshipGroupList = () => {
    const [sponsorshipGroups, setSponsorshipGroups] = useState<SponsorshipGroupDTO[]>([]);
    const [showVolunteersModal, setShowVolunteersModal] = useState(false);
    const [showSponsorshipGroupRemoveModal, setShowSponsorshipGroupRemoveModal] = useState(false);
    const [error, setError] = useState<Error>();
    const [selectedRow, setSelectedRow] = useState<SponsorshipGroupDTO>({});
    const [gridParams, setGridParams] = useState<any>();
    const [showSponsorshipGroupEdit, setShowSponsorshipGroupEdit] = useState<boolean>(false);


    useEffect(() => {
        const getSponsorshipGroups = async () => {
            await sponsorshipGroupClient.getSponsorshipGroupsList(parseInt(localStorage.getItem("selectedOrganizationId")!))
                .then((resp) => {
                    setSponsorshipGroups(resp);
                });
        };
        try{
            getSponsorshipGroups();
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    const getRowNodeId = (data: any) => data.id;

    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
        setGridParams(params);
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        wrapText: true
    };

    const handleGroupVolunteerChange = (updatedSponsorshipGroupVolunteers: SponsorshipGroupVolunteerDTO[]) => {
        const newSponsorshipGroups = sponsorshipGroups.map(sg => {
            if (sg.id === selectedRow.id) {
                sg.sponsorshipGroupVolunteers = updatedSponsorshipGroupVolunteers;
                sg.totalGroupMembers = updatedSponsorshipGroupVolunteers.filter(sgv => !sgv.isDeleted).length;
            }
            return sg;
        });

        setSponsorshipGroups(newSponsorshipGroups);
    }

    const showVolunteerList = (selectedRow: SponsorshipGroupDTO) => {
        setSelectedRow(selectedRow);
        setShowVolunteersModal(true);
    }

    const handleEditClick = (data: SponsorshipGroupDTO) => {
        setSelectedRow(data);
        setShowSponsorshipGroupEdit(true);
    }

    const handleSponsorshipGroupChange = async (newSG: SponsorshipGroupDTO) => {
        const updatedGroups = sponsorshipGroups.map(sg => {
            if (sg.id === newSG.id) {
                sg = newSG;
            }

            return sg;
        });

        try {
            await sponsorshipGroupClient.updateSponsorshipGroup(newSG);
            setSponsorshipGroups(updatedGroups);
        } catch (error: any) {
            setError(error);
        }

        setShowSponsorshipGroupEdit(false);
    }

    const handleRemoveSponsorshipGroup = async () => {
        const row: SponsorshipGroupDTO[] | undefined = gridParams.api.getSelectedRows()
        if (row) {
            try {
                await sponsorshipGroupClient.deleteSponsorshipGroup(row[0]);
                row[0].isDeleted = true;
            } catch (error: any) {
                setError(error)
            }
        }
        setShowSponsorshipGroupRemoveModal(false);
    }

    return (
        !error ?
            <Container>
                <Modal show={showVolunteersModal} onHide={() => setShowVolunteersModal(false)}
                    size='xl'
                    contentClassName='p-3'>
                    <Modal.Header closeButton>
                        <h3>{selectedRow.name}: Volunteers</h3>
                    </Modal.Header>
                    <GroupVolunteerList sponsorshipGroupVolunteers={selectedRow.sponsorshipGroupVolunteers ?? []} handleGroupChange={handleGroupVolunteerChange} />
                </Modal>
                <VerifyRemoveSponsorshipGroupModal
                    show={showSponsorshipGroupRemoveModal}
                    confirmHandler={handleRemoveSponsorshipGroup}
                    closeHandler={() => setShowSponsorshipGroupRemoveModal(false)}
                />
                {showSponsorshipGroupEdit &&
                    <EditSponsorshipGroupModal
                        show={showSponsorshipGroupEdit}
                        sponsorshipGroup={selectedRow}
                        confirmHandler={handleSponsorshipGroupChange}
                        closeHandler={() => setShowSponsorshipGroupEdit(false)}
                    />
                }
                <h3>Sponsorship Groups</h3>
                <div className="ag-theme-alpine" style={{ height: 700 }}>
                    <AgGridReact
                        getRowNodeId={getRowNodeId}
                        defaultColDef={defaultColDef}
                        frameworkComponents={{
                            sponsorshipGroupListActionRenderer: SponsorshipGroupListActionRenderer
                        }}
                        rowData={sponsorshipGroups}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        pagination={true}
                        paginationAutoPageSize={true}
                    >
                        <AgGridColumn headerName="Name" field="name"></AgGridColumn>
                        <AgGridColumn headerName="Associated Cases" field="associatedCases"></AgGridColumn>
                        <AgGridColumn headerName="Types" field="associatedTypes"></AgGridColumn>
                        <AgGridColumn headerName="Total Group Members" field="totalGroupMembers"></AgGridColumn>
                        <AgGridColumn headerName="Deleted" field="isDeleted"></AgGridColumn>
                        <AgGridColumn headerName='Actions' cellRenderer="sponsorshipGroupListActionRenderer" cellRendererParams={{
                            viewVolunteers: (data: SponsorshipGroupDTO) => { showVolunteerList(data); },
                            editGroup: (data: SponsorshipGroupDTO) => { handleEditClick(data) },
                            verifyRemoveSponsorshipGroup: (data: SponsorshipGroupDTO) => { setSelectedRow(data); setShowSponsorshipGroupRemoveModal(true); }
                        }}></AgGridColumn>
                    </AgGridReact>
                </div>
            </Container> :
            <>
                <Exception message={error.message}></Exception>
            </>
    );
} 