import { ReportClient, ReportDTO } from '../volunteer-tracking-client';
import { GetReportsByRolesDTO } from '../volunteer-tracking-client';
const client: ReportClient = new ReportClient();

export const ReportService = {
    getReportsByRoles: async (roles: string[] | undefined) => {
        const getReportsByRolesDTO: GetReportsByRolesDTO = {
            roles: roles
        }
        const result: ReportDTO[] = await client.getReportsByRoles(getReportsByRolesDTO)
        return result
    },

    getReport: async (id: number) => {
        const result: ReportDTO = await client.getReport(id)
        return result
    },
}