import React from "react";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { CreateCaseValidationService } from "../../../services/CreateCaseValidationService";
import { CaseSponsorshipGroupDTO, CaseVolunteerDTO, CreateCaseDTO, LocalOfficeDTO, NationalResettlementAgencyDTO, SponsorshipGroupTypeDTO, VolunteerDTO } from "../../../volunteer-tracking-client";
import { CreateVolunteer } from "../../Volunteer/CreateVolunteer";

export const CaseSponsorshipGroupMatched = (props: {
    handleChange: (key: any, value: any) => void,
    createCaseDTO: CreateCaseDTO,
    natRAList: NationalResettlementAgencyDTO[],
    localOfficeList: LocalOfficeDTO[],
    localOffice: LocalOfficeDTO | undefined,
    sponsorshipGroupTypes: SponsorshipGroupTypeDTO[],
    isMatched: boolean | undefined,
    isCommunitySponsorship: boolean | undefined
}) => {
    const individualVolunteerTypeID = props.sponsorshipGroupTypes.find(x => x.type === 'Individual Volunteer')?.id;
    const supportTeamTypeID = props.sponsorshipGroupTypes.find(x => x.type === 'Support Team')?.id;
    const coSponsorshipTypeID = props.sponsorshipGroupTypes.find(x => x.type === 'CoSponsorship')?.id;
    const privateSponsorshipID = props.sponsorshipGroupTypes.find(x => x.type === 'Private Sponsorship')?.id;
    const newVolunteer = { firstName: '', email: '', lastName: '' } as VolunteerDTO;
    const newCaseVolunteer = { volunteer: newVolunteer } as CaseVolunteerDTO;

    const handleCommunitySponsorshipMatchChange = (event: any) => {
        let key: keyof CreateCaseDTO = event.target.name;
        let value = event.target.checked;

        if (key.endsWith('No')) {
            value = !value;
            clearSponsorshipGroup(true);
        }

        if (key.includes('matchedWithCommunitySponsorship')) {
            key = 'matchedWithCommunitySponsorship';
        }

        props.handleChange(key, value);
        if (value) {
            const newCaseSponorshipGroup = {} as CaseSponsorshipGroupDTO;
            props.handleChange('caseSponsorshipGroups', [newCaseSponorshipGroup]);
        } else {
            props.handleChange('caseSponsorshipGroups', undefined);
        }
    }

    const handleCheckBoxChange = (event: any) => {
        let key: keyof CaseSponsorshipGroupDTO = event.target.name;
        let value = event.target.checked;

        if (props.createCaseDTO.caseSponsorshipGroups) {
            if (key.endsWith('No')) {
                value = !value;
                if (key.includes('pairedWithCommunitySponsorship')) {
                    props.createCaseDTO.caseSponsorshipGroups[0]['typeID'] = individualVolunteerTypeID;
                } else if (key.includes('isSupportGroup')) {
                    props.createCaseDTO.caseSponsorshipGroups[0]['typeID'] = coSponsorshipTypeID;
                }
            }

            if (key.includes('pairedWithCommunitySponsorship')) {
                if (!key.endsWith('No')) props.createCaseDTO.caseSponsorshipGroups[0]['typeID'] = undefined;
                key = 'pairedWithCommunitySponsorship';
                clearSponsorshipGroup();
            } else if (key.includes('isSupportGroup')) {
                if (!key.endsWith('No')) {
                    props.createCaseDTO.caseSponsorshipGroups[0]['typeID'] = supportTeamTypeID;
                }
            } else if (key.includes('isPrivateSponsorship')) {
                if (!key.endsWith('No')) {
                    props.createCaseDTO.caseSponsorshipGroups[0]['typeID'] = privateSponsorshipID;
                }
            } else if (key.includes('signedMoU')) {
                key = 'signedMoU';
            } else if (key.includes('allCoreMembersTrained')) {
                key = 'allCoreMembersTrained';
            } else if (key.includes('allCoreMembersProvidedBackgroundChecks')) {
                key = 'allCoreMembersProvidedBackgroundChecks';
            }

            if (!key.includes('isSupportGroup')) {
                props.createCaseDTO.caseSponsorshipGroups[0][key] = value;
            }
        }

        props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
    }

    const clearSponsorshipGroup = (clearAll?: boolean) => {
        if (props.createCaseDTO.caseSponsorshipGroups && props.createCaseDTO.caseSponsorshipGroups[0]) {
            props.createCaseDTO.caseSponsorshipGroups[0]['signedMoU'] = undefined;
            props.createCaseDTO.caseSponsorshipGroups[0]['coreServices'] = undefined;
            props.createCaseDTO.caseSponsorshipGroups[0]['requiredCashContribution'] = undefined;
            props.createCaseDTO.caseSponsorshipGroups[0]['requiredInKindContributions'] = undefined;
            props.createCaseDTO.caseSponsorshipGroups[0]['allCoreMembersTrained'] = undefined;
            props.createCaseDTO.caseSponsorshipGroups[0]['allCoreMembersProvidedBackgroundChecks'] = undefined;
            props.createCaseDTO.caseSponsorshipGroups[0]['sponsorshipGroup'] = undefined;
            props.createCaseDTO.caseSponsorshipGroups[0]['sponsorshipGroupID'] = undefined;
            props.handleChange('caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        }

        if (clearAll) {
            props.handleChange('caseVolunteers', undefined);
        }
    }

    const hasValidationError = (page: string, field: string, value: any): boolean => {
        const hasError = CreateCaseValidationService.hasError(page, field, value);
        return hasError;
    }

    const handleCaseVolunteerChange = (index: number, value: any) => {
        let updatedList = props.createCaseDTO.caseVolunteers ?? [];
        if (updatedList.length === 0) {
            updatedList.push(value);
        } else {
            updatedList = updatedList.map((cv, listIndex) => {
                return listIndex === index ? value : cv;
            });
        }

        props.handleChange('caseVolunteers', updatedList);
    }
    return (
        <>
            <Row className='mb-3'>
                <Col>
                    <Form.Group className='mb-3' controlId='caseNationalResettlementAgency'>
                        <Form.Label>National Resettlement Agency</Form.Label>
                        <Form.Control
                            isInvalid={hasValidationError('CaseDetails', 'nationalResettlementAgencyID', props.localOffice?.nationalResettlementAgency?.id)}
                            as='select'
                            disabled={true}
                            name='nationalResettlementAgencyID'
                            value={props.localOffice?.nationalResettlementAgency?.id ?? undefined}
                        >
                            <option value={undefined}>Unable to find National Resettlement Agency</option>
                            {props.natRAList.map((natRA, index) => {
                                return <option key={index} value={natRA.id}>{natRA.name}</option>
                            })
                            }
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Invalid
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='mb-3' controlId='caseLocalOfficeAffiliate'>
                        <Form.Label>Name of local affiliate office</Form.Label>
                        <Form.Control
                            isInvalid={hasValidationError('CaseDetails', 'localOfficeID', props.createCaseDTO.localOfficeID)}
                            name='localOfficeID'
                            as='select'
                            disabled={true}
                            value={props.createCaseDTO.localOfficeID ?? undefined}
                        >
                            <option value={undefined}>Select local affiliate office</option>
                            {props.localOfficeList.map((localOffice, index) => {
                                return <option key={index} value={localOffice.id}>{localOffice.name}</option>
                            })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Form.Group className='mb-3' controlId='matchedWithCommunitySponsorship'>
                    <Form.Label>Are you matching this client with a community sponsorship group or volunteer now?</Form.Label>
                    <Stack>
                        <Form.Check
                            type='radio'
                            label='Yes'
                            name='matchedWithCommunitySponsorship'
                            checked={(props.createCaseDTO && props.createCaseDTO.matchedWithCommunitySponsorship)
                                ? props.createCaseDTO.matchedWithCommunitySponsorship : false}
                            onChange={handleCommunitySponsorshipMatchChange}
                        />
                        <Form.Check
                            type='radio'
                            label='No'
                            name='matchedWithCommunitySponsorshipNo'
                            checked={(props.createCaseDTO &&
                                props.createCaseDTO.matchedWithCommunitySponsorship !== undefined &&
                                !props.createCaseDTO.matchedWithCommunitySponsorship)
                                ? !props.createCaseDTO.matchedWithCommunitySponsorship : false}
                            onChange={handleCommunitySponsorshipMatchChange}
                        />
                    </Stack>
                </Form.Group>
            </Row>
            {(props.createCaseDTO.caseSponsorshipGroups && props.isMatched !== undefined && props.isMatched) &&
                <Row className='mb-3'>
                    <Form.Group className='mb-3' controlId='agreedToBePaired'>
                        <Form.Label>Are you matching them with a community sponsorship group or a volunteer?</Form.Label>
                        <Stack>
                            <Form.Check
                                type='radio'
                                label='Community Sponsorship Group'
                                name='pairedWithCommunitySponsorship'
                                checked={props.createCaseDTO.caseSponsorshipGroups[0] &&
                                    props.createCaseDTO.caseSponsorshipGroups[0].pairedWithCommunitySponsorship !== undefined &&
                                    props.createCaseDTO.caseSponsorshipGroups[0].pairedWithCommunitySponsorship ? props.createCaseDTO.caseSponsorshipGroups[0].pairedWithCommunitySponsorship : false}
                                onChange={handleCheckBoxChange}
                            />
                            <Form.Check
                                type='radio'
                                label='Individual Volunteer'
                                name='pairedWithCommunitySponsorshipNo'
                                checked={props.createCaseDTO.caseSponsorshipGroups[0] &&
                                    props.createCaseDTO.caseSponsorshipGroups[0].pairedWithCommunitySponsorship !== undefined &&
                                    !props.createCaseDTO.caseSponsorshipGroups[0].pairedWithCommunitySponsorship ? !props.createCaseDTO.caseSponsorshipGroups[0].pairedWithCommunitySponsorship : false}
                                onChange={handleCheckBoxChange}
                            />
                        </Stack>
                    </Form.Group>
                </Row>
            }
            {props.isMatched && props.isCommunitySponsorship !== undefined && !props.isCommunitySponsorship &&
                <Row className='mb-3'>
                    <CreateVolunteer caseAddVolunteer={true}
                        handleCaseChange={handleCaseVolunteerChange}
                        caseNumber={props.createCaseDTO.number}
                        caseVolunteer={props.createCaseDTO.caseVolunteers && props.createCaseDTO.caseVolunteers[0] ? props.createCaseDTO.caseVolunteers[0] : newCaseVolunteer}
                        index={0}
                        isIndividualMatch={true} />
                </Row>
            }
            {(props.createCaseDTO.caseSponsorshipGroups && props.isMatched &&
                props.isCommunitySponsorship !== undefined &&
                props.isCommunitySponsorship) &&
                <Row className='mb-3'>
                    <Form.Group className='mb-3' controlId='agreedToBePaired'>
                        <Form.Label>Which type of community sponsorship group is the match?</Form.Label>
                        <Stack>
                            <Form.Check
                                type='radio'
                                label='Co-sponsor'
                                name='isSupportGroupNo'
                                checked={props.createCaseDTO.caseSponsorshipGroups[0].typeID !== undefined &&
                                    props.createCaseDTO.caseSponsorshipGroups[0].typeID === coSponsorshipTypeID}
                                onChange={handleCheckBoxChange}
                            />
                            <Form.Check
                                type='radio'
                                label='Support Team'
                                name='isSupportGroup'
                                checked={props.createCaseDTO.caseSponsorshipGroups[0].typeID !== undefined &&
                                    props.createCaseDTO.caseSponsorshipGroups[0].typeID === supportTeamTypeID}
                                onChange={handleCheckBoxChange}
                            />
                            <Form.Check
                                type='radio'
                                label='Private Sponsorship'
                                name='isPrivateSponsorship'
                                checked={props.createCaseDTO.caseSponsorshipGroups[0].typeID !== undefined &&
                                    props.createCaseDTO.caseSponsorshipGroups[0].typeID === privateSponsorshipID}
                                onChange={handleCheckBoxChange}
                            />
                        </Stack>
                    </Form.Group>
                </Row>
            }
        </>
    );
}
CaseSponsorshipGroupMatched.displayName = CaseSponsorshipGroupMatched.name;