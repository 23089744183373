import React from "react"
import { Button, Modal } from "react-bootstrap"

type VerifyRemoveModalProps = {
    show: boolean,
    confirmHandler: () => void
    closeHandler: () => void
    
}


export const VerifyRemoveLocalOfficeUserModal = (props: VerifyRemoveModalProps) => {
    const onConfirm = () => {
        props.confirmHandler()
    }

    return(
        <Modal show={props.show} onHide={ props.closeHandler } centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Remove Local Office User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You are about to remove the selected individual as a local office user.</p>
                <p>The individual will no longer be able to access the application as a local office user.</p>
                <p>Are you sure you want to proceed?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ props.closeHandler }>Cancel</Button>
                <Button variant="primary" onClick={onConfirm}>Yes</Button>
            </Modal.Footer>
        </Modal>
    )
}