import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faUserSlash, faUser, faDoorOpen } from '@fortawesome/free-solid-svg-icons';

export default (props: any) => {
  const verifyRemoveVolunteer = () => {
    props.verifyRemoveVolunteer(props.data)
  }

  const verifyReactivateVolunteer = () => {
    props.verifyReactivateVolunteer(props.data)
  }

  const verifyReinviteUser = () => {
    props.verifyReinviteUser(props.data)
  }

  return (
    <span style={{ cursor: "pointer" }}>
      {props.data.isActive &&
      <div>
        <span title={"Inactivate " + props.titleText}>
          <FontAwesomeIcon icon={faUserSlash} onClick={verifyRemoveVolunteer} />
        </span>
        <span title={"Reinvite User"} style={{"paddingLeft": "20px"}}>
          <FontAwesomeIcon icon={faDoorOpen} onClick={verifyReinviteUser} />
        </span>
      </div>
      }
      {!props.data.isActive &&
        <span title={"Reactivate " + props.titleText}>
          <FontAwesomeIcon icon={faUser} onClick={verifyReactivateVolunteer} />
        </span>
      }
    </span >
  );
};

