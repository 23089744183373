import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './App.css'

import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult
} from '@azure/msal-browser';
import { App } from './App';

export const msalInstance: PublicClientApplication = new PublicClientApplication({
    auth: {
        clientId: process.env.REACT_APP_APP_ID || '',
        redirectUri: window.location.protocol + '//' + window.location.host + '/blank.html',
        authority: "https://login.microsoftonline.com/5b0ee27a-c2e7-43a0-87d3-6b9c5b56eda0"
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
    }
});

// Check if there are already accounts in the browser session
// If so, set the first account as the active account
const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        // Set the active account - this simplifies token acquisition
        const authResult = event.payload as AuthenticationResult;
        msalInstance.setActiveAccount(authResult.account);
    }
});

ReactDOM.render(
    <BrowserRouter>
        <App pca={msalInstance} />
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
