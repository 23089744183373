
import React, { Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretSquareDown, faCaretSquareUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { CaseActivityCheckboxDTO } from "../../../volunteer-tracking-client";
import { CaseActivityCheckboxService } from "../../../services/CaseActivityCheckboxService";
import { CaseActivityCheckboxProps } from "./CaseActivityCheckboxProps";
import { Exception } from "../../Exception";

export const CaseActivityCheckboxComponent = (props: CaseActivityCheckboxProps) => {
    const { onChangeHandler, caseActivityTasks, clientDOA, review, localOfficeId, section } = props
    const [open, setOpen] = useState<boolean>(props.sectionOpened)
    const [questions, setQuestions] = useState<CaseActivityCheckboxDTO[]>([])
    const [otherDisabled, setOtherDisabled] = useState<boolean>(true)
    const [error, setError] = useState<Error>();

    useEffect(() => {
        const getQuestions = async () => {
            const result = await CaseActivityCheckboxService.getLocalOfficeActivityCheckboxesBySection(localOfficeId!, section);
            result.sort((c1: CaseActivityCheckboxDTO, c2: CaseActivityCheckboxDTO) => {
                if (c1.name === "other") {
                    return 1
                }
                if (c2.name === "other") {
                    return -1
                }
                return 0
            });
            setQuestions(result)
            const otherQuestion = result.find(q => q.name === "other")
            setOtherDisabled(!caseActivityTasks.some(t => t.caseActivityCheckboxId === otherQuestion!.id))
        }
        try{
            getQuestions()
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    const handleChange = (event: any) => {
        const index = caseActivityTasks?.findIndex(a => a.caseActivityCheckboxId === event.target.id)
        if (event.target.checked && index === -1) {
            caseActivityTasks!.push({ caseActivityCheckboxId: event.target.id })
        } else if (!event.target.checked && index !== -1) {
            caseActivityTasks!.splice(index, 1)
        }
        onChangeHandler(() => { return { caseActivityTasks: caseActivityTasks } })
    }

    const handleOtherChange = (event: any) => {
        const otherQuestion = questions.find(q => q.name === "other")
        const index = caseActivityTasks.findIndex(t => t.caseActivityCheckboxId === otherQuestion!.id)
        caseActivityTasks[index].otherCaseActivityTaskText = event.target.value
        onChangeHandler(() => { return { caseActivityTasks: caseActivityTasks } })
    }

    const handleOtherCheck = (event: any) => {
        setOtherDisabled(!event.target.checked)
        handleChange(event)
    }

    const calculateDueDate = (question: CaseActivityCheckboxDTO): string => {

        let result = new Date(clientDOA)
        if (question.useWorkingDays) {
            result = addWorkingDays(question.daysToComplete!)
        } else {
            result.setDate(clientDOA.getDate() + question.daysToComplete!)
        }
        return ((result.getMonth() + 1).toString().padStart(2, '0')) + '-' + result.getDate().toString().padStart(2, '0') + '-' + result.getFullYear();

    }

    const addWorkingDays = (daysToComplete: number): Date => {
        let result = new Date(clientDOA)
        var dow = clientDOA?.getDay();

        // If the current day is Sunday add one day
        if (dow! === 0)
            daysToComplete++;
        // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
        if (dow! + daysToComplete >= 6) {
            //Subtract days in current working week from work days
            var remainingWorkDays = daysToComplete - (5 - dow!);
            //Add current working week's weekend
            daysToComplete += 2;
            if (remainingWorkDays > 5) {
                //Add two days for each working week by calculating how many weeks are included
                daysToComplete += 2 * Math.floor(remainingWorkDays / 5);
                //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
                if (remainingWorkDays % 5 === 0)
                    daysToComplete -= 2;
            }
        }
        result.setDate(result.getDate() + daysToComplete);
        return result
    }

    return (
        !error?
        <>
            <Row className="mt-3" onClick={() => setOpen(!open)}
                aria-expanded={open}
                style={{
                    cursor: "pointer",
                    backgroundColor: "lightgray",
                    paddingTop: "5px",
                    borderRadius: "5px"
                }}>
                <Col xs={11}>
                    <h3>{props.header}</h3>
                </Col>
                {open ?
                    <Col xs={1}>
                        <FontAwesomeIcon
                            icon={faCaretSquareUp} style={{ color: "#1b6ec2" }} />
                    </Col> :
                    <Col xs={1}>
                        <FontAwesomeIcon
                            icon={faCaretSquareDown} style={{ color: "#1b6ec2" }} />
                    </Col>
                }
            </Row>

            <Collapse in={open}>
                <div key={props.header}>
                    <h4>
                        Please select all activities that were completed
                    </h4>

                    {
                        questions.map(q => {
                            // eslint-disable-next-line eqeqeq
                            const checked = caseActivityTasks?.some(a => a.caseActivityCheckboxId == q.id)
                            return (

                                <Fragment key={"fragment-" + q.id}>
                                    <Form.Group className="mb-3" key={q.id} id={q.id?.toString()}>
                                        <Form.Check type="checkbox" label={q.label + (q.daysToComplete != null ? " - Due Date : " + calculateDueDate(q) : "")}
                                            id={q.id!.toString()}
                                            name={q.name}
                                            checked={checked}
                                            onChange={q.name === "other" ? handleOtherCheck : handleChange}
                                            key={q.name + "-" + q.id}
                                            readOnly={review} />
                                    </Form.Group>
                                    {
                                        q.name === 'other' &&
                                        <Form.Group className="mb-3" key={"form-group-other-" + q.id}>
                                            <Form.Control
                                                value={caseActivityTasks.find(t => t.caseActivityCheckboxId === q.id)?.otherCaseActivityTaskText}
                                                name="otherCaseActivityTaskText"
                                                onChange={handleOtherChange}
                                                key={"otherCaseActivityTaskText-" + q.id}
                                                disabled={otherDisabled} />
                                        </Form.Group>
                                    }
                                </Fragment>
                            )
                        })
                    }
                </div>

            </Collapse>
        </>:<Exception message={error.message}></Exception>
    )
}

