import {ReinviteUserDTO, VolunteerClient, VolunteerDTO } from '../volunteer-tracking-client';

const client: VolunteerClient = new VolunteerClient();

export const VolunteerService = {
    deleteVolunteer: async (volunteer: VolunteerDTO) => {
        await client.deleteVolunteer(volunteer)
    },
    getResettlementAgencyVolunteers: async (resettlementAgencyId: number | undefined) => {
        return await client.getResettlementAgencyVolunteers(resettlementAgencyId)
    },
    getVolunteerByPrincipalId: async (principalId: string | undefined) => {
        return await client.getVolunteerByPrincipalId(principalId)
    },
    updateVolunteer: async (volunteer: VolunteerDTO) => {
        return await client.updateVolunteer(volunteer)
    },
    reactivateVolunteer: async (volunteer: VolunteerDTO) => {
        await client.reactivateVolunteer(volunteer)
    },
    createVolunteerInvite: async (dto:VolunteerDTO) => {
        const result = await client.createVolunteerInvite(dto)
        return result
     },
     reinviteVolunteer: async (dto: ReinviteUserDTO) => {
        await client.reinviteUser(dto)
     }
}