import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { CreateCaseValidationService } from '../../../services/CreateCaseValidationService';
import { InputService } from '../../../services/InputService';
import { CreateCaseDTO, FamilyMemberDTO, StateDTO } from '../../../volunteer-tracking-client';

export const FamilyMemberForm = (props: {
    handleUpdate: (index: number, value: FamilyMemberDTO) => void,
    handleRemove: (index: number) => void,
    index: number,
    familyMember: FamilyMemberDTO,
    createCaseDTO: CreateCaseDTO,
    statesList: StateDTO[]
}) => {
    const validationPageName = props.familyMember.isPrimary ? 'FamilyMember' : 'FamilyMember' + props.index.toString();

    const handleFamilyMemberChange = (event: any) => {
        const key: keyof FamilyMemberDTO = event.target.name;
        let value = event.target.value;

        props.familyMember[key] = value;
        props.handleUpdate(props.index, props.familyMember);
    }

    const handleStateSelectChange = (event: any) => {
        const value = event.target.value;
        // eslint-disable-next-line
        const selectedState = props.statesList?.filter(state => state.id == value)[0];

        props.familyMember['stateID'] = selectedState ? selectedState.id : undefined;
        props.handleUpdate(props.index, props.familyMember);
    }

    const hasValidationError = (page: string, field: string, value: any): boolean => {
        const hasError = CreateCaseValidationService.hasError(page, field, value);
        return hasError;
    }

    return (
        <Container className='mb-3'>
            <Form>
                <Row className='my-3'>
                    <Col sm={8}>
                        {props.familyMember.isPrimary ?
                            <h2>
                                Primary Applicant Name
                            </h2>
                            :
                            <h2>New Family Member</h2>
                        }
                    </Col>
                    {!props.familyMember.isPrimary &&
                        <Col sm={4} className='d-flex justify-content-end'>
                            <Button onClick={() => props.handleRemove(props.index)}>Delete Family Member</Button>
                        </Col>
                    }
                </Row>
                <Row className='my-3'>
                    <Col>
                        <Form.Group className='mb-3' controlId='firstName'>
                            <Form.Control isInvalid={hasValidationError(validationPageName, 'firstName', props.familyMember.firstName)}
                                onChange={handleFamilyMemberChange} placeholder='First name'
                                value={props.familyMember.firstName ?? ''}
                                name='firstName' />
                            <Form.Control.Feedback type="invalid">
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3' controlId='middleName'>
                            <Form.Control isInvalid={false} onChange={handleFamilyMemberChange} placeholder='Middle name'
                                value={props.familyMember.middleName ?? ''}
                                name='middleName' />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3' controlId='lastName'>
                            <Form.Control isInvalid={hasValidationError(validationPageName, 'lastName', props.familyMember.lastName)}
                                onChange={handleFamilyMemberChange} placeholder='Last name'
                                value={props.familyMember.lastName ?? ''}
                                name='lastName' />
                            <Form.Control.Feedback type="invalid">
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='my-3'>
                    <h4>
                        Address
                    </h4>
                </Row>
                <Row className='my-3'>
                    <Col>
                        <Form.Group className='mb-3' controlId='addressLine1'>
                            <Form.Control isInvalid={hasValidationError(validationPageName, 'addressLine1', props.familyMember.addressLine1)}
                                onChange={handleFamilyMemberChange} placeholder='Address Line 1'
                                value={props.familyMember.addressLine1 ?? ''}
                                name='addressLine1'
                                disabled={props.createCaseDTO.id !== undefined && !props.familyMember.isPrimary || !props.familyMember.isPrimary} />
                            <Form.Control.Feedback type="invalid">
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3' controlId='addressLine2'>
                            <Form.Control isInvalid={false} onChange={handleFamilyMemberChange} placeholder='Address Line 2'
                                value={props.familyMember.addressLine2 ?? ''}
                                name='addressLine2'
                                disabled={props.createCaseDTO.id !== undefined && !props.familyMember.isPrimary || !props.familyMember.isPrimary} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col>
                        <Form.Group className='mb-3' controlId='city'>
                            <Form.Control isInvalid={hasValidationError(validationPageName, 'city', props.familyMember.city)}
                                onChange={handleFamilyMemberChange} placeholder='City'
                                value={props.familyMember.city ?? ''}
                                name='city'
                                disabled={props.createCaseDTO.id !== undefined && !props.familyMember.isPrimary || !props.familyMember.isPrimary} />
                            <Form.Control.Feedback type="invalid">
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3' controlId='familyMemberState'>
                            <Form.Control
                                as='select'
                                name='stateID'
                                value={props.familyMember.stateID ?? undefined}
                                onChange={handleStateSelectChange}
                                disabled={props.createCaseDTO.id !== undefined && !props.familyMember.isPrimary || !props.familyMember.isPrimary}
                                isInvalid={hasValidationError(validationPageName, 'stateID', props.familyMember.stateID)}
                            >
                                <option value={undefined}>Select State/Territory</option>
                                {props.statesList?.map((state, index) => {
                                    return <option key={index} value={state.id}>{state.name}</option>
                                })
                                }
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3' controlId='zip'>
                            <Form.Control isInvalid={hasValidationError(validationPageName, 'zipCode', props.familyMember.zipCode)}
                                onChange={handleFamilyMemberChange} placeholder='Zip'
                                value={props.familyMember.zipCode ?? ''}
                                name='zipCode'
                                disabled={props.createCaseDTO.id !== undefined && !props.familyMember.isPrimary || !props.familyMember.isPrimary}
                                onKeyDown={InputService.HandleZipCodeInput} />
                            <Form.Control.Feedback type="invalid">
                                Invalid
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                {props.familyMember.isPrimary &&
                    <>
                        <Row className='my-3'>
                            <h4>Contact Number</h4>
                        </Row>
                        <Row className='my-3'>
                            <Form.Group className='mb-3' controlId='contactNumber'>
                                <Form.Control isInvalid={hasValidationError(validationPageName, 'contactNumber', props.familyMember.contactNumber)}
                                    onChange={handleFamilyMemberChange}
                                    maxLength={14}
                                    placeholder='Contact number'
                                    value={InputService.FormatPhoneNumber(props.familyMember.contactNumber) ?? ''}
                                    name='contactNumber'
                                    onKeyDown={InputService.HandleNumberInput} />
                                <Form.Control.Feedback type="invalid">
                                    Invalid
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </>
                }
            </Form>
        </Container >
    );
}
FamilyMemberForm.displayName = FamilyMemberForm.name;