import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CaseActivitySectionProps } from './CaseActivitySectionProps';
import { CaseSelectionComponent } from './CaseSelectionComponent';


export const CaseActivitySection1 = (props: CaseActivitySectionProps) => {
    const { onChangeHandler, caseActivity, volunteer, review } = props
    return (
        <>
            <div>
                {caseActivity.id && caseActivity.createdDate ?
                    <>
                        <div>Created Date</div>
                        <div>{caseActivity.createdDate.toLocaleString().split('T')[0]}</div>
                    </>
                    :
                    <>
                        <div>Today's Date</div>
                        <div>{new Date().toLocaleDateString()}</div>
                    </>
                }
            </div>
            <Col>
                <CaseSelectionComponent
                    onChangeHandler={onChangeHandler}
                    caseActivity={caseActivity}
                    volunteer={volunteer}
                    review={review}
                />
            </Col>
        </>
    )
}
