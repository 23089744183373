import React from "react"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { CaseDTO } from "../../volunteer-tracking-client";

type VolunteerCaseButtonProperties = {
    selectedCase: CaseDTO
}

export const VolunteerCaseButtons = (props:VolunteerCaseButtonProperties) => {
    const {selectedCase} = props
    const navigate = useNavigate();
    const onViewClick = async (selectedCase: CaseDTO) => {
        navigate('/volunteer-case-activities/' + selectedCase.id);
    }

    const onAddClick = async (selectedCase: CaseDTO) => {
        navigate('/new-case-activity/' + selectedCase.id);
    }

    return(
        <div className='case-item-buttons'>
                <Button size='sm' onClick={() => onAddClick(selectedCase)}>Add an activity</Button>
                <Button size='sm' onClick={() => onViewClick(selectedCase)}>View Activities</Button>
        </div>
    )
}