import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { CreateCaseActivityDTO, CreateCaseDTO } from '../../../volunteer-tracking-client';

export const LocalOfficeCaseActivitySection1 = (props: {
    onChangeHandler: (fn: () => any) => void,
    caseActivity: CreateCaseActivityDTO,
    caseDTO: CreateCaseDTO
}) => {

    return (
        <>
            <div>
                {props.caseActivity.createdDate &&
                    <>
                        <div>Created Date</div>
                        <div>{props.caseActivity.createdDate.toLocaleString().split('T')[0]}</div>
                    </>
                }
            </div>
            <Col>
                <Form.Group className='mb-3' >
                    <Form.Label>Which Client is this service provided to</Form.Label>
                    <Form.Control
                        name='caseId'
                        value={props.caseDTO.number ?? ''}
                        disabled={true}
                    />
                </Form.Group>
            </Col>
        </>
    )
}
