import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../AppContext';
import { VolunteerService } from '../../../services/VolunteerService';
import { CaseActivityClient, CaseClient, CreateCaseActivityDTO, CreateCaseDTO, SponsorshipGroupDTO, VolunteerDTO } from '../../../volunteer-tracking-client';
import { ListCard } from '../../Common/ListCard';
import { VolunteerCaseActivityButtons } from './VolunteerCaseActivityButtons';
import { VolunteerGroupCaseActivities } from './VolunteerGroupCaseActivities';
import { Exception } from '../../Exception';

const caseActivityClient: CaseActivityClient = new CaseActivityClient();
const caseClient: CaseClient = new CaseClient();

export const VolunteerCaseActivities = () => {
    const { caseId } = useParams();
    const navigate = useNavigate();
    const [caseActivities, setCaseActivities] = useState<CreateCaseActivityDTO[]>();
    const [createCaseDTO, setCreateCaseDTO] = useState<CreateCaseDTO>();
    const [caseName, setCaseName] = useState<string>();
    const [showGroupProgress, setShowGroupProgress] = useState<boolean>(false);
    const [volunteer, setVolunteer] = useState<VolunteerDTO>();
    const [error, setError] = useState<Error>()

    const app = useAppContext();
    const user = app.user || { displayName: '', email: '' };

    useEffect(() => {
        const getCaseActivities = async () => {
            if (caseId) {
                const caseActivities = await caseActivityClient.getCaseActivities(Number.parseInt(caseId));
                caseActivities.sort((ca1, ca2) => {
                    if (ca1.createdDate! > ca2.createdDate!) {
                        return -1;
                    }

                    if (ca1.createdDate! < ca2.createdDate!) {
                        return 1;
                    }

                    return 0;
                });
                try{
                    setCaseActivities(caseActivities);
                }catch(ex:any){
                    setError(ex)
                }
            }
        };

        const getCase = async () => {
            if (caseId) {
                const c = await caseClient.getCase(Number.parseInt(caseId));
                const cv = await caseClient.getCaseCaseVolunteers(parseInt(caseId));
                c.caseVolunteers = cv;
                if (c.caseSponsorshipGroups && c.caseSponsorshipGroups.length > 0) {
                    if (c.caseSponsorshipGroups[0].sponsorshipGroupID) {
                        const sponsorshipGroup: SponsorshipGroupDTO = await caseClient.getCaseSponsorshipGroup(c.caseSponsorshipGroups[0].sponsorshipGroupID);
                        c.caseSponsorshipGroups[0].sponsorshipGroup = sponsorshipGroup;
                    }
                }

                setCreateCaseDTO(c);
                const primary = c.familyMembers?.find(c => c.isPrimary)
                setCaseName(primary?.firstName + " " + primary?.lastName)
            }
        };

        const getVolunteer = async () => {
            const result = await VolunteerService.getVolunteerByPrincipalId(user.uniqueId);
            setVolunteer(result);
        }

        getVolunteer();
        if (caseId) {
            try{
                getCaseActivities();
                getCase()
            }catch(ex:any){
                setError(ex)
            }
        }
    }, []);

    const groupProgressClick = () => {
        setShowGroupProgress(!showGroupProgress);
    }

    return (
        !error?
        <>
            <Row className='mb-3'>
                <Col sm={8}>
                    <h3>Client: {caseName} Activities</h3>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <Button onClick={groupProgressClick}>
                        {showGroupProgress ? 'View Activities' : 'Group Progress'}
                    </Button>
                </Col>
            </Row>
            {showGroupProgress ?
                <VolunteerGroupCaseActivities createCaseDTO={createCaseDTO ?? {}} caseActivities={caseActivities ?? []} volunteer={volunteer ?? {}} />
                :
                <>
                    {caseActivities && caseActivities.length > 0 ?
                        caseActivities?.map((caseActivity, index) => {
                            if (caseActivity.volunteerId === volunteer?.id) {
                                return <ListCard key={index}
                                    title={caseActivity.createdDate ? caseActivity.createdDate.toLocaleString().split('T')[0] : new Date().toLocaleDateString()}
                                >
                                    <VolunteerCaseActivityButtons caseActivity={caseActivity} />
                                </ListCard>
                            }
                        })
                        :
                        <p>There are no activities associated with this client</p>
                    }
                </>
            }
        </>:<Exception message={error.message}></Exception>
    )
}

VolunteerCaseActivities.displayName = VolunteerCaseActivities.name;