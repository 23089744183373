import React from "react"
import { useEffect, useState } from "react"
import { useAppContext } from "../../AppContext"
import { ReportService } from "../../services/ReportService"
import { ReportDTO } from "../../volunteer-tracking-client"
import { Exception } from "../Exception"

export const ReportHome = () => {
    const app = useAppContext()
    const [reports, setReports] = useState<ReportDTO[]>([])
    const [error, setError] = useState<Error>()

    useEffect(() => {
        const getReports = async () => {
            const result = await ReportService.getReportsByRoles([localStorage.getItem('selectedRole')!])
            setReports(result)
        }
        try{
            getReports();
        }catch (ex:any){
            setError(ex)
        }
    }, [])

    return (
        !error?
        <ul>
            {reports.map(r => {
                return (
                    <li key={r.id}>
                        {
                            r.reportReportRoles?.some(rrr => rrr.reportRole?.roleName === 'GlobalAdmin.All') &&
                            <a href={'/globaladmin-report-embed/' + r.id}>{r.reportName}</a>
                        }
                        {
                            r.reportReportRoles?.some(rrr => rrr.reportRole?.roleName === 'NationalOfficeUser.All') &&
                            <a href={'/nationaloffice-report-embed/' + r.id}>{r.reportName}</a>
                        }
                        {
                            r.reportReportRoles?.some(rrr => rrr.reportRole?.roleName === 'OfficeUser.All') &&
                            <a href={'/localoffice-report-embed/' + r.id}>
                                {r.reportName === 'Local Office Report' ?
                                    'Community Sponsorship and Volunteer Report' : r.reportName === 'Local Office Volunteer Summary' ?
                                        'Community Sponsorship and Volunteer Case Notes Summary' : r.reportName}
                            </a>
                        }
                        {
                            r.reportReportRoles?.some(rrr => rrr.reportRole?.roleName === 'Volunteer.All') &&
                            <a href={'/volunteer-report-embed/' + r.id}>{r.reportName}</a>
                        }
                    </li>
                )

            })}
        </ul>:<Exception message={error.message}></Exception>
    )
}