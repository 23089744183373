import React, { useEffect, useState } from 'react';
import { Container, Modal, ModalBody } from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';
import Footer from './Footer';
import NavMenu from './NavMenu';
import { useAppContext } from '../AppContext';
import { useIdleTimer } from 'react-idle-timer';

export const Layout = (props: any) => {
  const app = useAppContext()
  const timeout = 2700_000
  const promptBeforeIdle = 60_000
  const [remaining, setRemaining] = useState<number>(timeout)
  const [open, setOpen] = useState<boolean>(false)

  const onIdle = () => {
    setOpen(false)
    app.signOutSilent()
  }

  const onActive = () => {
    setOpen(false)
  }

  const onPrompt = () => {
    if (app.user) {
      setOpen(true)
    }
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }

  return (
    <div>
      <ErrorMessage />
      <NavMenu />
      <Container className='App-body'>
        {props.children}
      </Container>
      <Footer />
      <Modal show={open} >
        <Modal.Header closeButton >
          <Modal.Title>Log Out</Modal.Title>
        </Modal.Header >
        <ModalBody>
          <h3>Are you still here?</h3>
          <p>Logging out in {remaining} seconds</p>
          <button onClick={handleStillHere}>Im still here</button>
        </ModalBody>
      </Modal >
    </div>
  );
}

