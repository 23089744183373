import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { CaseActivityClient, CaseClient, CreateCaseActivityDTO, CreateCaseActivityTaskDTO, CreateCaseDTO } from '../../../volunteer-tracking-client';
import { ListCard } from '../../Common/ListCard';
import LocalOfficeCaseActivitiesActionRenderer from './LocalOfficeCaseActivitiesActionRenderer';
import { LocalOfficeCaseActivitiesGroupProgress } from './LocalOfficeCaseActivitiesGroupProgress';
import { Exception } from '../../Exception';

const caseActivityClient: CaseActivityClient = new CaseActivityClient();
const caseClient: CaseClient = new CaseClient();

export const LocalOfficeCaseActivities = () => {
    const { caseId } = useParams();
    const [caseActivities, setCaseActivities] = useState<CreateCaseActivityDTO[]>();
    const [showGroupProgress, setShowGroupProgress] = useState<boolean>(false);
    const [primary, setPrimary] = useState<string>('');
    const [error, setError] = useState<Error>();

    const navigate = useNavigate();

    useEffect(() => {
        const getCaseActivities = async () => {
            if (caseId) {
                const caseActivities = await caseActivityClient.getCaseActivities(Number.parseInt(caseId));
                caseActivities.sort((ca1, ca2) => {
                    if (ca1.createdDate! > ca2.createdDate!) {
                        return -1;
                    }

                    if (ca1.createdDate! < ca2.createdDate!) {
                        return 1;
                    }

                    return 0;
                });
                setCaseActivities(caseActivities);
            }
        };

        const getCaseFamilyMembers = async () => {
            if (caseId) {
                const familyMembers = await caseClient.getCaseFamilyMembers(parseInt(caseId));
                const primary = familyMembers.filter(fm => fm.isPrimary)[0];
                setPrimary(primary?.firstName + ' ' + primary?.lastName);
            }
        }

        if (caseId) {
            try{
                getCaseActivities();
                getCaseFamilyMembers();
            }catch(ex:any){
                setError(ex)
            }
        }
    }, []);

    const onViewClick = async (selectedCaseActivity: CreateCaseActivityDTO) => {
        navigate('/localoffice-case-activity-summary/' + selectedCaseActivity.id);
    }

    const onEditClick = async (selectedCaseActivity: CreateCaseActivityDTO) => {
        navigate('/edit-case-activity/' + selectedCaseActivity.id);
    }

    const groupProgressClick = () => {
        setShowGroupProgress(!showGroupProgress);
    }

    const checkHasReceipt = (caseActivityTasks: CreateCaseActivityTaskDTO[] | undefined): boolean => {
        let hasReceipt = false;
        caseActivityTasks?.forEach(task => {
            if (task.inKindReceiptFilePath) {
                hasReceipt = true;
            }
        });

        return hasReceipt;
    }

    return (
        !error?
        <>
            <Row className='mb-3'>
                <Col sm={8}>
                    <h3>Client: {primary} Activities</h3>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <Button onClick={groupProgressClick}>
                        {showGroupProgress ? 'View Activities' : 'Group Progress'}
                    </Button>
                </Col>
            </Row>
            {showGroupProgress ?
                <LocalOfficeCaseActivitiesGroupProgress caseActivities={caseActivities ?? []} />
                :
                <>
                    {caseActivities && caseActivities.length > 0 ?
                        caseActivities?.map((caseActivity, index) => {
                            return (
                                <ListCard key={index}
                                    title={caseActivity.createdDate ? caseActivity.createdDate.toLocaleString().split('T')[0] : new Date().toLocaleDateString()}
                                    hasReceipt={caseActivity.caseActivityTasks ? checkHasReceipt(caseActivity.caseActivityTasks) : false}
                                >
                                    <LocalOfficeCaseActivitiesActionRenderer caseActivity={caseActivity} onViewClick={onViewClick} onEditClick={onEditClick} />
                                </ListCard>);
                        })
                        :
                        <p>There are no activities associated with this case</p>
                    }
                </>
            }
        </>:<Exception message={error.message}></Exception>
    )
}

LocalOfficeCaseActivities.displayName = LocalOfficeCaseActivities.name;