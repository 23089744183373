import './custom.css'
import { Layout } from './components/Layout';
import Home from './components/Home';
import { Route, Routes } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react'
import { CreateVolunteer } from './components/Volunteer/CreateVolunteer';
import { IPublicClientApplication } from '@azure/msal-browser';
import ProtectedRoute from './components/ProtectedRoute';
import { UnauthorizedComponent } from './components/UnauthorizedComponent';
import { VolunteerHome } from './components/Volunteer/VolunteerHome';
import { GlobalAdminHome } from './components/GlobalAdmin/GlobalAdminHome';
import { LocalOfficeVolunteers } from './components/LocalOffice/LocalOfficeVolunteers';
import { ListNationalResettlementAgencies } from './components/GlobalAdmin/ListNationalResettlementAgencies';
import { LocalOfficeHome } from './components/LocalOffice/LocalOfficeHome';
import { CreateCase } from './components/Case/CreateCase';
import { NationalOfficeUserHome } from './components/NationalResettlementOffice/NationalOfficeUserHome';
import { CreateLocalOffice } from './components/NationalResettlementOffice/CreateLocalOffice';
import { ResettlementAgencyLocalOffices } from './components/NationalResettlementOffice/ResettlementAgencyLocalOffices';
import { SignInComponent } from './components/SignIn';
import { ListNationalResettlementUsers } from './components/GlobalAdmin/ListNationalResettlementUsers';
import { ResettlementAgencyLocalOfficeUsers } from './components/NationalResettlementOffice/ResettlementAgencyLocalOfficeUsers';
import { NationalResettlementAgencyCases } from './components/NationalResettlementOffice/NationalResettlementAgencyCases';
import { CaseActivity } from './components/Case/Activity/CaseActivity';
import { SponsorshipGroupList } from './components/SponsorshipGroup/SponsorshipGroupList';
import { GACaseList } from './components/GlobalAdmin/GACaseList';
import { VolunteerCases } from './components/Volunteer/VolunteerCases';
import { EditDemographics } from './components/Demographics/EditDemographics';
import { LocalOfficeCaseActivities } from './components/LocalOffice/LocalOfficeCaseActivity/LocalOfficeCaseActivities';
import { VolunteerCaseActivities } from './components/Volunteer/VolunteerCaseActivity/VolunteerCaseActivities';
import { VolunteerCaseActivitySummary } from './components/Volunteer/VolunteerCaseActivity/VolunteerCaseActivitySummary';
import { LocalOfficeCaseActivitySummary } from './components/LocalOffice/LocalOfficeCaseActivity/LocalOfficeCaseActivitySummary';
import { Landing } from './components/Landing';
import { ReportHome } from './components/Reports/ReportHome';
import { VolunteerReportEmbed } from './components/Reports/VolunteerReportEmbed';
import { LocalOfficeReportEmbed } from './components/Reports/LocalOfficeReportEmbed';
import { NationalOfficeReportEmbed } from './components/Reports/NationalOfficeReportEmbed';
import { CreateNationalResettlementAgency } from './components/GlobalAdmin/CreateNationalResettlementAgency';
import { LocalOfficeSettings } from './components/LocalOffice/LocalOfficeSettings';

import ProvideAppContext from './AppContext';
import React from 'react';
import { SessionExpired } from './components/SessionExpired';


type AppProps = {
    pca: IPublicClientApplication
};

export const App = ({ pca }: AppProps, pageProps: any) => {

    return (
        <MsalProvider instance={pca}>
            <ProvideAppContext>
                <Layout>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/landing' element={<Landing />} />
                        <Route path='/create-volunteer' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo='/'>
                                <CreateVolunteer />
                            </ProtectedRoute>}
                        />
                        <Route path='/local-office-volunteers' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo="/">
                                <LocalOfficeVolunteers />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/create-new-case' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo='/'>
                                <CreateCase />
                            </ProtectedRoute>
                        } />
                        <Route path='/edit-case/:caseId' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo='/'>
                                <CreateCase />
                            </ProtectedRoute>
                        } />
                        <Route path='/unauthorized' element={<UnauthorizedComponent />}></Route>
                        <Route path='/local-office-home' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo="/">
                                <LocalOfficeHome />
                            </ProtectedRoute>

                        }
                        />
                        <Route path='/local-office-sponsorship-groups' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo="/">
                                <SponsorshipGroupList />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/localoffice-case-activities/:caseId' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo="/">
                                <LocalOfficeCaseActivities />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/localoffice-case-activity-summary/:caseActivityId' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo="/">
                                <LocalOfficeCaseActivitySummary />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/local-office-settings' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo="/">
                                <LocalOfficeSettings />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/volunteer-home' element={
                            <ProtectedRoute roles={["Volunteer.All"]} redirectTo="/">
                                <VolunteerHome />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/volunteer-cases' element={
                            <ProtectedRoute roles={["Volunteer.All"]} redirectTo="/">
                                <VolunteerCases />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/volunteer-demographics' element={
                            <ProtectedRoute roles={["Volunteer.All"]} redirectTo="/">
                                <EditDemographics />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/volunteer-case-activities/:caseId' element={
                            <ProtectedRoute roles={["Volunteer.All"]} redirectTo="/">
                                <VolunteerCaseActivities />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/volunteer-case-activity-summary/:caseActivityId' element={
                            <ProtectedRoute roles={["Volunteer.All"]} redirectTo="/">
                                <VolunteerCaseActivitySummary />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/global-admin-home' element={
                            <ProtectedRoute roles={["GlobalAdmin.All"]} redirectTo="/">
                                <GlobalAdminHome />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/global-admin-cases' element={
                            <ProtectedRoute roles={["GlobalAdmin.All"]} redirectTo="/">
                                <GACaseList />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/list-national-resettlement-agencies' element={
                            <ProtectedRoute roles={["GlobalAdmin.All"]} redirectTo="/">
                                <ListNationalResettlementAgencies />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/list-national-resettlement-users' element={
                            <ProtectedRoute roles={["GlobalAdmin.All", "NationalOfficeUser.All"]} redirectTo="/">
                                <ListNationalResettlementUsers />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/national-office-home' element={
                            <ProtectedRoute roles={["NationalOfficeUser.All"]} redirectTo="/">
                                <NationalOfficeUserHome />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/national-office-cases' element={
                            <ProtectedRoute roles={["NationalOfficeUser.All"]} redirectTo="/">
                                <NationalResettlementAgencyCases />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/national-office-local-offices' element={
                            <ProtectedRoute roles={["NationalOfficeUser.All"]} redirectTo="/">
                                <ResettlementAgencyLocalOffices />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/national-office-local-office-users' element={
                            <ProtectedRoute roles={["NationalOfficeUser.All"]} redirectTo="/">
                                <ResettlementAgencyLocalOfficeUsers />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/create-local-office' element={
                            <ProtectedRoute roles={["NationalOfficeUser.All"]} redirectTo="/">
                                <CreateLocalOffice />
                            </ProtectedRoute>
                        }
                        />
                        <Route path='/signin' element={
                            <SignInComponent />
                        }
                        />
                        <Route path='/edit-case-activity/:caseActivityId' element={
                            <ProtectedRoute roles={["Volunteer.All", "OfficeUser.All"]} redirectTo='/'>
                                <CaseActivity />
                            </ProtectedRoute>
                        } />

                        <Route path='/new-case-activity/:caseId' element={
                            <ProtectedRoute roles={["Volunteer.All"]} redirectTo='/'>
                                <CaseActivity />
                            </ProtectedRoute>
                        } />

                        <Route path='/reports' element={
                            <ProtectedRoute roles={["GlobalAdmin.All", "NationalOfficeUser.All", "OfficeUser.All", "Volunteer.All"]} redirectTo='/'>
                                <ReportHome />
                            </ProtectedRoute>
                        } />

                        <Route path='/volunteer-report-embed/:reportId' element={
                            <ProtectedRoute roles={["Volunteer.All"]} redirectTo='/'>
                                <VolunteerReportEmbed />
                            </ProtectedRoute>
                        } />

                        <Route path='/localoffice-report-embed/:reportId' element={
                            <ProtectedRoute roles={["OfficeUser.All"]} redirectTo='/'>
                                <LocalOfficeReportEmbed />
                            </ProtectedRoute>
                        } />

                        <Route path='/nationaloffice-report-embed/:reportId' element={
                            <ProtectedRoute roles={["NationalOfficeUser.All"]} redirectTo='/'>
                                <NationalOfficeReportEmbed />
                            </ProtectedRoute>
                        } />

                        <Route path='/globaladmin-report-embed/:reportId' element={
                            <ProtectedRoute roles={["GlobalAdmin.All"]} redirectTo='/'>
                                <NationalOfficeReportEmbed />
                            </ProtectedRoute>
                        } />
                        <Route path='/create-national-resettlement-agency' element={
                            <ProtectedRoute roles={["GlobalAdmin.All"]} redirectTo="/">
                                <CreateNationalResettlementAgency />
                            </ProtectedRoute>
                        }

                        
                        />
                        <Route path='/session-expired' element={
                              <SessionExpired/>
                        }
                        />
                    </Routes>
                </Layout>

            </ProvideAppContext>
        </MsalProvider>
    );
}

App.displayName = App.name


