import React, { useEffect, useState } from 'react'
import { Stack, Spinner, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { LocalOfficeUserService } from '../../services/LocalOfficeUserService';
import { CreateLocalOfficeDTO, LocalOfficeClient, StateDTO, StateClient, LocalOfficeUserDTO, LocalOfficeUserExistsDTO } from '../../volunteer-tracking-client';
import { Exception } from '../Exception';
import { CreateLocalOfficeUserForm } from '../LocalOffice/CreateLocalOfficeUserForm';

const localOfficeClient: LocalOfficeClient = new LocalOfficeClient();
const statesClient: StateClient = new StateClient();

export const CreateLocalOffice = () => {
  const [localOfficeDto, setLocalOfficeDTO] = useState<CreateLocalOfficeDTO>();
  const [states, setStates] = useState<StateDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateSuccess, setShowCreateSuccess] = useState(false);
  const [showBadRequest, setShowBadRequest] = useState(false);
  const [validated, setValidated] = useState(false)
  const [error, setError] = useState<Error>();
  const [emailMessage, setEmailMessage] = useState<string>();
  const [emailNew, setEmailNew] = useState<boolean>(true);

  useEffect(() => {
    const getNationalResettlementUser = async () => {
      const selectedOrganizationId = parseInt(localStorage.getItem("selectedOrganizationId")!)
      setLocalOfficeDTO({ ...localOfficeDto, ...{ "nationalResettlementAgencyID": selectedOrganizationId } })
    }

    const getStates = async () => {
      setStates(await statesClient.getAllStates())
    }
    try{
      getNationalResettlementUser();
      getStates();
    }catch(ex:any){
      setError(ex)
    }
  }, [])

  const handleInputChange = (event: any): void => {
    var key = event.target.name;
    var value = event.target.value;
    setLocalOfficeDTO(prevState => ({ ...prevState, [key]: value }));
  }

  const handleEmailChange = async (event: any) => {
    try {
      const localOfficeUserDTO = await LocalOfficeUserService.getByEmail(event.target.value);

      if (localOfficeUserDTO == null) {
        setEmailNew(true)
      } else {
        setEmailNew(false)

        setLocalOfficeDTO({ ...localOfficeDto, ...{ localOfficeUser: localOfficeUserDTO } });
      }
    } catch (ex: any) {
      setError(ex)
    }
  }

  const handleUserInputChange = (event: any): void => {
    var key = event.target.name;
    var value = event.target.value;
    setLocalOfficeDTO(prevState => ({ ...prevState, localOfficeUser: { ...prevState?.localOfficeUser, [key]: value } as LocalOfficeUserDTO }))
  }

  const handleCreateOffice = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setIsLoading(true);


      try {
        await localOfficeClient.createLocalOffice(localOfficeDto);
        setShowCreateSuccess(true);
        setValidated(false);
      } catch (error: any) {
        if (error.status === 404) {
          setValidated(true)
          setShowBadRequest(true);
        } else {
          setError(error)
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    !error ?
      <>
        <Alert show={showCreateSuccess} variant="success" onClose={() => setShowCreateSuccess(false)} dismissible>
          <Alert.Heading>Success</Alert.Heading>
          <p>
            Local Office Created!
          </p>
        </Alert>
        <Alert show={showBadRequest} variant="danger" onClose={() => setShowBadRequest(false)} dismissible>
          <Alert.Heading>Bad Request</Alert.Heading>
          <p>
            There was a problem creating the user.  This is mostly likely due to an invalid email address
          </p>
        </Alert>
        <Stack>
          <h1 id='tableLabel'>Create Local Office</h1>
          {isLoading &&
            <Stack className='mx-auto text-center mt-5'>
              <Spinner className='mx-auto' animation='border' variant='primary' />
            </Stack>
          }
          {(!isLoading) &&
            <Stack>
              <Form noValidate validated={validated} onSubmit={handleCreateOffice}>
                <Row className='mb-2'>
                  <h3>Office Details</h3>
                </Row>
                <Row className='my-3'>
                  <Col>
                    <Form.Group className='w-50 mb-3' controlId='name'>
                      <Form.Label>Name</Form.Label>
                      <Form.Control required type="text" placeholder='Name' name='name' onChange={handleInputChange} />
                      <Form.Control.Feedback type="invalid">
                        Required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='my-3'>
                  <Col sm={6}>
                    <Form.Group className='mb-3' controlId='address1'>
                      <Form.Label>Address Line 1</Form.Label>
                      <Form.Control required placeholder='Address Line 1' name='address1' onChange={handleInputChange} />
                      <Form.Control.Feedback type="invalid">
                        Required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>

                    <Form.Group className='mb-3' controlId='address2'>
                      <Form.Label>Address Line 2</Form.Label>
                      <Form.Control placeholder='Address Line 2' name='address2' onChange={handleInputChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='w-50 mb-3' controlId='city'>
                      <Form.Label>City</Form.Label>
                      <Form.Control required placeholder='City' name='city' onChange={handleInputChange} />
                      <Form.Control.Feedback type="invalid">
                        Required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group className='w-50 mb-3' controlId='stateId'>
                      <Form.Label>State/Territory</Form.Label>
                      <Form.Select required name='stateId' onChange={handleInputChange}>
                        <option value=''>Select</option>
                        {states.map((opt: StateDTO) => (
                          <option key={opt.id} value={opt.id}>{opt.name}</option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={8}>
                    <Form.Group className='w-50 mb-3' controlId='postalCode'>
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control required placeholder='Postal Code' name='postalCode' onChange={handleInputChange} />
                      <Form.Control.Feedback type="invalid">
                        Required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-2'>
                  <h3>Primary User Details</h3>
                </Row>
                <CreateLocalOfficeUserForm handleInputChange={handleUserInputChange} handleEmailChange={handleEmailChange}
                  localOfficeUserDTO={localOfficeDto?.localOfficeUser}
                  emailInvalidMessage={emailMessage}
                  readonly={!emailNew}
                />
                <Button className='mt-3' variant='primary' type='submit'>
                  Create Office
                </Button>
              </Form>
            </Stack>
          }
        </Stack>
      </> :
      <>
        <Exception message={error.message}></Exception>
      </>
  );
}
