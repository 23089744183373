import React from "react"
import { Col, Container, Form, Modal, Row } from "react-bootstrap"
import { CaseSponsorshipGroupDTO } from "../../volunteer-tracking-client"

type ViewCaseSponsorshipInfoModalProps = {
    show: boolean,
    caseSponsorshipGroup: CaseSponsorshipGroupDTO | undefined,
    closeHandler: () => void
}


export const ViewCaseSponsorshipInfoModal = (props: ViewCaseSponsorshipInfoModalProps) => {
    return (
        <Modal show={props.show} onHide={props.closeHandler} centered size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Client Sponsorship Details</Modal.Title>
            </Modal.Header>
            <Container>
                <Form>
                    <Row className='my-3'>
                        <Col>
                            <Form.Group className='mb-3' controlId='type'>
                                <Form.Label>Type</Form.Label>
                                <Form.Control disabled={true} value={props.caseSponsorshipGroup?.type?.type} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='mou'>
                                <Form.Label>Signed MOU</Form.Label>
                                <Form.Control disabled={true} value={String(props.caseSponsorshipGroup?.signedMoU)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='services'>
                                <Form.Label>Core Services</Form.Label>
                                <Form.Control disabled={true} value={props.caseSponsorshipGroup?.coreServices} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Form.Group className='mb-3' controlId='type'>
                                <Form.Label>Required Cash Contribution</Form.Label>
                                <Form.Control disabled={true} value={props.caseSponsorshipGroup?.requiredCashContribution} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='mou'>
                                <Form.Label>Required InKind Contributions</Form.Label>
                                <Form.Control disabled={true} value={String(props.caseSponsorshipGroup?.requiredInKindContributions)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Form.Group className='mb-3' controlId='type'>
                                <Form.Label>All Core Members Trained</Form.Label>
                                <Form.Control disabled={true} value={String(props.caseSponsorshipGroup?.allCoreMembersTrained)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='type'>
                                <Form.Label>All Core Members Provided Background Checks</Form.Label>
                                <Form.Control disabled={true} value={String(props.caseSponsorshipGroup?.allCoreMembersProvidedBackgroundChecks)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Modal>
    )
}