import React from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { useAppContext } from '../AppContext';
import { Landing } from './Landing';

const Home = () => {
    const app = useAppContext();

    if (app.user && localStorage.getItem("selectedRole")) {
        return (<Landing></Landing>)
    } else {
        return (
            <Container fluid>
                <Row className="bg-light rounded-3">
                    <Col md={8} className="p-4">
                        <Stack gap={3}>
                            <img src='/app_logo.svg' width={'50%'} className='d-inline-block align-top' alt=""></img>
                            <p>Please log in</p>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        );
    }
}

Home.displayName = Home.name;

export default Home

