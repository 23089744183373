import React from "react";
import { CaseActivitySection2ReviewProps } from "../../LocalOffice/LocalOfficeCaseActivity/CaseActivitySection2ReviewProps";
import { CaseActivitySection2Review } from "../../Case/Activity/CaseActivitySection2Review";

export const WelcomeCoreCaseActivitySection2Review = (props: CaseActivitySection2ReviewProps

) => {
    const caseActivityTasks = props.caseActivityTasks;

    return (
        caseActivityTasks && caseActivityTasks.length > 0 ?
            <>
                <CaseActivitySection2Review
                    caseActivityTasks={caseActivityTasks!}
                    header="Pre-Arrival"
                    section='wcPreArrival' />
                <CaseActivitySection2Review
                    caseActivityTasks={caseActivityTasks!}
                    header="Day of Arrival"
                    section='wcDayOfArrival' />
                <CaseActivitySection2Review
                    caseActivityTasks={caseActivityTasks!}
                    header="Weeks 1-4"
                    section='wcWeeks1-4' />
                <CaseActivitySection2Review
                    caseActivityTasks={caseActivityTasks!}
                    header="Weeks 5-12"
                    section='wcWeeks5-12' />
            </>
            :
            <div className="text-danger" >
                You must select at least one activity
            </div>
    )

}
