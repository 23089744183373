import { CaseClient, GetVolunteerCasesDTO } from '../volunteer-tracking-client';

const client: CaseClient = new CaseClient();

export const CaseService = {
    getVolunteerCases: async (dto: GetVolunteerCasesDTO) => {
        return await client.getVolunteerCases(dto)
    },

    getCase: async (caseId: number) => {
        return await client.getCase(caseId)
    }
}