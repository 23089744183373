import React, { useState } from "react"
import { Alert, Fade } from "react-bootstrap";
import { Exception } from "../Exception";
import { LocalOfficeCheckboxActivities } from "./LocalOfficeCheckboxActivities";
import { LocalOfficeSettingsProps } from "./LocalOfficeSettingsProps";



export const WelcomeCorpCheckboxSelections = (props: LocalOfficeSettingsProps) => {
    const { localOfficeCaseActivities, handleChange } = props
    const [error, setError] = useState<Error>();


    return (
        !error ?
            <>

                <LocalOfficeCheckboxActivities
                    onChangeHandler={handleChange}
                    localOfficeCaseActivities={localOfficeCaseActivities || []}
                    header="Pre-Arrival"
                    section='wcPreArrival'
                    sectionOpened={true}
                    readonly={true} />
                <LocalOfficeCheckboxActivities
                    onChangeHandler={handleChange}
                    localOfficeCaseActivities={localOfficeCaseActivities || []}
                    header="Day of Arrival"
                    section='wcDayOfArrival'
                    sectionOpened={false}
                    readonly={true} />
                <LocalOfficeCheckboxActivities
                    onChangeHandler={handleChange}
                    localOfficeCaseActivities={localOfficeCaseActivities || []}
                    header="Weeks 1-4"
                    section='wcWeeks1-4'
                    sectionOpened={false}
                    readonly={true} />
                <LocalOfficeCheckboxActivities
                    onChangeHandler={handleChange}
                    localOfficeCaseActivities={localOfficeCaseActivities || []}
                    header="Weeks 5-12"
                    section='wcWeeks5-12'
                    sectionOpened={false}
                    readonly={true} />

            </> :
            <>
                <Exception message={error.message}></Exception>
            </>
    )
}