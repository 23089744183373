import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { useAppContext } from "../../AppContext"
import { VolunteerService } from "../../services/VolunteerService"
import { VolunteerDTO } from "../../volunteer-tracking-client"
import { RegistrationComponent } from "../Demographics/RegistrationComponent"
import { VolunteerCases } from "./VolunteerCases"
import "./Volunteer.css"

export const VolunteerHome = () => {
    const app = useAppContext()
    const [volunteer, setVolunteer] = useState<VolunteerDTO>()

    useEffect(() => {
        const getVolunteer = async () => {
            setVolunteer(await VolunteerService.getVolunteerByPrincipalId(app.user?.uniqueId))
        }
        getVolunteer()
        
    }, [])

    if (!volunteer) {
        return (<></>)
    }
    else if (volunteer.registrationComplete) {
        return (
            <Container>
                <div className="volunteer-home-header">Welcome {volunteer.firstName + ' ' + volunteer.lastName}</div>
                <VolunteerCases />
            </Container>
        )
    } else {
        return (
            <RegistrationComponent volunteer={volunteer}></RegistrationComponent>
        )
    }
}