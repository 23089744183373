import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import { VolunteerService } from '../../services/VolunteerService';
import { VolunteerDTO } from '../../volunteer-tracking-client';
import { AddDemographics } from './AddDemographics';
import { Exception } from '../Exception';

export const EditDemographics = () => {
    const [volunteer, setVolunteer] = useState<VolunteerDTO>({})
    const [error, setError] = useState<Error>()
    const app = useAppContext()

    useEffect(() => {
        const getVolunteer = async () => {
            setVolunteer(await VolunteerService.getVolunteerByPrincipalId(app.user?.uniqueId))
        }
        try{
            getVolunteer()
        }catch(ex:any){
            setError(ex)
        }
    }, []);

    return (
        !error?
        <Container>
            <p>Editing Demographics</p>
            <AddDemographics volunteer={volunteer} />
        </Container >:<Exception message={error.message}></Exception>
    )
}
