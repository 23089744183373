import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import { Navbar, Nav, NavDropdown, Container, Dropdown, NavItem } from 'react-bootstrap';
import './NavMenu.css';
import { useAppContext } from '../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCog } from '@fortawesome/free-solid-svg-icons';
import { UserAvatar } from './UserAvatar';


const NavMenu = () => {
    const app = useAppContext();
    const user = app.user || { displayName: '', email: '' };

    const isVolunteer = (): boolean | undefined => {
        return localStorage.getItem("selectedRole") === "Volunteer.All";
    }

    const isLocalOfficeUser = (): boolean | undefined => {
        return localStorage.getItem("selectedRole") === "OfficeUser.All"
    }

    const isNationalResettlementUser = (): boolean | undefined => {
        return localStorage.getItem("selectedRole") === "NationalOfficeUser.All"
    }

    const isGlobalAdminUser = (): boolean | undefined => {
        return localStorage.getItem("selectedRole") === "GlobalAdmin.All"
    }

    const changeRole = (organizationId: number, role: string) => {
        localStorage.setItem("selectedOrganizationId", organizationId.toString());
        localStorage.setItem("selectedRole", role);
        document.location = '/'
    }
    return (
        <header>
            <Navbar className='Navbar-expand-sm Navbar-toggleable-sm ng-white border-bottom box-shadow mb-3' bg="light" expand="lg">
                <Container>
                    {!isVolunteer() &&
                        < Navbar.Toggle aria-controls="basic-navbar-nav" />
                    }

                    <Navbar.Brand href="/">
                        <img src='/RWC_Logo_RGB.png' width={150} height={30} className='d-inline-block align-top' alt=""></img>
                    </Navbar.Brand>

                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="me-auto">
                            {isGlobalAdminUser() &&
                                <>
                                    <NavDropdown title='National Resettlement Agencies'>
                                        <NavDropdown.Item href='/list-national-resettlement-agencies'>National Resettlement Agencies</NavDropdown.Item>
                                        <NavDropdown.Item href="/list-national-resettlement-users">National Resettlement Agency Users</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link href='/global-admin-cases'>Clients</Nav.Link>
                                    <Nav.Link href="/reports">Reports</Nav.Link>
                                </>

                            }

                            {isNationalResettlementUser() &&
                                <>
                                    <Nav.Link href="/national-office-cases">Clients</Nav.Link>
                                    <NavDropdown title="Local Offices">
                                        <NavDropdown.Item href="/national-office-local-offices">Local Offices</NavDropdown.Item>
                                        <NavDropdown.Item href="/national-office-local-office-users">Local Office Users</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link href="/list-national-resettlement-users">Resettlement Users</Nav.Link>
                                    <Nav.Link href="/reports">Reports</Nav.Link>
                                </>}

                            {isLocalOfficeUser() &&
                                <>
                                    <Nav.Link href="/local-office-volunteers">Volunteers</Nav.Link>
                                    <Nav.Link href="/local-office-home">Clients</Nav.Link>
                                    <Nav.Link href="/local-office-sponsorship-groups">Sponsorship Groups</Nav.Link>
                                    <Nav.Link href="/reports">Reports</Nav.Link>
                                    <Nav.Link href='/local-office-settings'><FontAwesomeIcon icon={faCog} title="Setting" /></Nav.Link>
                                </>
                            }
                            {isVolunteer() &&
                                <>
                                    <Nav.Link href="/reports">Reports</Nav.Link>
                                </>}

                        </Nav>

                        <div className='login-fixed' >
                            <AuthenticatedTemplate>
                                {localStorage.getItem("selectedRole") !== 'None' &&
                                    <NavDropdown style={{ fontSize: "12px" }} title={
                                        user.roleSelections?.find((rs: any) => rs.organizationId === parseInt(localStorage.getItem("selectedOrganizationId")!) &&
                                            localStorage.getItem("selectedRole") === rs.role)?.roleName
                                    } id="role-dropdown">
                                        {user.roleSelections?.map((rs: any) => {
                                            return (<Dropdown.Item onClick={() => changeRole(rs.organizationId, rs.role)} key={rs.role + ":" + rs.organizationId}>{rs.roleName}</Dropdown.Item>)
                                        })}
                                    </NavDropdown>
                                }
                                <NavDropdown title={<UserAvatar />} id="user-dropdown" align="end">
                                    <h5 className="dropdown-item-text mb-0">{user.displayName}</h5>
                                    <p className="dropdown-item-text text-muted mb-0">{user.email}</p>
                                    <Dropdown.Divider />
                                    {isVolunteer() && <NavDropdown.Item className='text-dark' href="/volunteer-demographics">Demographics</NavDropdown.Item>}
                                    <Dropdown.Item onClick={app.signOut!}>Log Out</Dropdown.Item>
                                </NavDropdown>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <NavItem className='.d-none .d-md-block .d-lg-none'>
                                    <Nav.Link
                                        onClick={app.signIn!}>Log In</Nav.Link>
                                </NavItem>
                            </UnauthenticatedTemplate>
                        </div>


                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

NavMenu.displayName = NavMenu.name
export default NavMenu

