import { CaseActivity } from '../components/Case/Activity/CaseActivity';
import {CaseActivityClient, CaseClient, CreateCaseActivityDTO, VolunteerClient, VolunteerDTO } from '../volunteer-tracking-client';

const client: CaseActivityClient = new CaseActivityClient();

export const CaseActivityService = {
    createCaseActivity: async (caseActivity:CreateCaseActivityDTO) => {
       return await client.createCaseActivity(caseActivity)
    },
    updateCaseActivity: async (caseActivity:CreateCaseActivityDTO) => {
        return await client.updateCaseActivity(caseActivity)
     }
}