import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { EducationLevelClient, EducationLevelDTO, EmploymentTypeClient, EmploymentTypeDTO, EngagementCountClient, EngagementCountDTO, EthnicityClient, EthnicityDTO, GenderClient, GenderDTO, ImmigrationStoryClient, ImmigrationStoryDTO, LanguageClient, LanguageDTO, StateClient, StateDTO } from "../../volunteer-tracking-client";
import { RegistrationComponentProps } from "./RegisrationComponentProps";
import { Exception } from "../Exception";

export const DemographicsReview = (props: RegistrationComponentProps) => {
    const [ethnicities, setEthnicities] = useState<EthnicityDTO[]>([])
    const [languages, setLanguages] = useState<LanguageDTO[]>([])
    const [educationLevels, setEducationLevels] = useState<EducationLevelDTO[]>([])
    const [employmentTypes, setEmploymentTypes] = useState<EmploymentTypeDTO[]>([])
    const [engagementCounts, setEngagementCounts] = useState<EngagementCountDTO[]>([])
    const [immigrationStories, setImmigrationStories] = useState<ImmigrationStoryDTO[]>([])
    const [states, setStates] = useState<StateDTO[]>([])
    const [error, setError] = useState<Error>();

    const { volunteer } = props
    const ethnicityClient = new EthnicityClient()
    const languageClient = new LanguageClient()
    const educationLevelClient = new EducationLevelClient()
    const employmentTypeClient = new EmploymentTypeClient()
    const engagementCountClient = new EngagementCountClient()
    const immigrationStoryClient = new ImmigrationStoryClient()
    const stateClient = new StateClient();

    useEffect(() => {
        const getEthnicities = async () => {
            setEthnicities(await ethnicityClient.getAll())
        }

        const getLanguages = async () => {
            setLanguages(await languageClient.getAllLanguages())
        }

        const getEducationLevels = async () => {
            setEducationLevels(await educationLevelClient.getAll())
        }

        const getEmploymentTypes = async () => {
            setEmploymentTypes(await employmentTypeClient.getAll())
        }

        const getEngagementCounts = async () => {
            setEngagementCounts(await engagementCountClient.getAll())
        }

        const getImmigrationStories = async () => {
            setImmigrationStories(await immigrationStoryClient.getAll())
        }

        const getStates = async () => {
            setStates(await stateClient.getAllStates())
        }
        try{
            getEthnicities()
            getLanguages()
            getEducationLevels()
            getEmploymentTypes()
            getEngagementCounts()
            getImmigrationStories()
            getStates();
        }catch(ex:any){
            setError(ex)
        }

    }, [])
    return (
        !error?
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col md={10}><h2>Contact Information</h2></Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-4">
                        <Col>
                            <Form.Label>
                                <div>Community Sponsor Name</div>
                                <div>{volunteer.firstName + ' ' + volunteer.lastName}</div>
                            </Form.Label>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={4}>
                            <Form.Label>
                                <div>Address</div>
                                <div>{volunteer.addressLine1}</div>
                                <div>{volunteer.addressLine2}</div>
                                <div>{(volunteer.city || '') + (!volunteer.city ? '' : ', ') + (states.find(s => s.id == volunteer.stateId)?.name || '') + ' ' + (volunteer.postalCode || '')}</div>
                            </Form.Label>
                        </Col>
                        <Col md={4}>
                            <Form.Label>
                                <div>Contact Number</div>
                                <div>{volunteer.cellPhone}</div>
                            </Form.Label>
                        </Col>
                        <Col md={4}>
                            <Form.Label>
                                <div>Email</div>
                                <div>{volunteer.email}</div>
                            </Form.Label>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card>
                <Card.Header>
                    <Row>
                        <Col md={10}><h2>Demographics</h2></Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-4">
                        <Col>
                            <Form.Label>
                                <h4>Which of the following best describes you?</h4>
                                <div>{ethnicities.find(e => e.id == volunteer.ethnicityId)?.value}</div>
                            </Form.Label>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={6}>
                            <Form.Label>
                                <div>Primary Language Spoken</div>
                                <div>{languages.find(l => l.id == volunteer.primaryLanguageId)?.name}</div>
                            </Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Label>
                                <div>Other Language Spoken</div>
                                {
                                    volunteer.volunteerOtherLanguages?.map(ol => {
                                        return (
                                            <div key={ol.languageId}>{languages.find(l => l.id == ol.languageId)?.name}</div>
                                        )
                                    })
                                }
                            </Form.Label>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={4}>
                            <Form.Label>
                                <div>Highest Level of Education achieved</div>
                                <div>{educationLevels.find(e => e.id == volunteer.educationLevelId)?.value}</div>
                            </Form.Label>
                        </Col>
                        <Col md={4}>
                            <Form.Label>
                                <div>Employment Status</div>
                                <div>{employmentTypes.find(e => e.id == volunteer.employmentTypeId)?.value}</div>
                                {
                                    volunteer.employmentTypeId == 4 &&
                                    <div>{volunteer.employmentTypeNotes}</div>
                                }
                            </Form.Label>
                        </Col>
                        <Col md={4}>
                            <Form.Label>
                                <div>Age</div>
                                <div>{volunteer.dateOfBirth ? new Date((new Date() as any) - (new Date(volunteer.dateOfBirth) as any)).getFullYear() - 1970 : ''}</div>
                            </Form.Label>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Form.Label>
                                <h4>Which statement best describes your previous engagement with welcoming newcomers</h4>
                            </Form.Label>
                            {volunteer.engagementCountId &&
                                <Form.Check
                                    disabled={true}
                                    readOnly={true}
                                    type="radio"
                                    checked label={engagementCounts.find(e => e.id == volunteer.engagementCountId)?.value}
                                />
                            }
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Form.Label>
                                <h4>Tell us about your immigration story</h4>
                                <div>Check all that apply</div>
                            </Form.Label>
                            <Form.Check
                                key={volunteer.immigrationStoryId}
                                checked
                                disabled={true}
                                readOnly={true}
                                label={immigrationStories.find(islu => islu.id == volunteer.immigrationStoryId)?.story + (volunteer.immigrationStoryId == 2 ? ' ' + volunteer.immigrationStoryCountry : '')}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </ >:<Exception message={error.message}></Exception>
    )
}