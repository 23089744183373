import React, { useState } from "react"
import { Alert, Card, Fade } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Exception } from "../Exception";
import { LocalOfficeCheckboxActivities } from "./LocalOfficeCheckboxActivities";
import { LocalOfficeSettingsProps } from "./LocalOfficeSettingsProps";

export const SponsorshipGroupCheckboxSelections = (props: LocalOfficeSettingsProps) => {
    const { handleChange, localOfficeCaseActivities } = props
    const [error, setError] = useState<Error>();
    const [alertHidden, setAlertHidden] = useState<boolean>(true)
    const navigate = useNavigate();



    const showAlert = () => {
        setAlertHidden(false)
        window.setTimeout(() => {
            setAlertHidden(true)
        }, 2000)
    }


    return (
        !error ?
            <>
                <Alert variant="success" hidden={alertHidden} transition={Fade}>
                    Settings Updated!
                </Alert>
                <Card>
                    <Card.Body>
                        <div>Please deselect the activities you do not want listed for volunteers</div>
                    </Card.Body>
                </Card>
                <LocalOfficeCheckboxActivities
                    onChangeHandler={handleChange}
                    localOfficeCaseActivities={localOfficeCaseActivities || []}
                    header="Pre-Arrival"
                    section='PreArrival'
                    sectionOpened={true} />
                <LocalOfficeCheckboxActivities
                    onChangeHandler={handleChange}
                    localOfficeCaseActivities={localOfficeCaseActivities || []}
                    header="Arrival"
                    section='Arrival'
                    sectionOpened={false} />
                <LocalOfficeCheckboxActivities
                    onChangeHandler={handleChange}
                    localOfficeCaseActivities={localOfficeCaseActivities || []}
                    header="Service"
                    section='PostArrival'
                    sectionOpened={false} />
                <LocalOfficeCheckboxActivities
                    onChangeHandler={handleChange}
                    localOfficeCaseActivities={localOfficeCaseActivities || []}
                    header="Additional wrap-around services"
                    section='AdditionalServices'
                    sectionOpened={false} />

            </> :
            <>
                <Exception message={error.message}></Exception>
            </>
    )
}