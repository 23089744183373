import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faDoorOpen, faUser, faUserSlash } from '@fortawesome/free-solid-svg-icons';

export default (props: any) => {

    const verifyDeleteNationalResettlementUser = () => {
        props.verifyDeleteNationalResettlementUser(props.data)
    }

    const verifyReactivateNationalResettlementUser = () => {
        props.verifyReactivateNationalResettlementUser(props.data)
    }

    const verifyReinviteUser = () => {
        props.verifyReinviteUser(props.data)
    }

    return (
        <span style={{ cursor: "pointer" }}>
            {props.data.isActive &&
            <div>
                <FontAwesomeIcon icon={faUserSlash} title='Deactivate User' onClick={verifyDeleteNationalResettlementUser} />
                <span title={"Reinvite User"} style={{"paddingLeft": "20px"}}>
                    <FontAwesomeIcon icon={faDoorOpen} onClick={verifyReinviteUser} />
                </span>
            </div>
            }
            {
                !props.data.isActive &&
                <span title={"Reactivate Volunteer"}>
                    <FontAwesomeIcon icon={faUser} title='Reactivate User' onClick={verifyReactivateNationalResettlementUser} />
                </span>
            }

        </span>


    );
};



