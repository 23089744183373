import React, { useEffect, useState } from 'react';
import { CaseActivityCheckboxService } from '../../../services/CaseActivityCheckboxService';
import { CaseActivityCheckboxDTO, CreateCaseActivityTaskDTO } from '../../../volunteer-tracking-client';
import { CaseActivityElaborationForm } from './CaseActivityElaborationForm';
import { SelectedActivitiesProps } from './SelectedActivitiesProps';
import { Exception } from '../../Exception';
import { error } from 'console';


export const CaseActivitySection3 = (props: SelectedActivitiesProps) => {
    const { onChangeHandler, caseActivity, review } = props
    const [questions, setQuestions] = useState<CaseActivityCheckboxDTO[]>([])
    const [error, setError] = useState<Error>()

    const handleChange = (selectedActivity: CreateCaseActivityTaskDTO) => {
        const index = caseActivity!.caseActivityTasks!.findIndex(a => a.caseActivityCheckboxId == selectedActivity.caseActivityCheckboxId)
        caseActivity!.caseActivityTasks![index] = selectedActivity
        onChangeHandler(()=>{return {caseActivityTasks: caseActivity!.caseActivityTasks}})
    }

    useEffect(() => {
        const getQuestions = async () => {
            const checkboxIds: number[] = caseActivity!.caseActivityTasks!.map(a => { return a.caseActivityCheckboxId || 0 })
            setQuestions(await CaseActivityCheckboxService.getActivityCheckboxesByIds(checkboxIds))
         }
         try{
            getQuestions()   
         }catch(ex:any) {
            setError(ex)
         }
    }, []);

    return (
        !error?
        <>
            {questions.map((q, index) => {
                return <CaseActivityElaborationForm
                    key={q.id}
                    label={q.label}
                    sectionOpened={index == 0}
                    caseNumber={caseActivity!.caseNumber!}
                    selectedActivity={
                        caseActivity!.caseActivityTasks!.find(a => a.caseActivityCheckboxId == q.id) || {
                            hoursSpent: 0,
                            minutesSpent: 0
                        }
                    }
                    onChangeHandler={handleChange}
                    review={props.review ?? false} />
            })}

        </>:<Exception message={error.message}></Exception>
    )

}
