import React, { useState } from "react"
import { Button, Col, Form, FormLabel, Row, Stack } from "react-bootstrap"
import { CaseActivityBlobClient, CreateCaseActivityTaskDTO } from "../../../volunteer-tracking-client"
import { InKindDonationItemComponent } from "./InKindDonationItemComponent"

type InKindDonationProperties = {
    onChangeHandler: (selectedActivity: CreateCaseActivityTaskDTO) => void
    selectedActivity: CreateCaseActivityTaskDTO
    caseNumber: string
    review?: boolean
}

export const TransportationComponent = (props: InKindDonationProperties) => {
    const { onChangeHandler, selectedActivity, caseNumber, review } = props
    const [transportationProvided, setTransportationProvided] = useState<boolean>(selectedActivity.transportationProvided || false)

    const blobClient = new CaseActivityBlobClient()

    const handleChange = (event: any) => {
        const fieldName: string = event.target.name as string
        const value = event.target.value
        onChangeHandler({ ...selectedActivity, ...{ [fieldName]: value } })
    }

    const handleTransportationProvidedClick = (event: any) => {
        setTransportationProvided(!transportationProvided)
        onChangeHandler({
            ...selectedActivity, ...{
                [event.target.name]: event.target.value == 'true' ? true : false
            }
        })
    }
    return (
        <>
            <Form.Group>
                <h3>Do you need to report mileage?</h3>
                <Stack direction="horizontal">
                    <Form.Check inline type="radio"
                        checked={
                            selectedActivity.transportationProvided?.toString() == "true"}
                        label="Yes"
                        name="transportationProvided"
                        value="true"
                        onChange={handleTransportationProvidedClick}
                        disabled={props.review ?? false}
                    />
                    <Form.Check inline type="radio"
                        checked={
                            selectedActivity.transportationProvided ? selectedActivity.transportationProvided.toString() == "false" : true}
                        label="No"
                        name="transportationProvided"
                        value="false"
                        onChange={handleTransportationProvidedClick}
                        disabled={props.review ?? false}
                    />
                </Stack>
            </Form.Group>
            {transportationProvided &&
                <Form.Group className="mb-3">
                    <Row>
                        <Col md={2}>
                            <Form.Label>Mileage</Form.Label>
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                placeholder="Mileage"
                                isInvalid={selectedActivity.transportationMileage?.toString() == ''}
                                min={0}
                                name='transportationMileage'
                                value={selectedActivity.transportationMileage || ''}
                                onChange={handleChange}
                                disabled={props.review ?? false} />
                            <Form.Control.Feedback type="invalid">
                                Required
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                </Form.Group>
            }
        </>
    )
}