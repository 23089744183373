import React, { useState } from "react"
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { SponsorshipGroupVolunteerDTO } from "../../volunteer-tracking-client"

type EditGroupVolunteerModalProps = {
    show: boolean,
    groupVolunteer: SponsorshipGroupVolunteerDTO,
    confirmHandler: (groupVolunteer: SponsorshipGroupVolunteerDTO) => void
    closeHandler: () => void
}


export const EditGroupVolunteerModal = (props: EditGroupVolunteerModalProps) => {
    const [gv, setGV] = useState<SponsorshipGroupVolunteerDTO>(props.groupVolunteer);
    const onConfirm = () => {
        props.confirmHandler(gv);
    }

    const handleInputChange = (event: any) => {
        const key: keyof SponsorshipGroupVolunteerDTO = event.target.name;
        let value = event.target.value;

        if (key === 'isDeleted') {
            value = !(value === 'true')
        }

        setGV(prevState => ({ ...prevState, [key]: value }));
    }

    return (
        <Modal show={props.show} onHide={props.closeHandler} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Edit Sponsorship Group Volunteer</Modal.Title>
            </Modal.Header>
            <Container>
                <Form>
                    <Row className='my-3'>
                        <Col>
                            <Form.Group className='mb-3' controlId='name'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control disabled={true}
                                    name='email' value={gv.volunteer?.email} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='name'>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control disabled={true}
                                    name='firstName' value={gv.volunteer?.firstName} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='name'>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control disabled={true}
                                    name='lastName' value={gv.volunteer?.lastName} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='isDeleted'>
                                <Form.Label>Active Member</Form.Label>
                                <Form.Control
                                    name='isDeleted'
                                    as='select'
                                    value={(!gv.isDeleted)?.toString()}
                                    onChange={handleInputChange}
                                    disabled={gv.isGroupLeader}
                                >
                                    <option value={'true'}>True</option>
                                    <option value={'false'}>False</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.closeHandler}>Cancel</Button>
                <Button variant="primary" onClick={onConfirm}>Update</Button>
            </Modal.Footer>
        </Modal>
    )
}