import React from 'react';
import { Form, Row, Col, Stack, Button } from 'react-bootstrap';
import { CreateCaseValidationService } from '../../../services/CreateCaseValidationService';
import { CaseSponsorshipGroupDTO, CreateCaseDTO, FaithCommunityTypeDTO, SponsorshipGroupCommunityTypeDTO, SponsorshipGroupDTO, SponsorshipGroupTypeDTO, SponsorshipGroupVolunteerDTO } from '../../../volunteer-tracking-client';
import { SponsorshipGroupVolunteer } from './SponsorshipGroupVolunteer';

export const SponsorshipGroup = (props: {
    handleChange: (key: any, value: any) => void,
    isExisting: boolean,
    createCaseDTO: CreateCaseDTO,
    caseSponsorshipGroup: CaseSponsorshipGroupDTO
    sponsorshipGroupTypes: SponsorshipGroupTypeDTO[]
    sponsorshipCommunityTypes: SponsorshipGroupCommunityTypeDTO[]
    faithCommunityTypes: FaithCommunityTypeDTO[]
}) => {
    const validationPageName = 'SponsorshipGroup';
    const faithCommunityTypeID = props.sponsorshipCommunityTypes.find(sct => sct.type === 'Faith Community')?.id;
    const faithCommunityOtherID = props.faithCommunityTypes.find(fc => fc.faithType === 'Other')?.id;

    const handleInputChange = (event: any) => {
        const key = event.target.name;
        let value = event.target.value;

        if (props.caseSponsorshipGroup) {
            if (key.includes('dateOfMatch') || key.includes('commitmentDurationMonths') || key.includes('commitmentDurationMonthsMax')
                || key.includes('commitmentDuration')) {
                props.caseSponsorshipGroup[key as keyof CaseSponsorshipGroupDTO] = value;
            } else {
                if (props.caseSponsorshipGroup.sponsorshipGroup) {
                    if (key.includes('sponsorshipGroupCommunityTypeID')) {
                        value = parseInt(value);
                        if (value !== faithCommunityTypeID) {
                            props.caseSponsorshipGroup.sponsorshipGroup['faithCommunityTypeID'] = undefined;
                            props.caseSponsorshipGroup.sponsorshipGroup['faithCommunityOther'] = undefined;
                        }
                    }
                    if (key.includes('faithCommunityTypeID')) {
                        value = parseInt(value);
                        if (value !== faithCommunityOtherID) {
                            props.caseSponsorshipGroup.sponsorshipGroup['faithCommunityOther'] = undefined;
                        }
                    }
                    props.caseSponsorshipGroup.sponsorshipGroup[key as keyof SponsorshipGroupDTO] = value;
                }
            }
        }

        updateSponsorshipGroup();
    }

    const handleVolunteerUpdate = (index: number, value: SponsorshipGroupVolunteerDTO) => {
        let updatedList = props.caseSponsorshipGroup.sponsorshipGroup?.sponsorshipGroupVolunteers;
        if (updatedList) {
            updatedList = updatedList.map((sgVolunteer, listIndex) => {
                return listIndex === index ? value : sgVolunteer;
            });

            if (props.caseSponsorshipGroup.sponsorshipGroup && props.createCaseDTO.caseSponsorshipGroups && props.createCaseDTO.caseSponsorshipGroups[0]) {
                props.caseSponsorshipGroup.sponsorshipGroup.sponsorshipGroupVolunteers = updatedList;
                props.createCaseDTO.caseSponsorshipGroups[0] = props.caseSponsorshipGroup;
                props.handleChange('sponsorshipGroup', props.createCaseDTO.caseSponsorshipGroups);
            }
        }
    }

    const handleRemoveVolunteer = (index: number) => {
        if (props.caseSponsorshipGroup.sponsorshipGroup && props.caseSponsorshipGroup.sponsorshipGroup.sponsorshipGroupVolunteers) {
            let newList = props.caseSponsorshipGroup.sponsorshipGroup.sponsorshipGroupVolunteers;
            newList.splice(index, 1);
            props.caseSponsorshipGroup.sponsorshipGroup.sponsorshipGroupVolunteers = newList;

            updateVolunteers(newList);
        }
    }

    const handleAddVolunteer = (_event: any) => {
        const newVolunteer: SponsorshipGroupVolunteerDTO = { volunteer: {}, isGroupLeader: false };
        let newList: SponsorshipGroupVolunteerDTO[] = [];
        if (props.caseSponsorshipGroup.sponsorshipGroup && props.caseSponsorshipGroup.sponsorshipGroup.sponsorshipGroupVolunteers) {
            newList = props.caseSponsorshipGroup.sponsorshipGroup.sponsorshipGroupVolunteers;
        }
        newList.push(newVolunteer);
        if (props.caseSponsorshipGroup.sponsorshipGroup) {
            props.caseSponsorshipGroup.sponsorshipGroup.sponsorshipGroupVolunteers = newList;
        }

        updateVolunteers(newList);
    }

    const handleSelectSponsorshipGroupType = (event: any) => {
        const value = event.target.value as number;
        if (props.caseSponsorshipGroup) {
            props.caseSponsorshipGroup.typeID = value;
            updateSponsorshipGroup();
        }
    }

    const updateSponsorshipGroup = () => {
        if (props.createCaseDTO.caseSponsorshipGroups && props.createCaseDTO.caseSponsorshipGroups[0]) {
            props.createCaseDTO.caseSponsorshipGroups[0] = props.caseSponsorshipGroup;
            props.handleChange('props.caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        } else if (props.createCaseDTO.caseSponsorshipGroups) {
            props.createCaseDTO.caseSponsorshipGroups = [props.caseSponsorshipGroup];
            props.handleChange('props.caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        }
    }

    const updateVolunteers = (updatedList: SponsorshipGroupVolunteerDTO[]) => {
        if (props.createCaseDTO.caseSponsorshipGroups && props.createCaseDTO.caseSponsorshipGroups[0]
            && props.createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup
            && props.createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup.sponsorshipGroupVolunteers) {
            props.createCaseDTO.caseSponsorshipGroups[0].sponsorshipGroup.sponsorshipGroupVolunteers = updatedList;
            props.handleChange('props.caseSponsorshipGroups', props.createCaseDTO.caseSponsorshipGroups);
        }
    }

    const hasValidationError = (page: string, field: string, value: any): boolean => {
        let hasError = false;
        if (page === 'props.caseSponsorshipGroup') {
            hasError = CreateCaseValidationService.caseSponsorshipGroupHasError(props.caseSponsorshipGroup, page, field, value);
        } else {
            if (props.caseSponsorshipGroup && props.caseSponsorshipGroup.sponsorshipGroup) {
                hasError = CreateCaseValidationService.sponsorshipGroupHasError(props.caseSponsorshipGroup.sponsorshipGroup, page, field, value);
            }
        }
        return hasError;
    }


    return (
        <>
            <Row className='mb-3'>
                <Col>
                    <Form.Group className='mb-3' controlId='name'>
                        <Form.Label>Name of group</Form.Label>
                        <Form.Control isInvalid={hasValidationError(validationPageName, 'name', props.caseSponsorshipGroup.sponsorshipGroup?.name)}
                            onChange={handleInputChange}
                            name='name'
                            placeholder='Name of group'
                            disabled={props.isExisting}
                            value={props.caseSponsorshipGroup.sponsorshipGroup?.name ?? ''} />
                        <Form.Control.Feedback type='invalid'>
                            Required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='mb-3' controlId='typeID'>
                        <Form.Label>Type of group</Form.Label>
                        <Form.Control
                            as='select'
                            name='typeID'
                            disabled={true}
                            defaultValue={undefined}
                            value={props.caseSponsorshipGroup.typeID ?? ''}
                            onChange={handleSelectSponsorshipGroupType}
                            isInvalid={hasValidationError(validationPageName, 'typeID', props.caseSponsorshipGroup.typeID)}
                        >
                            <option value={undefined}>Select Type</option>
                            {props.sponsorshipGroupTypes.map((type, index) => {
                                return <option key={index} value={type.id}>{type.type}</option>
                            })
                            }
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            {props.caseSponsorshipGroup.sponsorshipGroup?.sponsorshipGroupVolunteers &&
                <Row className='mb-3'>
                    <h4 className='mb-2'>Group Leader Information</h4>
                    <SponsorshipGroupVolunteer handleVolunteerUpdate={handleVolunteerUpdate} handleRemove={handleRemoveVolunteer}
                        createCaseDTO={props.createCaseDTO}
                        index={0} sponsorshipGroupVolunteer={props.caseSponsorshipGroup.sponsorshipGroup.sponsorshipGroupVolunteers[0]}
                    />
                </Row>
            }
            <Row className='mb-3'>
                <Col>
                    <Form.Group className='mb-3' controlId='totalGroupMembers'>
                        <Form.Label>Total number of group members (including support volunteers)</Form.Label>
                        <Form.Control onChange={handleInputChange}
                            isInvalid={hasValidationError(validationPageName, 'totalGroupMembers', props.caseSponsorshipGroup.sponsorshipGroup?.totalGroupMembers)}
                            type='number'
                            min={0}
                            placeholder='Total group members'
                            name='totalGroupMembers'
                            className='w-50'
                            value={props.caseSponsorshipGroup.sponsorshipGroup?.totalGroupMembers ?? ''} />
                        <Form.Control.Feedback type='invalid'>
                            Required. Must be greater or equal to {props.caseSponsorshipGroup.sponsorshipGroup?.sponsorshipGroupVolunteers?.filter(sgv => !sgv.isDeleted).length}.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Group className='mb-3' controlId='totalGroupMembers'>
                        <Form.Label>
                            What statement best describes the community sponsorship group?
                            {props.caseSponsorshipGroup.sponsorshipGroup?.sponsorshipGroupCommunityTypeID == undefined &&
                                <div className="text-danger form-label-warning">(Please select one)</div>
                            }
                        </Form.Label>
                        {props.sponsorshipCommunityTypes.map(sct => {
                            return (
                                <Row key={sct.id} className="mb-3">
                                    <Col md={12}>
                                        <Form.Check
                                            required
                                            checked={props.caseSponsorshipGroup.sponsorshipGroup?.sponsorshipGroupCommunityTypeID === sct.id}
                                            label={sct.type}
                                            name="sponsorshipGroupCommunityTypeID"
                                            type="radio"
                                            value={sct.id}
                                            onChange={handleInputChange} />
                                    </Col>
                                </Row>
                            )
                        })}
                    </Form.Group>
                </Col>
                {props.caseSponsorshipGroup.sponsorshipGroup?.sponsorshipGroupCommunityTypeID === faithCommunityTypeID &&
                    <Col>
                        <Form.Group className='mb-3' controlId='totalGroupMembers'>
                            <Form.Label>
                                Which of the following best describes the community?
                                {props.caseSponsorshipGroup.sponsorshipGroup?.faithCommunityTypeID == undefined &&
                                    <div className="text-danger form-label-warning">(Please select one)</div>
                                }
                            </Form.Label>
                            {props.faithCommunityTypes.map(ft => {
                                return (
                                    <Row key={ft.id} className="mb-3">
                                        <Col>
                                            <Form.Check
                                                required
                                                checked={props.caseSponsorshipGroup.sponsorshipGroup?.faithCommunityTypeID === ft.id}
                                                label={ft.faithType}
                                                name="faithCommunityTypeID"
                                                type="radio"
                                                value={ft.id}
                                                onChange={handleInputChange} />
                                        </Col>
                                        {ft.faithType === 'Other' &&
                                            <Col sm={8}>
                                                <Form.Control
                                                    disabled={props.caseSponsorshipGroup.sponsorshipGroup?.faithCommunityTypeID !== faithCommunityOtherID}
                                                    name='faithCommunityOther'
                                                    placeholder={props.caseSponsorshipGroup.sponsorshipGroup?.faithCommunityTypeID !== faithCommunityOtherID ?
                                                        '' : 'Please specify'}
                                                    value={props.caseSponsorshipGroup.sponsorshipGroup?.faithCommunityOther ?? ''}
                                                    onChange={handleInputChange}
                                                />
                                            </Col>
                                        }
                                    </Row>
                                )
                            })}
                            <Form.Control.Feedback type='invalid'>
                                Must select community type
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                }
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Group className='mb-3' controlId='dateOfMatch'>
                        <Form.Label>Date of Match</Form.Label>
                        <Form.Control onChange={handleInputChange}
                            isInvalid={hasValidationError('props.caseSponsorshipGroup', 'dateOfMatch', props.caseSponsorshipGroup.dateOfMatch)}
                            type='date'
                            name='dateOfMatch'
                            value={props.caseSponsorshipGroup.dateOfMatch?.toString().split('T')[0] ?? ''} />
                        <Form.Control.Feedback type='invalid'>
                            Invalid Date
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='mb-3' controlId='commitmentDurationMonths'>
                        <Form.Label>Duration of commitment (in months)</Form.Label>
                        <Stack direction='horizontal' gap={2}>
                            <Row>
                                <Col>
                                    <Form.Control onChange={handleInputChange}
                                        isInvalid={hasValidationError('props.caseSponsorshipGroup', 'commitmentDurationMonths', props.caseSponsorshipGroup.commitmentDurationMonths)}
                                        type='number'
                                        min={0}
                                        value={props.caseSponsorshipGroup.commitmentDurationMonths ?? ''}
                                        placeholder='Min.'
                                        name='commitmentDurationMonths' />
                                    <Form.Control.Feedback type='invalid'>
                                        Required
                                    </Form.Control.Feedback>
                                </Col>
                                <Col>
                                    <Form.Control onChange={handleInputChange}
                                        isInvalid={hasValidationError('props.caseSponsorshipGroup', 'commitmentDurationMonthsMax', props.caseSponsorshipGroup.commitmentDurationMonthsMax)}
                                        type='number'
                                        placeholder='Max.'
                                        min={0}
                                        value={props.caseSponsorshipGroup.commitmentDurationMonthsMax ?? ''}
                                        name='commitmentDurationMonthsMax' />
                                    <Form.Control.Feedback type='invalid'>
                                        {props.caseSponsorshipGroup.commitmentDurationMonthsMax ?
                                            props.caseSponsorshipGroup.commitmentDurationMonthsMax < props.caseSponsorshipGroup.commitmentDurationMonths! ?
                                                'Must be greater than commitment duration min' : 'Required'
                                            : 'Required'
                                        }
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Stack>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={8}>
                    <h3>Community Sponsorship Group Volunteers</h3>
                </Col>
                <Col sm={4} className='d-flex justify-content-end'>
                    <Button onClick={handleAddVolunteer}>Add Volunteer</Button>
                </Col>
            </Row>
            {props.caseSponsorshipGroup.sponsorshipGroup?.sponsorshipGroupVolunteers?.map((sgVolunteer, index) => {
                if (!sgVolunteer.isGroupLeader && !sgVolunteer.isDeleted) {
                    return (
                        <SponsorshipGroupVolunteer key={index} handleVolunteerUpdate={handleVolunteerUpdate} handleRemove={handleRemoveVolunteer}
                            createCaseDTO={props.createCaseDTO}
                            index={index} sponsorshipGroupVolunteer={sgVolunteer} />
                    );
                }
            })
            }
        </>
    );
}
SponsorshipGroup.displayName = SponsorshipGroup.name;