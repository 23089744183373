import React from "react"
import { Button, Modal } from "react-bootstrap"

type VerifyDeleteModalProps = {
    show: boolean,
    confirmHandler: () => void
    closeHandler: () => void

}


export const VerifyDeleteNationalResettlementUserModal = (props: VerifyDeleteModalProps) => {
    const onConfirm = () => {
        props.confirmHandler()
    }

    return (
        <Modal show={props.show} onHide={props.closeHandler} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete National Resettlement User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You are about to delete the national resettlement user.</p>
                <p>Are you sure you want to proceed?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.closeHandler}>Cancel</Button>
                <Button variant="primary" onClick={onConfirm}>Yes</Button>
            </Modal.Footer>
        </Modal>
    )
}